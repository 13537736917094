import { Reducer } from 'redux'

interface State {
	excavationProgress: ExcavationProgress[]
}

const initialState: State = {
	excavationProgress: [],
}

export enum ExcavationProgressActions {
	AddMultipleExcavationProgress = 'ADD_MULTIPLE_EXCAVATION_PROGRESS',
	RemoveExcavationProgress = 'REMOVE_EXCAVATION_PROGRESS',
}

const reducer: Reducer<
	State,
	{ type: ExcavationProgressActions; payload: any }
> = (state: State = initialState, { type, payload }): State => {
	switch (type) {
		case ExcavationProgressActions.AddMultipleExcavationProgress:
			return {
				...state,
				excavationProgress: [
					...state.excavationProgress.filter(
						(ep) => !payload.find((nep) => nep.id === ep.id)
					),
					...payload,
				],
			}
		case ExcavationProgressActions.RemoveExcavationProgress:
			return {
				...state,
				excavationProgress: state.excavationProgress.filter(
					(ep) => ep.id !== payload
				),
			}

		default:
			return state
	}
}

export default reducer
