import { w3cwebsocket as WebSocketClient } from 'websocket'
import { WebSocketActions } from '../reducers/WebSocketReducer'
import store from '../store'

const API_URL = process.env.REACT_APP_FLOWER_URL
const FLOWER_WS_URL = process.env.REACT_APP_FLOWER_WS_URL
const WS_URL = process.env.REACT_APP_WS_URL

export const eventMonitor = (): WebSocketClient => {
	// alert("eventMonitor ws ceated for flower")
	console.log("LOG 2");
	console.log("WS URL: ", FLOWER_WS_URL);
	const WS_ENDPOINT = FLOWER_WS_URL + 'api/task/events/task-succeeded/'
	console.log("WS_ENDPOINT: ", WS_ENDPOINT);
	const socket = new WebSocketClient(
		WS_ENDPOINT
	)
	socket.onopen = () => console.log('open')
	// if (socket.readyState === 1) {
	// 	console.log('readyState: ', socket.readyState)
	// 	// socket.send('ping')
	// 	socket.send(JSON.stringify('hola'))
	// }

	return socket
}



export const setAllSockets = () => {
	setEditWebSocket()
	setFlowerWebSocket()
}

export const setEditWebSocket = () => {
	console.log("LOG EDIT WS");
	console.log("WS URL: ", WS_URL);
	// const WS_ENDPOINT = FLOWER_WS_URL + 'api/task/events/task-succeeded/'
	const WS_ENDPOINT = WS_URL + "ws/prism_point/semaphor/all/"
	console.log("EDIT WS_ENDPOINT: ", WS_ENDPOINT);
	const socket = new WebSocketClient(
		WS_ENDPOINT
	)
	socket.onopen = () => {
		console.log('open')
		setInterval(() => {socket.send(JSON.stringify({key:'__ping__'}))}, 5000)
	}
	socket.onclose = () => {
		console.log('edit ws closed')
		setTimeout(() => { setEditWebSocket() }, 1000)
	}
	socket.onerror = (e) => console.log('edit ws error', e)
	store.dispatch({ type: WebSocketActions.SetEditWS, payload: socket })
}

export const setFlowerWebSocket = () => {
	console.log("LOG XXXX");
	console.log("WS URL: ", FLOWER_WS_URL);
	const WS_ENDPOINT = FLOWER_WS_URL + 'api/task/events/task-succeeded/'
	console.log("FLOWER WS_ENDPOINT: ", WS_ENDPOINT);
	const socket = new WebSocketClient(
		WS_ENDPOINT
	)
	socket.onopen = () => {
		console.log('open')
		setInterval(() => {socket.send(JSON.stringify({key:'__ping__'}))}, 5000)
	}
	socket.onclose = () => {
		console.log('flower ws closed')
		setTimeout(() => { setFlowerWebSocket() }, 1000)
	}

	socket.onerror = (e) => console.log('flower ws error', e)
	store.dispatch({ type: WebSocketActions.SetFlowerWS, payload: socket })
}