import axios from 'axios'
import { authHeader } from './auth'

const API_URL = process.env.REACT_APP_API_URL

export const fetchAllPhases = async () => {
	var phases: Phase[] = []
	const firstResponse = (
		await axios.get<Paginated<Phase>>(API_URL + `axis/phase/`, {
			headers: authHeader(),
		})
	).data
	if (firstResponse.count) phases = [...phases, ...firstResponse.results]
	var nextUrl = firstResponse.next
	while (nextUrl) {
		const nextResponse = (
			await axios.get(nextUrl, { headers: authHeader() })
		).data
		if (nextResponse.count) phases = [...phases, ...nextResponse.results]
		nextUrl = nextResponse.next
	}
	return phases
}

export const createPhase = async (name: string) => {
	const response = await axios.post<Phase>(
		API_URL + `axis/phase/`,
		{ name, is_horizontal: true, description: name, code: 'aaa' },
		{
			headers: authHeader(),
		}
	)

	return response.data
}
