import {
	Button,
	Grow,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	TableHead,
	Chip,
} from '@material-ui/core'
import Icon from '@mdi/react'
import React, { useEffect, useRef, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useAppSelector } from '../../../hooks/storeHooks'
import { getUserById, getUsers } from '../../../services/auth'
import { addSnackbarNotification } from '../../../services/snackbarNotification'
import { getTopoFiles, uploadTopoFile } from '../../../services/topoFile'
import useStyles from '../../../styles/appStyles'
import tripodIcon from '../../../assets/tripod.svg'
import moment from 'moment-timezone'
import Checkbox from '../../shared/Checkbox'

const FileUploadView: React.FC = () => {
	const [sort, setSort] = useState<'date' | 'name'>('date')
	const [instrument, setInstrument] = useState<'L' | 'T'>('L')
	const topoFiles = useAppSelector((s) => s.topoFileReducer.topoFiles)
	const user = useAppSelector((s) => s.authReducer.user)
	const inputFile = useRef<HTMLInputElement>(null)
	const [file, setFile] = useState<File>(null)
	const [showAllDates, setShowAllDates] = useState(false)
	const { getRootProps, getInputProps } = useDropzone({
		onDrop: (acceptedFiles) => {
			setFile(acceptedFiles[0])
		},
	})
	const classes = useStyles(true)()

	useEffect(() => {
		getTopoFiles()
		getUsers()
	}, [])

	const tmp_topoFiles = (): TopoFile[] => {
		var list = []

		if (showAllDates) {
			list = topoFiles
		} else {
			list =
				topoFiles?.filter(
					(f) =>
						moment(f.created).format('DD/MM/YYYY') ===
						moment(new Date()).format('DD/MM/YYYY')
				) || []
		}

		return list
	}

	return (
		<div
			className={`bg-gray-200 w-full overflow-auto p-3 ${classes.bodyContent}`}
		>
			<div className='text-center font-medium text-gray-600 text-lg'>
				Ingreso de datos de medición
			</div>
			<div className='text-center font-medium text-gray-600 text-lg'>
				(Terreno)
			</div>
			<div className='flex'>
				<div
					className='rounded-xl p-2 bg-white w-52 h-32 shadow transition-all'
					{...getRootProps()}
				>
					<div
						onClick={() => {
							inputFile.current?.click()
						}}
						className='p-2 rounded-xl text-center space-x-2  border h-full font-semibold uppercase text-blue-800 cursor-pointer border-blue-500 border-dashed flex justify-center items-center'
					>
						Subir <br /> Medición
						<div className='w-10'>
							<img src={tripodIcon} alt=''></img>
						</div>
						<input
							type='file'
							name='file'
							id='file'
							style={{ display: 'none' }}
							ref={inputFile}
							onChange={(e) => setFile(e.target.files[0])}
							{...getInputProps()}
						/>
					</div>
				</div>

				<div className='p-2 rounded-xl text-center space-x-2  border h-full items-center'>
					<Checkbox
						checked={showAllDates}
						onChange={() => setShowAllDates(!showAllDates)}
						label={'Mostrar fechas anteriores'}
					></Checkbox>
				</div>
				{!!file && (
					<Grow in={!!file}>
						<div className='p-2 ml-3 bg-white rounded-xl flex shadow'>
							<div className='flex flex-col items-center gap-3 '>
								<div>Archivo: {file.name}</div>
								<Button
									variant='contained'
									color='primary'
									onClick={async () => {
										try {
											await uploadTopoFile(
												file,
												instrument
											)
											addSnackbarNotification({
												type: 'success',
												message: 'Archivo subido',
											})
											setFile(null)
										} catch (e) {
											addSnackbarNotification({
												type: 'error',
												message:
													'Error al subir el archivo',
											})
											console.log(
												'Error al subir archivo',
												file,
												e
											)
										}
									}}
								>
									subir
								</Button>
							</div>
						</div>
					</Grow>
				)}
			</div>
			<div className='mt-4'>
				<TableContainer component={Paper}>
					<Table size='small'>
						<TableHead>
							<TableRow>
								<TableCell className="cursor-pointer" onClick={() => setSort('name')}>Archivo</TableCell>
								<TableCell className="cursor-pointer" onClick={() => setSort('date')}>Fecha </TableCell>
								<TableCell>Estado</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{tmp_topoFiles()
								?.sort((a, b) =>
									sort === 'date' ? b.created?.localeCompare(a.created) : a.datafile.localeCompare(b.datafile)

								).filter(f => f.creator === user?.id)
								.map((tf) => (
									<TableRow key={tf.id}>
										<TableCell>
											{tf.datafile.split('/').slice(-1)}
										</TableCell>
										<TableCell>
											{moment(tf.created).format(
												'DD/MM/YYYY HH:mm'
											)}
										</TableCell>
										<TableCell>
											<Chip
												label='ARCHIVO SUBIDO'
												color='primary'
											></Chip>
										</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
				</TableContainer>
			</div>
		</div>
	)
}
export default FileUploadView
