import axios from "axios";
import { PrismPointActions } from "../reducers/PrismPointReducer";
import store from "../store";
import { Section } from "../types/Section";
import { authHeader } from "./auth";
import { w3cwebsocket as WebSocketClient } from "websocket";
import { addSnackbarNotification } from "./snackbarNotification";

const API_URL = process.env.REACT_APP_API_URL;
const WS_URL = process.env.REACT_APP_WS_URL

export const fetchAllSectionPrismPoints = async (section: Section) => {
  if (!section?.id) return;
  store.dispatch({
    type: PrismPointActions.RequestPrismPoints,
    payload: section,
  });
  const firstResponse = (
    await axios.get(
      API_URL + `prism_point/installation/?section=${section.id}`,
      { headers: authHeader() }
    )
  ).data;
  if (firstResponse.count)
    store.dispatch({
      type: PrismPointActions.AddPrismPoints,
      payload: firstResponse.results,
    });
  var nextUrl = firstResponse.next;
  while (nextUrl) {
    const nextResponse = (await axios.get(nextUrl, { headers: authHeader() }))
      .data;
    if (nextResponse.count)
      store.dispatch({
        type: PrismPointActions.AddPrismPoints,
        payload: nextResponse.results,
      });
    nextUrl = nextResponse.next;
  }
  store.dispatch({
    type: PrismPointActions.EndSectionRequest,
    payload: section,
  });
};

export const fetchAllPrismPoints = async () => {
  const firstResponse = (
    await axios.get(API_URL + `prism_point/installation/`, {
      headers: authHeader(),
    })
  ).data;
  if (firstResponse.count)
    store.dispatch({
      type: PrismPointActions.AddPrismPoints,
      payload: firstResponse.results,
    });
  var nextUrl = firstResponse.next;
  while (nextUrl) {
    const nextResponse = (await axios.get(nextUrl, { headers: authHeader() }))
      .data;
    if (nextResponse.count)
      store.dispatch({
        type: PrismPointActions.AddPrismPoints,
        payload: nextResponse.results,
      });
    nextUrl = nextResponse.next;
  }
};

export const fetchAllFixedPrismPoints = async () => {
  var prismPoints = [];
  const firstResponse = (
    await axios.get(API_URL + `prism_point/installation/?is_fixed_point=True`, {
      headers: authHeader(),
    })
  ).data;
  if (firstResponse.count) prismPoints = [...firstResponse.results];
  var nextUrl = firstResponse.next;
  while (nextUrl) {
    const nextResponse = (await axios.get(nextUrl, { headers: authHeader() }))
      .data;
    if (nextResponse.count)
      prismPoints = [...prismPoints, ...nextResponse.results];
    nextUrl = nextResponse.next;
  }
  return prismPoints;
};

export const fetchMeasurments = async (prismPoint: PrismPoint) => {
  return (
    await axios.get(
      `https://oms-dev-api.geosinergia.cl/api/prism_point/installation/${prismPoint.id}/measurements/`,
      { headers: authHeader() }
    )
  ).data.sort(
    (a, b) => new Date(a.dt_gen).getTime() - new Date(b.dt_gen).getTime()
  );
};

export const getPrismPointColor = (id: number) =>
  store.getState().prismPointReducer.prismPointColors[id] ?? "#000";

export const createPrismPoint = async (prismPoint: any) => {
  const response = await axios.post<PrismPoint>(
    API_URL + `prism_point/installation/`,
    prismPoint,
    {
      headers: authHeader(),
    }
  );

  store.dispatch({
    type: PrismPointActions.AddPrismPoints,
    payload: [response.data],
  });
};

export const updatePrismPoint = async (
  id: number,
  update: Partial<PrismPoint>
) => {
  const response = await axios.patch<PrismPoint>(
    API_URL + `prism_point/installation/${id}/`,
    update,
    { headers: authHeader() }
  );

  store.dispatch({
    type: PrismPointActions.AddPrismPoints,
    payload: [response.data],
  });
};

export const deletePrismPoint = async (id: number) => {
  const response = await axios.delete(
    API_URL + `prism_point/installation/${id}/`,
    {
      headers: authHeader(),
    }
  );
  store.dispatch({ type: PrismPointActions.RemovePrismPoint, payload: id });
};

export const canEditPrismPoint = (prismPoint: PrismPoint) => () =>
  new Promise<boolean>((res) => {

    let state = store.getState()
    const socket = state.wsReducer.editSocket

    console.log("using edit ws");
    console.log(socket);
    // console.log("WS URL: ", WS_URL);
    // const WS_ENDPOINT = WS_URL + "ws/prism_point/semaphor/all/"
    // console.log("WS_ENDPOINT: ", WS_ENDPOINT);
    // const socket = new WebSocketClient(
    //   WS_ENDPOINT
    // );
    // socket.onopen = () =>
    socket.send(
      JSON.stringify({
        message: {
          model: "prism_point",
          mid: prismPoint.id,
          action: "edit_item",
          user: store.getState().authReducer.user.id,
        },
      })
    );
    socket.onmessage = (message) => {
      const data = JSON.parse(message.data as string);
      console.log("edit WS data")
      console.log(data)
      if (data.model === "prism_point" && data.mid === prismPoint.id) {
        // socket.close();
        !(data.semaphor === "green") &&
          addSnackbarNotification({
            message: "Otro usuario esta editando este punto",
            type: "error",
          });
        res(data.semaphor === "green");
      }
    };
  });

export const closeEdit = async (prismPoint: PrismPoint) => {
  // console.log("LOG 3");
  // console.log("WS URL: ", WS_URL);
  // const WS_ENDPOINT = WS_URL + "ws/prism_point/semaphor/all/"
  // console.log("WS_ENDPOINT: ", WS_ENDPOINT);
  // const socket = new WebSocketClient(
  //   WS_ENDPOINT
  // );
  let state = store.getState()
  const socket = state.wsReducer.editSocket

  console.log("using edit ws");
  console.log(socket);

  // socket.onopen = () => {
    socket.send(
      JSON.stringify({
        message: {
          model: "prism_point",
          mid: prismPoint.id,
          action: "close_item",
          user: store.getState().authReducer.user.id,
        },
      })
    );
  //   socket.close();
  // };
};
