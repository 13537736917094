import axios from 'axios'
import { SchemeModel, SchemeNode } from '../types/Scheme'
import { authHeader } from './auth'
const API_URL = process.env.REACT_APP_API_URL

export const getFirstScheme = async () => {
	const { data } = await axios.get<Paginated<SchemeModel>>(
		API_URL + 'directory/scheme/',
		{ headers: authHeader() }
	)

	if (data.count) {
		const scheme = data.results[0]
		fillRecursiveIds(scheme.directory)
		return scheme
	}
}

const fillRecursiveIds = (node: SchemeNode) => {
	if (node.children && node.children.length) {
		node.children.forEach(fillRecursiveIds)
	}
	node.id = Date.now().toString() + Math.random().toString()
}

export const uploadFile = async (file: File, directory: string) => {
	const formData = new FormData()
	formData.append('file', file)
	formData.append('origin', 'app')
	formData.append('directory', directory)
	const response = await axios.post(API_URL + 'file/document/', formData, {
		headers: { ...authHeader(), 'content-type': 'multipart/form-data' },
	})
	return response
}

export const deleteDocument = async (node: SchemeNode) => {
	const deleteUrl = (API_URL + node.options.api_url).replace('/api/api/', '/api/')
	const response = await axios.delete(deleteUrl, {
		headers: { ...authHeader()},
	})
	return response
}

export const recursiveDelete = (node: SchemeNode, scheme: SchemeNode, depth: number) => {
	depth++
	if (scheme.children){
		const filtered = scheme.children.filter((child)=> {
			if (child.idx !== node.idx) {
				return true
			} else {
				return false
			}
		}).map((child1)=> 
			recursiveDelete(node, child1, depth)
		)
		scheme.children = filtered
		return scheme
	} else {
		return scheme
	}
}

export const deleteNode = (node: SchemeNode, scheme: SchemeModel) => {
	var newScheme = scheme
	const processed = recursiveDelete(node, newScheme.directory, 0)
	return processed
}