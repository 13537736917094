import {
	Button,
	Card,
	IconButton,
	MenuItem,
	Select,
	TextField,
} from '@material-ui/core'
import { DatePicker, DateTimePicker } from '@material-ui/pickers'
import { useEffect, useState } from 'react'
import { useAppSelector } from '../../../hooks/storeHooks'
import { useForm } from '../../../hooks/useForm'
import { fetchAllAxes, selectAxis } from '../../../services/axis'
import {
	fetchAllExcavationProgress,
	submitExcavationProgress,
} from '../../../services/excavationProgress'
import { createPhase, fetchAllPhases } from '../../../services/phase'
import {
	addSnackbarNotification,
	withError,
} from '../../../services/snackbarNotification'
import useStyles from '../../../styles/appStyles'
import moment from 'moment-timezone'
import Icon from '@mdi/react'
import { mdiCheck, mdiClose, mdiPlus } from '@mdi/js'

const ExcavationProgressForm: React.FC = () => {
	const axes = useAppSelector((s) => s.axisReducer.axes)
	const [phases, setPhases] = useState<Phase[]>()
	const [date, setDate] = useState(moment())
	const [newPhase, setNewPhase] = useState(false)
	const [newPhaseLabel, setNewPhaseLabel] = useState('')

	useEffect(() => {
		const init = async () => {
			setPhases(await fetchAllPhases())
		}
		fetchAllAxes().then(
			() => axes.length && setValues({ ...values, axis: axes[0].id })
		)
		init()
		selectAxis()
	}, [])

	const [values, setValues, handleChange] = useForm({
		axis: 0,
		excavated_distance: 0,
		comment: '',
		phase: 0,
	})

	return (
		<div className='m-2'>
			<div className='title-lg text-center'>Secuencia de excavación</div>
			<Card className='p-2 flex flex-col space-y-2 w-full'>
				<div className='flex w-full'>
					<div className='title-lg mr-2'>Eje: </div>
					<Select
						label='Eje'
						placeholder='Seleccione un eje'
						fullWidth
						onChange={(e) => {
							setValues({
								...values,
								axis: e.target.value as number,
							})
							selectAxis(
								axes.find(
									(a) => a.id === (e.target.value as number)
								)
							)
						}}
						value={values.axis}
					>
						{axes.map((a) => (
							<MenuItem value={a.id} key={a.id}>
								{a.code} {a.name}
							</MenuItem>
						))}
					</Select>
				</div>
				<div className='flex items-center'>
					<div className='title-lg mr-2'>Fase: </div>
					{newPhase ? (
						<TextField
							label='Fase de excavación'
							value={newPhaseLabel}
							id='new_phase'
							onChange={(e) =>
								setNewPhaseLabel(e.target.value as string)
							}
						></TextField>
					) : (
						<Select
							label='Fase'
							placeholder='Seleccione una fase'
							value={values.phase}
							onChange={(e) =>
								setValues({
									...values,
									phase: parseInt(e.target.value as string),
								})
							}
						>
							<MenuItem value={'0'}>Seleccione una fase</MenuItem>
							{phases?.map((ph) => (
								<MenuItem value={ph.id} key={ph.id}>
									{ph.name}
								</MenuItem>
							))}
						</Select>
					)}
					{newPhase ? (
						<>
							<IconButton
								onClick={withError(async () => {
									setNewPhase(false)
									const phase = await createPhase(
										newPhaseLabel
									)
									setPhases([...phases, phase])
									setValues({ ...values, phase: phase.id })
								})}
							>
								<div className='w-5 h-5'>
									<Icon path={mdiCheck}></Icon>
								</div>
							</IconButton>
							<IconButton onClick={() => setNewPhase(false)}>
								<div className='w-5 h-5'>
									<Icon path={mdiClose}></Icon>
								</div>
							</IconButton>
						</>
					) : (
						<IconButton onClick={() => setNewPhase(true)}>
							<div className='w-5 h-5'>
								<Icon path={mdiPlus}></Icon>
							</div>
						</IconButton>
					)}
				</div>
				<TextField
					label='Distancia de excavación [m]'
					value={values.excavated_distance}
					id='excavation_distance'
					type='number'
					onChange={(e) =>
						setValues({
							...values,
							excavated_distance: parseFloat(e.target.value),
						})
					}
				></TextField>
				<div className='p-1'>
					<DatePicker
						disableFuture
						value={date}
						onChange={(time) => {
							setDate(moment(time))
							// adjustRange()
						}}
						label='Fecha'
						InputLabelProps={{
							shrink: true,
						}}
						format={'dd/MMM/yyyy'}
					></DatePicker>
				</div>
				<TextField
					label='Comentarios'
					value={values.comment}
					id='comment'
					placeholder={'Ingrese comentarios (opcional)'}
					multiline
					minRows={3}
					onChange={(e) =>
						setValues({
							...values,
							comment: e.target.value,
						})
					}
				></TextField>
				<Button
					variant='contained'
					color='primary'
					onClick={withError(async () => {
						await submitExcavationProgress({
							...values,
							datetime: date.toDate().toISOString(),
						})
						fetchAllExcavationProgress()

						addSnackbarNotification({
							message: 'Progreso de excavación guardado',
						})
					})}
				>
					Confirmar avance
				</Button>
			</Card>
		</div>
	)
}
export default ExcavationProgressForm
