import { Reducer } from 'react'

interface State {
	timeZone: string
	timeZones: string[]
	textSize: number
}

const initialState: State = {
	timeZone: (process.env.REACT_APP_DEFAULT_TIMEZONE as string) || 'UTC',
	timeZones: [],
	textSize: 2,
}

export enum ConfigActions {
	SetTimeZone = 'SET_TIME_ZONE',
	SetTimeZones = 'SET_TIME_ZONES',
	SetTextSize = 'SET_TEXT_SIZE',
}

const reducer: Reducer<State, { type: ConfigActions; payload: any }> = (
	state: State = initialState,
	{ type, payload }
): State => {
	switch (type) {
		case ConfigActions.SetTimeZone:
			return { ...state, timeZone: payload }
		case ConfigActions.SetTimeZones:
			return { ...state, timeZones: payload }
		case ConfigActions.SetTextSize:
			return { ...state, textSize: payload }
		default:
			return state
	}
}

export default reducer
