import axios from 'axios'
import { authHeader } from './auth'

const API_URL = process.env.REACT_APP_API_URL

export const createMonograph = async (
	monograph: Partial<Monograph>,
	img: File
) => {
	const formData = new FormData()
	formData.append('figure', img)
	formData.append('section', monograph.section.toString())
	formData.append('active', 'true')
	formData.append('origin', 'app')
	const response = await axios.post<Monograph>(
		API_URL + 'section/monograph/',
		formData,
		{
			headers: authHeader(),
		}
	)
}

export const getSectionMonographs = async (id: number) => {
	const response = await axios.get<Paginated<Monograph>>(
		API_URL + `section/monograph/?section=${id}`,
		{
			headers: authHeader(),
		}
	)
	return response.data
}

export const deleteMonograph = async (id: number) => {
	const response = await axios.delete(API_URL + `section/monograph/${id}/`, {
		headers: authHeader(),
	})
	return response.data
}
