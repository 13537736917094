import axios from 'axios'
import { TopoFileActions } from '../reducers/TopoFileReducer'
import store from '../store'
import { SpreadsheetReview } from '../types/SpreadsheetReview'
import { TopoFileReview } from '../types/TopoFileReview'
import { authHeader } from './auth'
import { w3cwebsocket as WebSocketClient } from 'websocket'

const API_URL = process.env.REACT_APP_API_URL
const WS_URL = process.env.REACT_APP_WS_URL
const TOPO_FILE = process.env.REACT_APP_PATH_TOPO_FILE
const TOPO_REVIEW = process.env.REACT_APP_PATH_TOPO_REVIEW
const SPREADSHEET_REVIEW = process.env.REACT_APP_PATH_SPREADSHEET_REVIEW
const FILE_MANAGER = process.env.REACT_APP_PATH_FILE_MANAGER

export const uploadTopoFile = async (file: File, instrument: 'L' | 'T') => {
	console.log(file)
	const formData = new FormData()
	formData.append('datafile', file)
	formData.append('status', '1')
	formData.append('instrument', instrument)
	formData.append('origin', 'app')
	const response = await axios.post(API_URL + TOPO_FILE, formData, {
		headers: { ...authHeader(), 'content-type': 'multipart/form-data' },
	})
	getTopoFiles()
	return response
}

export const getTopoFiles = async () => {
	store.dispatch({ type: TopoFileActions.RequestTopoFiles, payload: {} })
	const response = await axios.get(API_URL + TOPO_FILE, {
		headers: authHeader(),
	})
	store.dispatch({
		type: TopoFileActions.SetTopoFiles,
		payload: response.data.results,
	})
}

export const getTopoFile = async (id: number) => {
	const response = await axios.get<TopoFile>(API_URL + TOPO_FILE + `${id}/`, {
		headers: authHeader(),
	})
	return response.data
}

export const getTopoFileReviews = async () => {
	var response = await axios.get(API_URL + TOPO_REVIEW, {
		headers: authHeader(),
	})

	store.dispatch({
		type: TopoFileActions.AddTopoFileReviews,
		payload: response.data.results,
	})
	while (response.data.next) {
		response = await axios.get(response.data.next, {
			headers: authHeader(),
		})
		store.dispatch({
			type: TopoFileActions.AddTopoFileReviews,
			payload: response.data.results,
		})
	}
}

export const getTopoFileReview = async (id: number) => {
	const response = await axios.get<TopoFileReview>(
		API_URL + TOPO_REVIEW + `${id}/`,
		{
			headers: authHeader(),
		}
	)
	store.dispatch({
		type: TopoFileActions.AddTopoFileReviews,
		payload: [response.data],
	})
	return response.data
}

export const createTopoFileReview = async (tf) => {
	// store.dispatch({ type: TopoFileActions.RequestTopoFiles, payload: {} })
	const response = await axios.post(
		API_URL + TOPO_REVIEW,
		{ topofile: tf.id },
		{ headers: authHeader() }
	)
	// store.dispatch({ type: TopoFileActions.SetTopoFiles, payload: response.data })
}

export const selectTopoFileReview = (
	topoFileReview: TopoFileReview | null = null
) => {
	store.dispatch({
		type: TopoFileActions.SetSelectedTopoFileReview,
		payload: topoFileReview,
	})
}

export const createSpreadsheetReview = async (
	file: File,
	tfr: TopoFileReview,
	tf: TopoFile
) => {
	const formData = new FormData()
	formData.append('spreadsheet', file)
	//TODO cambiar a topofile_review
	formData.append('topofile_review', tfr.id.toString())
	formData.append('checksum', tf.checksum.toString())
	const response = await axios.post<SpreadsheetReview>(
		API_URL + SPREADSHEET_REVIEW,
		formData,
		{ headers: { ...authHeader(), 'content-type': 'multipart/form-data' } }
	)
	return response.data
}

export const getSpreadsheetReviewsByTopoFileReview = async (id: number) => {
	return (
		await axios.get(
			API_URL + SPREADSHEET_REVIEW + `?topofile_review=${id}`,
			{
				headers: {
					...authHeader(),
					'content-type': 'multipart/form-data',
				},
			}
		)
	).data.results as SpreadsheetReview[]
}

export const topoFileMonitorSocket = (): WebSocketClient => {
	console.log("LOG 4");
    console.log("WS URL: ", WS_URL);
    const WS_ENDPOINT = WS_URL + 'ws/topofile/monitor/all/'
    console.log("WS_ENDPOINT: ", WS_ENDPOINT);
	const socket = new WebSocketClient(
		WS_ENDPOINT
	)
	return socket
}

export const dataToFileSubmission = async (
	data: Partial<DataToFileRequest>
) => {
	const response = await axios.post<DataToFileRequest>(
		API_URL + 'data_to_file/request/',
		data,
		{ headers: authHeader() }
	)
	await delay(1)
	const response2 = await axios.get<DataToFileRequest>(
		API_URL + `data_to_file/request/${response.data.id}/`,
		{ headers: authHeader() }
	)

	return response2.data
}

export const scheduleReportSubmission = async (
	data: Partial<ScheduleReportRequest>
) => {
	const response = await axios.post<DataToFileRequest>(
		API_URL + 'pdf/schedule/',
		data,
		{ headers: authHeader() }
	)

	return response.data
}

function delay(n) {
	return new Promise(function (resolve) {
		setTimeout(resolve, n * 1000)
	})
}

export const sendTopoFileReport = async (
	file: File,
	topoFileReview: TopoFileReview
) => {
	const formData = new FormData()
	formData.append('report', file)
	formData.append('topofile_review', topoFileReview.id.toString())
	formData.append('creator', store.getState().authReducer.user?.id.toString())
	const response = await axios.post(
		API_URL + 'file_manager/report/',
		formData,
		{ headers: authHeader() }
	)

	return response.data
}
