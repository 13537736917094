import { Reducer } from 'react'
import { w3cwebsocket as WebSocketClient } from 'websocket'
interface State {
	flowerSocket: WebSocketClient,
	editSocket: WebSocketClient,
}

const initialState: State = {
	flowerSocket: null,
	editSocket: null,
}

export enum WebSocketActions {
	SetFlowerWS = 'SET_FLOWER_WS',
	SetEditWS = 'SET_EDIT_WS',
}

const reducer: Reducer<State, { type: WebSocketActions; payload: any }> = (
	state: State = initialState,
	{ type, payload }
): State => {
	switch (type) {
		case WebSocketActions.SetFlowerWS:
			return { ...state, flowerSocket: payload }
		case WebSocketActions.SetEditWS:
			return { ...state, editSocket: payload }
		default:
			return state
	}
}

export default reducer
