import moment from 'moment-timezone'
import { ConfigActions } from '../reducers/ConfigReducer'
import store from '../store'

export const setTimeZone = (timeZone: string) => {
	store.dispatch({ type: ConfigActions.SetTimeZone, payload: timeZone })
	moment.tz.setDefault(timeZone)
}

export const loadTimeZones = async () => {
	const timeZones = moment.tz.names()
	store.dispatch({ type: ConfigActions.SetTimeZones, payload: timeZones })
}
