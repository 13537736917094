import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@material-ui/core'
import { number } from 'prop-types'
import { useAppSelector } from '../../../hooks/storeHooks'
import { useForm } from '../../../hooks/useForm'
import { createGroup, createProfile, createUser } from '../../../services/auth'
// import { useForm } from '../../hooks/useForm'

interface Props {
	open: boolean
	onClose?: (event?: {}, reason?: 'backdropClick' | 'escapeKeyDown') => void
}

const GroupForm: React.FC<Props> = ({ open, onClose }) => {
	const [values, setValues, handleChange] = useForm({
		name: '',
	})

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth='md'>
			<DialogContent className='p-2'>
				<div className='w-full'>
					<div className='text-main-1 text-lg mb-2 font-medium uppercase'>
						Nombre
					</div>
					<TextField
						id='name'
						fullWidth
						color='primary'
						label='Nombre'
						size='medium'
						value={values.name}
						onChange={handleChange}
					/>
				</div>
			</DialogContent>
			<DialogActions>
				<Button className='m-2' onClick={onClose}>
					Volver
				</Button>
				<Button
					color='primary'
					className='m-2'
					variant='contained'
					onClick={async () => {
						await createGroup(values)
						onClose()
						setValues({
							name: '',
						})
					}}
				>
					Guardar
				</Button>
			</DialogActions>
		</Dialog>
	)
}
export default GroupForm
