import React from 'react'

import ProtectedRoute from './components/utils/ProtectedRoute'
import { Routes } from './config/routes'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import FileUploadView from './components/views/FileUploadView/FileUploadView'
import LoginView from './components/views/LoginView/LoginView'
import { useAppSelector } from './hooks/storeHooks'
import useStyles from './styles/appStyles'
import TopBar from './components/shared/TopBar/TopBar'
import FunctionsMenu from './components/shared/FunctionsMenu/FunctionsMenu'
import Theme from './components/utils/Theme'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import AdminView from './components/views/AdminView/AdminView'
import ReportsView from './components/views/ReportsView/ReportsView'
import TopoFileReviewView from './components/views/TopoFileReviewView/TopoFileReviewView'
import { TEXT_SIZES } from './services/config'
import SnackbarNotificationContainer from './components/shared/SnackbarNotificationContainer'
import PlotCreationView from './components/views/Report2/Report2'
import DataSubscriptionForm from './components/views/DataSubscriptionForm/DataSubscriptionForm'
import MeasurementTable from './components/views/MeasurementTableView/MeasurementTable'
import FileExplorerView from './components/views/FileExplorerView/FileExplorerView'
import ConfigView from './components/views/ConfigView/ConfigView'
import { createBrowserHistory } from 'history'
import ExcavationProgressForm from './components/views/ExcavationProgressView/ExcavationProgressForm'
import ReportViewerView from './components/views/ReportViewerView/ReportViewerView'
import { pdfjs } from 'react-pdf'
import ExcavationProgressView from './components/views/ExcavationProgressView/ExcavationProgressView'
import Accounts from './components/views/AccountsView/Accounts'
import MeasurementsView from './components/views/MeasurementTableView/MeasurementsView'
import moment from 'moment'
import DateFnsUtils from '@date-io/date-fns'
import esLocale from 'date-fns/locale/es'
import FilesView from './components/views/FilesView/FilesView'
import InitialView from './components/views/InitialView/InitialView'
import MapView from './components/views/MapView/MapView'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`
moment.locale('es')

const App: React.FC = () => {
	const isAuthenticated = useAppSelector((s) => !!s.authReducer.token)
	const classes = useStyles(isAuthenticated)()
	const textSize = useAppSelector((s) => TEXT_SIZES[s.configReducer.textSize])
	const historyInstance = createBrowserHistory()

	return (
		<Theme>
			{/* <MuiPickersUtilsProvider
				utils={MomentUtils}
				locale={moment.locale('es')}
			> */}
			<MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
				<div className={`${classes.root} ${textSize}`}>
					<BrowserRouter>
						{!isAuthenticated && <Redirect to={Routes.Login} />}
						{isAuthenticated && <TopBar />}
						{isAuthenticated && <FunctionsMenu />}
						<div className={classes.content}>
							<div
								className={
									isAuthenticated ? classes.toolbar : ''
								}
							></div>
							<Switch>
								<Route path={Routes.Login} exact>
									<LoginView />
								</Route>
								<ProtectedRoute
									path={['/']}
									exact
								>
									<InitialView />
								</ProtectedRoute>
								<ProtectedRoute
									path={[Routes.Map]}
									exact
								>
									<MapView />
								</ProtectedRoute>
								<ProtectedRoute
									path={[Routes.FileUpload]}
									exact
								>
									<FileUploadView />
								</ProtectedRoute>
								<ProtectedRoute
									path={[Routes.FileReview]}
									exact
								>
									<TopoFileReviewView />
								</ProtectedRoute>
								<ProtectedRoute path={[Routes.Admin]} exact>
									<ConfigView />
								</ProtectedRoute>
								<ProtectedRoute path={[Routes.Reports]} exact>
									<ReportsView />
								</ProtectedRoute>
								<ProtectedRoute
									path={[Routes.MeasurementTable]}
									exact
								>
									<MeasurementsView />
								</ProtectedRoute>
								<ProtectedRoute
									path={[Routes.FileExplorer]}
									exact
								>
									<FileExplorerView></FileExplorerView>
								</ProtectedRoute>
								<ProtectedRoute
									path={[Routes.ExcavationProgress]}
									exact
								>
									<ExcavationProgressView></ExcavationProgressView>
								</ProtectedRoute>
								<ProtectedRoute path={[Routes.Accounts]} exact>
									<Accounts></Accounts>
								</ProtectedRoute>
								<Route path={[Routes.DataExport]} exact>
									<DataSubscriptionForm />
								</Route>
								<Route path={[Routes.PlotCreation]} exact>
									<PlotCreationView />
								</Route>
								<ProtectedRoute
									path={[Routes.ReportViewer]}
									exact
								>
									<ReportViewerView></ReportViewerView>
								</ProtectedRoute>
								<ProtectedRoute path={[Routes.Files]} exact>
									<FilesView></FilesView>
								</ProtectedRoute>
							</Switch>
						</div>
					</BrowserRouter>
					<SnackbarNotificationContainer></SnackbarNotificationContainer>
				</div>
			</MuiPickersUtilsProvider>
		</Theme>
	)
}

export default App
