import AppBar from '@material-ui/core/AppBar'
import Clock from 'react-live-clock'
import useStyles from '../../../styles/appStyles'
import store from '../../../store'
import { useAppSelector } from '../../../hooks/storeHooks'
import Icon from '@mdi/react'
import { mdiAccount, mdiAlert, mdiCog, mdiMinus, mdiPlus } from '@mdi/js'
// import { mainColor } from "../../styles/styleVars.js"
// import { AlertActions } from "../../reducers/AlertReducer"
// import AlertsPopup from "../AlertsContainer/AlertsPopup"
import { useEffect, useRef, useState } from 'react'
// import AlertModal from "../AlertModal/AlertModal"
// import {
//     stopAlertSync,
//     syncAlerts,
//     useRaisedAlertStatus,
// } from "../../services/alert"
import { Menu, MenuItem, Switch } from '@material-ui/core'
import { setIsAdmin } from '../../../services/auth'
import { loadTimeZones, setTimeZone } from '../../../services/time'
import { decreaseTextSize, increaseTextSize } from '../../../services/config'
import logoMetro from '../../../assets/logo-metro.svg'

const TopBar: React.FC = () => {
	const classes = useStyles(true)()
	// const alertStatus = useRaisedAlertStatus()
	const isAdmin = useAppSelector((s) => s.authReducer.isEditing)
	const user = useAppSelector((s) => s.authReducer.user)
	const [timezoneMenuOpen, setTimezoneMenuOpen] = useState(false)
	const timeDisplay = useRef()
	const timeZone = useAppSelector((s) => s.configReducer.timeZone)
	useEffect(() => {
		// syncAlerts()
		loadTimeZones()
		return () => {
			// stopAlertSync()
		}
	}, [])

	// const alerts = useAppSelector((s) => s.alertReducer.alerts)
	const timeZones = useAppSelector((s) => s.configReducer.timeZones)

	//   const alertsPopupVisible = useAppSelector((s)=>s.alertReducer.alertsPopupVisible)

	// const handleClickOpenAlertsPopup = () => {
	//     store.dispatch({ type: AlertActions.ShowAlertsPopup, payload: {} })
	// }

	return (
		<AppBar className={classes.appBar} position='fixed'>
			{/* <AlertsPopup></AlertsPopup> */}
			<div
				className={`flex align-center items-center p-2 ${classes.toolbarContent}`}
			>
				<div className='logoadjust'>
					<img width='160px' src={logoMetro} alt='Geosinergia' />
				</div>
				<div className='text-gray-900 m-auto inline-flex items-center '>
					<div className='bg-white px-4 py-2 rounded-full text-xl flex gap-2'>
						<Clock
							format={'DD/MMM/YYYY HH:mm:ss'}
							ticking={true}
							timezone={timeZone}
						/>
						{/* <div
                            onClick={() => setTimezoneMenuOpen(true)}
                            className="cursor-pointer"
                            ref={timeDisplay}
                        >
                            {timeZone}
                        </div> */}
					</div>
					{/* <div
                        className="bg-white mx-2 p-2 cursor-pointer rounded-full text-2xl inline-flex items-center"
                        onClick={handleClickOpenAlertsPopup}
                    >
                        <Icon
                            path={mdiAlert}
                            title="Alertas"
                            size={1}
                            color={mainColor}
                        />
                    </div> */}
					{/* <div
                        className=""
                        style={{ transform: "translate(-20px,10px)" }}
                        onClick={handleClickOpenAlertsPopup}
                    >
                        <div className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
                            {alerts.length}
                        </div>
                    </div> */}
				</div>
				<div className='text-gray-900 mt-1 mb-1 ml-3 flex items-center'>
					<div className='bg-white p-2 flex items-center rounded-full text-base  mr-2'>
						<div onClick={increaseTextSize}>
							<Icon
								path={mdiPlus}
								size={1}
								className='text-gray-600 hover:text-gray-700 transition-all cursor-pointer'
							/>
						</div>
						Aa
						<div onClick={decreaseTextSize}>
							<Icon
								path={mdiMinus}
								size={1}
								className='text-gray-600 hover:text-gray-700 transition-all cursor-pointer'
							/>
						</div>
					</div>
					<div className='bg-white p-2 flex items-center rounded-full  text-2xl'>
						{user && user.username}
						<Icon path={mdiAccount} size={1} color='black' />
					</div>
				</div>
				{/* <div className="text-gray-600 mt-1 mb-1 ml-3 flex items-center">
                    <div className="bg-white  flex items-center rounded-full text-2xl">
                        <Switch
                            value={isAdmin}
                            onChange={(e) => setIsAdmin(e.target.checked)}
                            checked={isAdmin}
                            color="primary"
                        ></Switch>
                        <Icon path={mdiCog} size={1} className="mr-2" />
                    </div>
                </div> */}
			</div>
			{/* {alertStatus && (
                <AlertModal alertStatus={alertStatus} open={true}></AlertModal>
            )} */}
			{/* <Menu
				open={timezoneMenuOpen}
				onClose={() => setTimezoneMenuOpen(false)}
				anchorEl={timeDisplay.current}
			>
				{timeZones?.map((name) => (
					<MenuItem
						key={name}
						onClick={() => {
							setTimeZone(name)
							setTimezoneMenuOpen(false)
						}}
					>
						{name}
					</MenuItem>
				))}
			</Menu> */}
		</AppBar>
	)
}
export default TopBar
