import { ConfigActions } from '../reducers/ConfigReducer'
import store from '../store'

export const TEXT_SIZES = [
	'text-xs',
	'text-sm',
	'text-base',
	'text-lg',
	'text-xl',
	'text-2xl',
]

export const increaseTextSize = () => {
	store.dispatch({
		type: ConfigActions.SetTextSize,
		payload: Math.min(
			TEXT_SIZES.length - 1,
			store.getState().configReducer.textSize + 1
		),
	})
}

export const decreaseTextSize = () => {
	store.dispatch({
		type: ConfigActions.SetTextSize,
		payload: Math.max(0, store.getState().configReducer.textSize - 1),
	})
}
