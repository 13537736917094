import axios from 'axios'
import { FileActions } from '../reducers/FileReducer'
import store from '../store'
import { authHeader } from './auth'
const API_URL = process.env.REACT_APP_API_URL

export const getFileTypes = async () => {
	var response = await axios.get<Paginated<FileType>>(
		API_URL + 'file/type/',
		{
			headers: authHeader(),
		}
	)
	store.dispatch({
		type: FileActions.AddFileTypes,
		payload: response.data.results,
	})
	while (response.data.next) {
		response = await axios.get<Paginated<FileType>>(response.data.next, {
			headers: authHeader(),
		})
		store.dispatch({
			type: FileActions.AddFileTypes,
			payload: response.data.results,
		})
	}
}

export const createFileType = async (fileType: Partial<FileType>) => {
	var response = await axios.post<FileType>(
		API_URL + 'file/type/',
		fileType,
		{
			headers: authHeader(),
		}
	)
	store.dispatch({
		type: FileActions.AddFileTypes,
		payload: [response.data],
	})
}

export const editFileType = async (id: number, fileType: Partial<FileType>) => {
	var response = await axios.patch<FileType>(
		API_URL + `file/type/${id}/`,
		fileType,
		{
			headers: authHeader(),
		}
	)
	store.dispatch({
		type: FileActions.AddFileTypes,
		payload: [response.data],
	})
}

export const deleteFileType = async (id: number) => {
	var response = await axios.delete(API_URL + `file/type/${id}/`, {
		headers: authHeader(),
	})
	store.dispatch({
		type: FileActions.RemoveFileType,
		payload: id,
	})
}

export const getReports = async () => {
	var response = await axios.get<Paginated<Report>>(
		API_URL + 'file/report/',
		{
			headers: authHeader(),
		}
	)
	store.dispatch({
		type: FileActions.AddReports,
		payload: response.data.results,
	})
	while (response.data.next) {
		response = await axios.get<Paginated<Report>>(response.data.next, {
			headers: authHeader(),
		})
		store.dispatch({
			type: FileActions.AddReports,
			payload: response.data.results,
		})
	}
}

export const createReport = async (params: Partial<Report>, file: File) => {
	const formData = new FormData()
	formData.append('file', file)
	formData.append('type', params.type.toString())
	formData.append('group', params.group)
	formData.append('start_date', params.start_date)
	formData.append('end_date', params.end_date)
	formData.append('public', params.public ? 'True' : 'False')
	formData.append('origin', 'app')

	var response = await axios.post<Report>(
		API_URL + 'file/report/',

		formData,
		{ headers: { ...authHeader(), 'content-type': 'multipart/form-data' } }
	)
	store.dispatch({
		type: FileActions.AddReports,
		payload: [response.data],
	})
}

export const editReport = async (id: number, report: Partial<Report>) => {
	var response = await axios.patch<FileType>(
		API_URL + `file/report/${id}/`,
		report,
		{
			headers: authHeader(),
		}
	)
	store.dispatch({
		type: FileActions.AddReports,
		payload: [response.data],
	})
}

export const deleteReport = async (id: number) => {
	var response = await axios.delete(API_URL + `file/report/${id}/`, {
		headers: authHeader(),
	})
	store.dispatch({
		type: FileActions.RemoveReport,
		payload: id,
	})
}
