import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	TextField,
	Select,
	MenuItem,
	InputLabel,
} from '@material-ui/core'
import { useForm } from '../../../hooks/useForm'
import { createAxis, updateAxis } from '../../../services/axis'
import { addSnackbarNotification } from '../../../services/snackbarNotification'
import Checkbox from '../../shared/Checkbox'

interface Props {
	open: boolean
	onClose?: (event?: {}, reason?: 'backdropClick' | 'escapeKeyDown') => void
	axis: Axis
}

const AxisThresholdEditModal: React.FC<Props> = ({ open, onClose, axis }) => {

	const isObject = (obj: any) => {
		var type = typeof obj;
		return (type === 'function' || type === 'object') && !!obj;
	}

	const cloneObject = (src: any, f: Function) => {
		let target = {};
		let keys = Object.keys(src);
		for (let i = 0, len = keys.length; i < len; i++) {
			let key = keys[i];
			if (src.hasOwnProperty(key)) {
				// if the value is a referece(object), recursively copy all properties by calling deepClone
				let val = src[key];
				let isObj = isObject(val);
				if (isObj) {
					target[key] = cloneObject(val, f);
				} else {
					target[key] = f(val);
				}
			}
		}
		return target;
	}



	const mToMm = (thresholds: any) => {
		// console.log(thresholds)
		const fx1000 = (value: number) => value * 1000
		const newObj = cloneObject(thresholds, fx1000)
		// console.log(newObj)
		return newObj
	}

	const [values, setValues, handleChange] = useForm<any>({
		thresholds: mToMm(axis.thresholds),
	})

	return (
		<Dialog fullWidth maxWidth='md' open={open} onClose={onClose}>
			<DialogTitle>Editar Umbrales</DialogTitle>
			<DialogContent>
				<div className='text-center title-lg'>Umbrales[mm]</div>
				{/* <div className='w-full'>
					<div className='title-lg'>Normal</div>
					<div className='flex justify-between'>
						<div className='w-3'>
							<InputLabel>Norte</InputLabel>
							<TextField
								value={values.thresholds.caution.north}
								type='number'
								fullWidth
								onChange={(e) => {
									setValues({
										...values,
										thresholds: {
											...values.thresholds,
											caution: {
												...values.thresholds.caution,
												north: parseFloat(
													e.target.value
												),
											},
										},
									})
								}}
							></TextField>
						</div>
						<div className='w-3'>
							<InputLabel>Este</InputLabel>
							<TextField
								value={values.thresholds.caution.east}
								fullWidth
								type='number'
								onChange={(e) => {
									setValues({
										...values,
										thresholds: {
											...values.thresholds,
											caution: {
												...values.thresholds.caution,
												east: parseFloat(
													e.target.value
												),
											},
										},
									})
								}}
							></TextField>
						</div>
						<div className='w-3'>
							<InputLabel>Elevación</InputLabel>
							<TextField
								value={values.thresholds.caution.up}
								fullWidth
								type='number'
								onChange={(e) => {
									setValues({
										...values,
										thresholds: {
											...values.thresholds,
											caution: {
												...values.thresholds.caution,
												up: parseFloat(e.target.value),
											},
										},
									})
								}}
							></TextField>
						</div>
					</div>
				</div> */}
				<div className='w-full bg-yellow-200'>
					<div className='title-lg'>Advertencia</div>
					<div className='flex w-full'>
						<div className='w-1/3 flex flex-col'>
							<InputLabel>Convergencia</InputLabel>
							<TextField
								value={
									(values.thresholds.warning.x as number)
								}
								fullWidth
								type='number'
								onChange={(e) => {
									setValues({
										...values,
										thresholds: {
											...values.thresholds,
											warning: {
												...values.thresholds.warning,
												x: parseFloat(e.target.value),
											},
										},
									})
								}}
							></TextField>
						</div>
						<div className='w-1/3 flex flex-col'>
							<InputLabel>Longitudinal</InputLabel>
							<TextField
								value={
									(values.thresholds.warning.y as number)
								}
								fullWidth
								type='number'
								onChange={(e) => {
									setValues({
										...values,
										thresholds: {
											...values.thresholds,
											warning: {
												...values.thresholds.warning,
												y: parseFloat(e.target.value),
											},
										},
									})
								}}
							></TextField>
						</div>

						<div className='w-1/3 flex flex-col'>
							<InputLabel>Elevación</InputLabel>
							<TextField
								value={
									(values.thresholds.warning.z as number)
								}
								fullWidth
								type='number'
								onChange={(e) => {
									setValues({
										...values,
										thresholds: {
											...values.thresholds,
											warning: {
												...values.thresholds.warning,
												z: parseFloat(e.target.value),
											},
										},
									})
								}}
							></TextField>
						</div>
					</div>
				</div>

				<div className='w-full bg-red-200 '>
					<div className='title-lg'>Peligro</div>
					<div className='flex'>
						<div className='w-1/3 flex flex-col'>
							<InputLabel>Convergencia</InputLabel>
							<TextField
								value={
									(values.thresholds.danger.x as number)
								}
								fullWidth
								type='number'
								onChange={(e) => {
									setValues({
										...values,
										thresholds: {
											...values.thresholds,
											danger: {
												...values.thresholds.danger,
												x: parseFloat(e.target.value),
											},
										},
									})
								}}
							></TextField>
						</div>
						<div className='w-1/3 flex flex-col'>
							<InputLabel>Longitudinal</InputLabel>
							<TextField
								value={
									(values.thresholds.danger.y as number)
								}
								fullWidth
								type='number'
								onChange={(e) => {
									setValues({
										...values,
										thresholds: {
											...values.thresholds,
											danger: {
												...values.thresholds.danger,
												y: parseFloat(e.target.value),
											},
										},
									})
								}}
							></TextField>
						</div>

						<div className='w-1/3 flex flex-col'>
							<InputLabel>Elevación</InputLabel>
							<TextField
								value={
									(values.thresholds.danger.z as number)
								}
								fullWidth
								type='number'
								onChange={(e) => {
									setValues({
										...values,
										thresholds: {
											...values.thresholds,
											danger: {
												...values.thresholds.danger,
												z: parseFloat(e.target.value),
											},
										},
									})
								}}
							></TextField>
						</div>
					</div>
				</div>
			</DialogContent>
			<DialogActions>
				<Button
					variant='contained'
					color='primary'
					onClick={async () => {
						try {
							const aux = values
							const fdiv1000 = (value: number) => value / 1000
							var newObj = {thresholds: cloneObject(values.thresholds, fdiv1000)}
							// aux.thresholds = newObj

							await updateAxis(axis.id, newObj)
							addSnackbarNotification({ message: 'Eje editado' })
							onClose()
						} catch (error) {
							addSnackbarNotification({
								message: 'Ha ocurrido un error',
								type: 'error',
							})
						}
					}}
				>
					Guardar
				</Button>
				<Button onClick={() => onClose()}>Cancelar</Button>
			</DialogActions>
		</Dialog>
	)
}
export default AxisThresholdEditModal
