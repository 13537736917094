import React from 'react'
import useStyles from '../../../styles/appStyles'

const InitialView: React.FC = () => {
    const classes = useStyles(true)()

    return (
		<div
			className={`bg-gray-200 w-full overflow-auto p-3 ${classes.bodyContent}`}
		></div>
    )
}
export default InitialView