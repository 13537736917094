import { List, MenuItem, TableCell, TableRow } from '@material-ui/core'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useAppSelector } from '../../../hooks/storeHooks'
import { fetchAllAxes } from '../../../services/axis'
import {
	deleteExcavationProgress,
	editExcavationProgress,
	fetchAllExcavationProgress,
} from '../../../services/excavationProgress'
import { fetchAllPhases } from '../../../services/phase'
import {
	addSnackbarNotification,
	withError,
} from '../../../services/snackbarNotification'
import useStyles from '../../../styles/appStyles'
import DeleteButton from '../../shared/DeleteButton'
import EditableDateLabel from '../../shared/EditableDateLabel'
import EditableLabel from '../../shared/EditableLabel'
import AppTable from '../../utils/AppTable'
import ExcavationProgressForm from './ExcavationProgressForm'
const ConfigView: React.FC = () => {
	const classes = useStyles(true)()
	const [tab, setTab] = useState<'ExcavationProgress' | 'Notes'>(
		'ExcavationProgress'
	)
	const excavationProgress = useAppSelector(
		(s) => s.excavationProgressReducer.excavationProgress
	)
	const axes = useAppSelector((s) => s.axisReducer.axes)
	const selectedAxis = useAppSelector((s) => s.axisReducer.selectedAxis)
	const [phases, setPhases] = useState<Phase[]>(null)

	useEffect(() => {
		fetchAllExcavationProgress()
		fetchAllAxes()
		fetchAllPhases().then((phases) => setPhases(phases))
	}, [])

	return (
		<div className={`${classes.bodyContent} overflow-auto pb-2`}>
			{/* <div className='flex h-full'>
				<div className='w-1/6'>
					<h1 className='text-center title-lg'>Menu</h1>
					<List>
						<MenuItem
							selected={tab === 'ExcavationProgress'}
							onClick={() => setTab('ExcavationProgress')}
						>
							Avance de excavaciones
						</MenuItem>
						<MenuItem
							selected={tab === 'Notes'}
							onClick={() => setTab('Notes')}
						>
							Notas
						</MenuItem>
					</List>
				</div>
				<div>
					{tab === 'ExcavationProgress' && ( */}
			<ExcavationProgressForm></ExcavationProgressForm>
			<div className='overflow-auto mb-2'>
				<AppTable headers={['Eje', 'Distancia', 'Fecha', 'Fase']}>
					{excavationProgress
						.sort((a, b) => a.datetime.localeCompare(b.datetime))
						.filter((ep) =>
							selectedAxis ? selectedAxis.id === ep.axis : true
						)
						.map((ep) => (
							<TableRow key={ep.id}>
								<TableCell>
									{axes.find((a) => a.id === ep.axis)?.code}{' '}
									{axes.find((a) => a.id === ep.axis)?.name}
								</TableCell>
								<TableCell>
									<EditableLabel
										id='excavated_distance'
										value={ep.excavated_distance}
										label='Distancia'
										onSubmit={withError(
											async (excavated_distance) => {
												await editExcavationProgress(
													ep.id,
													{ excavated_distance }
												)
												addSnackbarNotification({
													message: 'Progreso editado',
												})
											}
										)}
									>
										{ep.excavated_distance}
									</EditableLabel>
								</TableCell>
								<TableCell>
									<EditableDateLabel
										value={moment(ep.datetime)}
										label={moment(ep.datetime).format(
											'DD/MMM/YYYY'
										)}
										id='datetime'
										onSubmit={withError(
											async (datetime: moment.Moment) => {
												await editExcavationProgress(
													ep.id,
													{
														datetime:
															datetime.toISOString(),
													}
												)
												addSnackbarNotification({
													message: 'Progreso editado',
												})
											}
										)}
									></EditableDateLabel>
								</TableCell>
								<TableCell>
									{
										phases?.find((a) => a.id === ep.phase)
											?.name
									}
								</TableCell>
								<TableCell>
									<DeleteButton
										onClick={withError(async () => {
											await deleteExcavationProgress(
												ep.id
											)
											addSnackbarNotification({
												message: 'Progreso eliminado',
											})
										})}
									></DeleteButton>
								</TableCell>
							</TableRow>
						))}
				</AppTable>
			</div>
			{/* )}
					{tab === 'Notes' && <></>}
				</div>
			</div> */}
		</div>
	)
}
export default ConfigView
