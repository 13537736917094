import { mdiAutorenew } from "@mdi/js"
import Icon from "@mdi/react"

interface Props {
    className?: string
}

const Spinner: React.FC<Props> = ({ className }) => (
    <div className={`w-full flex justify-center ${className ?? ""}`}>
        <Icon
            path={mdiAutorenew}
            color={"#AAA"}
            className="w-7 m-auto"
            spin
        ></Icon>
    </div>
)
export default Spinner
