import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  Card,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useAppSelector } from "../../../hooks/storeHooks";
import {
  createSpreadsheetReview,
  createTopoFileReview,
  getTopoFileReviews,
  getTopoFiles,
  topoFileMonitorSocket,
  uploadTopoFile,
} from "../../../services/topoFile";
import { SpreadsheetReview } from "../../../types/SpreadsheetReview";
import SpreadsheetReviewImportConfirmModal from "../FileReviewView/SpreadsheetReviewImportConfirmModal";
import useStyles from "../../../styles/appStyles";
import { TopoFileStatuses } from "../../../config/TopoFileStatuses";
import TopoFileReviewExpandable from "./TopoFileReviewExpandable";
import { getUsers } from "../../../services/auth";
import store from "../../../store";
import { TopoFileActions } from "../../../reducers/TopoFileReducer";
import moment from "moment";
import Checkbox from "../../shared/Checkbox";
import { withError } from "../../../services/snackbarNotification";

const TopoFileReviewView: React.FC = () => {
  const [sort, setSort] = useState<"date" | "name">("date");
  const inputFile = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File>(null);
  const topoFiles = useAppSelector((s) => s.topoFileReducer.topoFiles);
  const topoFileReviews = useAppSelector(
    (s) => s.topoFileReducer.topoFileReviews
  );
  const [topoFile, setTopoFile] = useState<number>();
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setFile(acceptedFiles[0]);
    },
  });
  const currentUser = useAppSelector((s) => s.authReducer.user?.id);

  const [
    spreadsheetReviewImportConfirmModalOpen,
    setSpreadsheetReviewImportConfirmModalOpen,
  ] = useState(false);

  const [targetSpreadSheetReview, setTargetSpreadSheetReview] =
    useState<SpreadsheetReview>(null);

  const [targetTopoFile, setTargetTopoFile] = useState<TopoFile>(null);

  const [socket, setSocket] = useState(null);

  useEffect(() => {
    getTopoFiles();
    getTopoFileReviews();
    getUsers();
    configSocket();
  }, []);

  useEffect(() => {
    if (socket) {
      socket.onmessage = function (e) {
        switch (e.data.model) {
          case "topofile":
            store.dispatch({
              type: TopoFileActions.AddTopoFile,
              payload: JSON.parse(e.data),
            });
            break;
          case "topofilereview":
            store.dispatch({
              type: TopoFileActions.AddTopoFileReview,
              payload: JSON.parse(e.data),
            });
            break;
        }
      };
    }
  }, [socket]);

  const configSocket = () => {
    setSocket(topoFileMonitorSocket());
  };

  const takeTask = async (tf: TopoFile) => {
    console.log(tf);
    await createTopoFileReview(tf);

    // download file
    const link = document.createElement("a");
    link.href = tf.datafile;
    link.setAttribute("download", "filename.txt");
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    getTopoFiles();
    getTopoFileReviews();
  };
  const [showAllDates, setShowAllDates] = useState(false);

  const classes = useStyles(true)();

  const selectedTopoFile = topoFiles?.find((tf) => tf.id === topoFile);

  return (
    <div className={`${classes.bodyContent} p-3 flex flex-col relative`}>
      <div className="absolute top-5 right-5">
        <RadioGroup
          value={sort}
          onChange={(e) => setSort(e.target.value as "date" | "name")}
          row
          style={{ flexDirection: "row", alignItems: "center" }}
        >
          <div className="h-full mr-2 flex items-center">Orden: </div>
          <FormControlLabel
            value="date"
            control={<Radio color="primary" />}
            label="Fecha"
            color="primary"
          />
          <FormControlLabel
            value="name"
            control={<Radio color="primary" />}
            label="Nombre"
            color="primary"
          />
        </RadioGroup>
      </div>
      <div className="text-center font-medium text-gray-600 text-lg">
        Ingreso de análisis de medición
      </div>
      <div className="text-center font-medium text-gray-600 text-lg">
        (Gabinete)
      </div>
      <div className="flex-1 flex overflow-auto">
        <div className="p-4 items-center w-2/5 overflow-auto space-y-2 h-full   border-r-2 border-gray-200">
          <div className="font-medium text-center text-gray-600 flex">
            Tareas Pendientes (
            {
              topoFiles?.filter((tf) => tf.status === TopoFileStatuses.STATUS_1)
                .length
            }
            )
          </div>
          {topoFiles
            ?.filter((tf) => tf.status === TopoFileStatuses.STATUS_1)
            .sort((a, b) =>
              sort === "date"
                ? new Date(b.created).getTime() - new Date(a.created).getTime()
                : a.datafile.localeCompare(b.datafile)
            )
            .map((tf) => (
              <Card key={tf.id}>
                <div className="flex w-full items-center justify-between p-2">
                  {tf?.datafile?.split("/").slice(-1)}
                  <div className="ml-2">
                    {moment(tf.created).format("DD/MM/YYYY HH:mm")}
                  </div>
                  <Button
                    className=""
                    variant="contained"
                    onClick={withError(() => takeTask(tf))}
                  >
                    Tomar tarea
                  </Button>
                </div>
              </Card>
            ))}
        </div>
        <div className="p-4 w-3/5 overflow-auto pb-0">
          <div className="font-medium text-center text-gray-600">
            Tareas en proceso (
            {
              topoFileReviews?.filter(
                (tr) =>
                  tr.creator === currentUser &&
                  !tr.confirmed &&
                  (moment(tr.created).isSame(moment(), "day") || showAllDates)
              ).length
            }
            ){" "}
            <Checkbox
              checked={showAllDates}
              onChange={() => setShowAllDates(!showAllDates)}
              label={"Mostrar fechas anteriores"}
            ></Checkbox>
          </div>
          {topoFileReviews
            ?.sort((a, b) =>
              sort === "date"
                ? new Date(b.created).getTime() - new Date(a.created).getTime()
                : topoFiles
                    .find((tf) => tf.id === a.topofile)
                    ?.datafile.localeCompare(
                      topoFiles.find((tf) => tf.id === b.topofile)?.datafile
                    )
            )
            .filter(
              (tf) =>
                tf.creator === currentUser &&
                (moment(tf.created).isSame(moment(), "day") || showAllDates)
            )
            .map((tfr) => (
              <TopoFileReviewExpandable
                showPast={showAllDates}
                topoFileReview={tfr}
                key={tfr.id}
              ></TopoFileReviewExpandable>
            ))}
          <div className="h-20"></div>
        </div>
      </div>
    </div>
  );
};
export default TopoFileReviewView;
