import { Reducer } from 'redux'
import { Section } from '../types/Section'

interface State {
	sections: Section[]
	isLoading: Map<number, boolean>
	selectedSection: Section
}

const initialState = {
	sections: [],
	isLoading: {} as Map<number, boolean>,
	selectedSection: null,
}

export enum SectionActions {
	RequestSections = 'REQUEST_SECTIONS',
	AddSections = 'ADD_SECTIONS',
	EndAxisRequest = 'SET_LOADING',
	SetSelectedSection = 'SET_SELECTED_SECTION',
	UpdateSection = 'UPDATE_SECTION',
	RemoveSection = 'REMOVE_SECTION',
}

const reducer: Reducer<State, { type: SectionActions; payload: any }> = (
	state: State = initialState,
	{ type, payload }
): State => {
	switch (type) {
		case SectionActions.RequestSections:
			return {
				...state,
				isLoading: { ...state.isLoading, [payload.id]: true },
			}
		case SectionActions.AddSections:
			return {
				...state,
				sections: [
					...state.sections.filter(
						(s) => !payload.find((ns) => ns.id === s.id)
					),
					...payload,
				],
			}
		case SectionActions.RemoveSection:
			return {
				...state,
				sections: [
					...state.sections.filter((s) => s.id !== payload.id),
				],
			}
		case SectionActions.EndAxisRequest:
			return {
				...state,
				isLoading: { ...state.isLoading, [payload.id]: false },
			}
		case SectionActions.SetSelectedSection:
			return { ...state, selectedSection: payload }
		default:
			return state
	}
}

export default reducer
