import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TableCell,
	TableRow,
	TextField,
} from '@material-ui/core'
import { DatePicker, DateTimePicker } from '@material-ui/pickers'
import moment, { Moment } from 'moment'
import { useEffect, useState } from 'react'
import { useForm } from '../../../hooks/useForm'
import {
	createNote,
	deleteNote,
	editNote,
	getSectionNotes,
} from '../../../services/note'
import {
	addSnackbarNotification,
	withError,
	withErrors,
} from '../../../services/snackbarNotification'
import { Section } from '../../../types/Section'
import DeleteButton from '../../shared/DeleteButton'
import EditableLabel from '../../shared/EditableLabel'
import AppTable from '../../utils/AppTable'

interface Props {
	open: boolean
	onClose?: (event?: {}, reason?: 'backdropClick' | 'escapeKeyDown') => void
	section: Section
}

const SectionNotes: React.FC<Props> = ({ open, onClose, section }) => {
	const [notes, setNotes] = useState<Note[]>([])
	const [newNote, setNewNote] = useState(false)

	const [values, setValues, handleChange] = useForm<{
		text: string
		attach_to: number
		importance: 1 | 2 | 3 | 4 | 5 | 6 | 7
		dt_gen: Moment
	}>({
		text: '',
		attach_to: 0,
		importance: 1,
		dt_gen: moment(),
	})

	useEffect(() => {
		open && getSectionNotes(section.id).then((res) => setNotes(res.results))
	}, [open])

	return (
		<Dialog fullWidth maxWidth='md' open={open} onClose={onClose}>
			{newNote ? (
				<>
					<DialogTitle>
						<div className='relative'>
							Notas
							<div className='absolute right-2 top-2'>
								<Button onClick={() => setNewNote(false)}>
									Volver
								</Button>
							</div>
						</div>
					</DialogTitle>
					<DialogContent>
						<div className='space-y-2 fle-col'>
							<div className='flex'>
								<div className='title-lg mr-2'>
									Importancia:{' '}
								</div>
								<Select
									label='Sección'
									placeholder='Seleccione importancia'
									fullWidth
									onChange={(e) =>
										setValues({
											...values,
											importance: e.target.value as
												| 1
												| 2
												| 3
												| 4
												| 5
												| 6
												| 7,
										})
									}
									value={values.importance}
								>
									{[1, 2, 3, 4, 5, 6, 7].map((i) => (
										<MenuItem value={i} key={i}>
											{i}
										</MenuItem>
									))}
								</Select>
							</div>
							<div>
								<TextField
									label='Nota'
									value={values.text}
									id='text'
									placeholder={'Ingrese nota'}
									multiline
									fullWidth
									minRows={3}
									onChange={(e) =>
										setValues({
											...values,
											text: e.target.value,
										})
									}
								></TextField>
							</div>
							<div className='p-1'>
								<DatePicker
									disableFuture
									value={values.dt_gen}
									onChange={(time) => {
										setValues({
											...values,
											dt_gen: moment(time),
										})
										// adjustRange()
									}}
									label='Fecha'
									InputLabelProps={{
										shrink: true,
									}}
									format='dd/MMM/yyyy'
								></DatePicker>
							</div>
							<div>
								<Button
									variant='contained'
									color='primary'
									fullWidth
									onClick={withError(async () => {
										if (values.text === '') {
											addSnackbarNotification({
												message: 'Ingrese nota',
												type: 'error',
											})
											return
										}

										var aux: any = values
										aux.attach_to = section.id

										await createNote(aux)
										getSectionNotes(section.id).then(
											(res) => setNotes(res.results)
										)
										setNewNote(false)
										addSnackbarNotification({
											message: 'Nota guardada',
										})
									})}
								>
									Guardar Nota
								</Button>
							</div>
						</div>
					</DialogContent>
				</>
			) : (
				<>
					<DialogTitle>
						<div className='relative'>
							Notas
							<div className='absolute right-2 top-2'>
								<Button
									variant='contained'
									color='primary'
									onClick={() => setNewNote(true)}
								>
									Nueva nota
								</Button>
							</div>
						</div>
					</DialogTitle>
					<DialogContent>
						<AppTable headers={['Texto', 'Importancia', 'Fecha']}>
							{notes
								?.sort(
									(a, b) =>
										new Date(a.dt_gen).getTime() -
										new Date(b.dt_gen).getTime()
								)
								.map((n) => (
									<TableRow>
										<TableCell>
											<EditableLabel
												id='text'
												label='Texto'
												value={n.text}
												onSubmit={async (text) => {
													withErrors(async () => {
														await editNote(n.id, {
															text,
														})
													})
												}}
											>
												{n.text}
											</EditableLabel>
										</TableCell>
										<TableCell>{n.importance}</TableCell>
										<TableCell>
											{moment(n.dt_gen).format(
												'DD/MM/YYYY'
											)}
										</TableCell>
										<TableCell>
											<DeleteButton
												onClick={async () => {
													await deleteNote(n.id)
													getSectionNotes(
														section.id
													).then((res) =>
														setNotes(res.results)
													)
												}}
											/>
										</TableCell>
									</TableRow>
								))}
						</AppTable>
					</DialogContent>
				</>
			)}
			<DialogActions>
				<Button onClick={async () => onClose()}>Volver</Button>
			</DialogActions>
		</Dialog>
	)
}

export default SectionNotes
