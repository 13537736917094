import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
} from '@material-ui/core'
import { uploadFile } from '../../../services/scheme'
import { addSnackbarNotification } from '../../../services/snackbarNotification'

interface Props {
	open: boolean
	onClose?: (event?: {}, reason?: 'backdropClick' | 'escapeKeyDown') => void
	file: File
	directory: string
}

const UploadFileModal: React.FC<Props> = ({
	open,
	onClose,
	file,
	directory,
}) => {
	return (
		<Dialog fullWidth maxWidth='sm' open={open} onClose={onClose}>
			<DialogTitle>¿Subir archivo {file?.name}?</DialogTitle>
			<DialogContent></DialogContent>
			<DialogActions>
				<Button
					variant='contained'
					color='primary'
					onClick={async () => {
						await uploadFile(file, directory)
						addSnackbarNotification({ message: 'Archivo subido' })
						onClose()
					}}
				>
					Subir
				</Button>
				<Button onClick={() => onClose()}>Cancelar</Button>
			</DialogActions>
		</Dialog>
	)
}
export default UploadFileModal
