import { mdiCloseThick, mdiTrashCan } from '@mdi/js'
import Icon from '@mdi/react'
import { MouseEventHandler } from 'react'

interface Props {
	onClick: MouseEventHandler
}

const DeleteButton: React.FC<Props> = ({ onClick }) => {
	return (
		<div className='w-5 h-5 text-center m-auto' onClick={onClick}>
			<Icon
				path={mdiTrashCan}
				className='text-gray-700 hover:text-red-500 cursor-pointer transition-all'
			></Icon>
		</div>
	)
}
export default DeleteButton
