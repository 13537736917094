import {
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
} from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import { useState } from 'react'
import { createFileType } from '../../../services/file'
import {
	addSnackbarNotification,
	withError,
} from '../../../services/snackbarNotification'

interface Props {
	open: boolean
	onClose?: (event?: {}, reason?: 'backdropClick' | 'escapeKeyDown') => void
}
interface Props {}

const NewFileTypeModal: React.FC<Props> = ({ open, onClose }) => {
	const [name, setName] = useState('')

	return (
		<Dialog fullWidth maxWidth='md' open={open} onClose={onClose}>
			<DialogTitle>Nuevo Tipo de Reporte</DialogTitle>
			<DialogContent>
				<TextField
					value={name}
					onChange={(e) => setName(e.target.value)}
					label='Nombre'
					fullWidth
					id='name'
					margin='normal'
				></TextField>
			</DialogContent>
			<DialogActions>
				<Button
					variant='contained'
					color='primary'
					onClick={withError(async () => {
						await createFileType({ name })
						addSnackbarNotification({ message: 'Tipo creado' })
						onClose()
					})}
				>
					Guardar
				</Button>
				<Button onClick={() => onClose()}>Cancelar</Button>
			</DialogActions>
		</Dialog>
	)
}
export default NewFileTypeModal
