import axios from 'axios'
import { authHeader } from './auth'

const API_URL = process.env.REACT_APP_API_URL
const SPREADSHEET_REVIEW = process.env.REACT_APP_PATH_SPREADSHEET_REVIEW

export const spreadsheetCommitImport = async (id: number) => {
    // store.dispatch({ type: TopoFileActions.RequestTopoFiles, payload: {} })
    const response = await axios.get(
        API_URL + SPREADSHEET_REVIEW + id + '/commit_import/', {
        headers: authHeader(),
    })
    // store.dispatch({ type: TopoFileActions.SetTopoFiles, payload: response.data.results })
}
