import { useEffect } from 'react'
import { useAppSelector } from '../../../hooks/storeHooks'
import { fetchAllAxes } from '../../../services/axis'
import useStyles from '../../../styles/appStyles'
import AxesListCard from '../../shared/AxesListCard/AxesListCard'
import PrismPointCharts from './PrismPointCharts'

const ReportsView: React.FC = () => {
	const classes = useStyles(true)()

	const selectedSection = useAppSelector(
		(s) => s.sectionReducer.selectedSection
	)

	useEffect(() => {
		fetchAllAxes()
	}, [])
	return (
		<div className={`flex ${classes.bodyContent} `}>
			<div className='w-1/5 bg-gray-100 p-2 flex flex-col'>
				<AxesListCard></AxesListCard>
			</div>

			<div className='w-4/5 bg-gray-100 p-2 flex flex-col'>
				{selectedSection ? (
					<PrismPointCharts></PrismPointCharts>
				) : (
					<div className='text-center text-gray-600 mt-4'>
						Seleccione una sección
					</div>
				)}
			</div>
		</div>
	)
}
export default ReportsView
