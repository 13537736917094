import axios from 'axios'
import { authHeader } from './auth'

const API_URL = process.env.REACT_APP_API_URL

export const createNote = async (note: Partial<Note>) => {
	const response = await axios.post<Note>(API_URL + 'note/text/', note, {
		headers: authHeader(),
	})
}

export const editNote = async (id: number, note: Partial<Note>) => {
	const response = await axios.patch<Note>(API_URL + 'note/text/', note, {
		headers: authHeader(),
	})
}

export const getSectionNotes = async (id: number) => {
	const response = await axios.get<Paginated<Note>>(
		API_URL + `note/text/?attach_to=${id}`,
		{
			headers: authHeader(),
		}
	)
	return response.data
}

export const deleteNote = async (id: number) => {
	const response = await axios.delete(API_URL + `note/text/${id}/`, {
		headers: authHeader(),
	})
}
