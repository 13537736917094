import { Reducer } from "react";
import { Group } from "../types/Group";

interface State {
    groups: Group[]
}

const initialState: State = {
    groups: []
}

export enum GroupActions {
    SetGroup = 'SET_GROUP',
    SetGroups = 'SET_GROUPS',
}

const reducer: Reducer<State, { type: GroupActions, payload: any }> = (state: State = initialState, { type, payload }): State => {
    switch (type) {
        case GroupActions.SetGroups:
            return { ...state, groups: payload }
        default:
            return state;
    }
}

export default reducer