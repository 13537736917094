import axios from 'axios'
import { Measurement } from '../types/Measurement'
import { authHeader } from './auth'

const API_URL = process.env.REACT_APP_API_URL

export const fetchMeasurments = async (filter?: {
	status?: string
	axis__short_code?: string
	section__short_code: string
}): Promise<Paginated<Measurement>> => {
	if (!filter) {
		const response = await axios.get<Paginated<Measurement>>(
			API_URL + 'prism_point/measurement/',
			{ headers: authHeader() }
		)
		return response.data
	} else {
		const params = filter
			? `?${Object.keys(filter).reduce(
					(acc, val, i) =>
						acc + `${i === 0 ? '' : '&'}${val}=${filter[val]}`,
					''
			  )}`
			: ''
		const response = await axios.get<Paginated<Measurement>>(
			API_URL + 'prism_point/measurement/' + params,
			{ headers: authHeader() }
		)
		return response.data
	}
}

export const updateMeasurement = async (
	id: number,
	update: Partial<Measurement>
) => {
	const response = await axios.patch<Measurement>(
		API_URL + `prism_point/measurement/${id}/`,
		update,
		{ headers: authHeader() }
	)
}
