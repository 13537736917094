import { List, MenuItem } from '@material-ui/core'
import { useState } from 'react'
import useStyles from '../../../styles/appStyles'
import AxesConfig from './AxesConfig'
import PrismPointsConfig from './PrismPointConfig'
import SectionsConfig from './SectionsConfig'
import FixedPrismPointConfig from './FixedPrismPointConfig'

const ConfigView: React.FC = () => {
	const classes = useStyles(true)()
	const [tab, setTab] = useState<
		'Axes' | 'Sections' | 'Points' | 'FixedPoints'
	>('Axes')

	return (
		<div className={`${classes.bodyContent}`}>
			<div className='flex h-full'>
				<div className='w-1/6'>
					<h1 className='text-center title-lg'>Entidades</h1>
					<List>
						<MenuItem
							selected={tab === 'Axes'}
							onClick={() => setTab('Axes')}
						>
							Ejes
						</MenuItem>
						<MenuItem
							selected={tab === 'Sections'}
							onClick={() => setTab('Sections')}
						>
							Secciones
						</MenuItem>
						<MenuItem
							selected={tab === 'Points'}
							onClick={() => setTab('Points')}
						>
							Puntos
						</MenuItem>
					</List>
				</div>
				<div className='w-5/6 overflow-auto'>
					{tab === 'Axes' && <AxesConfig></AxesConfig>}
					{tab === 'Sections' && <SectionsConfig></SectionsConfig>}
					{tab === 'Points' && (
						<PrismPointsConfig></PrismPointsConfig>
					)}
				</div>
			</div>
		</div>
	)
}
export default ConfigView
