import { Reducer } from 'react'
import { Profile } from '../types/Profile'

interface State {
	profiles: Profile[]
	jobtitles: JobTitle[]
	group_assignations: GroupAssignation[]
}

const initialState: State = {
	profiles: [],
	jobtitles: [],
	group_assignations: [],
}

export enum ProfileActions {
	SetProfile = 'SET_PROFILE',
	SetProfiles = 'SET_PROFILES',
	SetJobTitles = 'SET_JOB_TITLES',
	SetGroupAssignations = 'SET_GROUP_ASSIGNATIONS',
}

const reducer: Reducer<State, { type: ProfileActions; payload: any }> = (
	state: State = initialState,
	{ type, payload }
): State => {
	switch (type) {
		case ProfileActions.SetProfiles:
			return { ...state, profiles: payload }
		case ProfileActions.SetJobTitles:
			return { ...state, jobtitles: payload }
		case ProfileActions.SetGroupAssignations:
			return { ...state, group_assignations: payload }
		default:
			return state
	}
}

export default reducer
