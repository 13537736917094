import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@material-ui/core'
import {
    withError,
} from '../../../services/snackbarNotification'
import { SchemeNode } from '../../../types/Scheme'

interface Props {
    open: boolean
    onClose?: (event?: {}, reason?: 'backdropClick' | 'escapeKeyDown') => void
    node: SchemeNode
    onAccept: (node: SchemeNode) => void
}

const DeleteConfirmationModal: React.FC<Props> = ({
    open,
    onClose,
    node,
    onAccept,
}) => {
    if (!node) return <></>
    return (
        <Dialog fullWidth maxWidth='md' open={open} onClose={onClose} >
            <DialogTitle>
                Advertencia de seguridad 
            </DialogTitle>
            <DialogContent>
                <div className='font-medium'>
                    Se eliminará el fichero {' '}
                    {
                        node.name
                    }
                    <br />
                    Esta acción no puede deshacerse.
                </div>
                <br />
            </DialogContent>
            <DialogActions>
                <Button
                    variant='contained'
                    color='primary'
                    onClick={withError(() =>
                        // deleteDocument(node).then(
                        //     () => {
                        //         onClose()
                        //         addSnackbarNotification({
                        //             message: 'Documento eliminado',
                        //         })
                        //     }
                        // )
                        onAccept(node)
                    )}
                >
                    Estoy seguro. Eliminar documento.
                </Button>
                <Button onClick={() => onClose()}>Cancelar</Button>
            </DialogActions>
        </Dialog>
    )
}
export default DeleteConfirmationModal
