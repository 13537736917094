import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Icon from "@mdi/react";
import GeoSinergiaLogo from "./../../../assets/GeoSinergiaLogo.svg";
import useStyles from "../../../styles/appStyles";

import {
  mdiExitToApp,
  mdiFolderMarker,
  mdiMicrosoftExcel,
  mdiFileChart,
  mdiCogOutline,
  mdiTools,
  mdiExport,
  mdiTable,
  mdiFolderHome,
  mdiBulldozer,
  mdiAccountAlert,
  mdiPageNextOutline,
  mdiMap,
} from "@mdi/js";

import { Link, useHistory, useLocation } from "react-router-dom";
import { logout } from "../../../services/auth";
import { Routes } from "../../../config/routes";
import { secondaryColor } from "../../../styles/styleVars";
import { FrontendPermissions } from "../../../config/FrontendPermissions";
import { useAppSelector } from "../../../hooks/storeHooks";

const FunctionsMenu: React.FC = () => {
  const classes = useStyles(true)();

  const location = useLocation();

  const frontendPermissions = useAppSelector((s) => s.authReducer.frontendPermissions)
  const [showMap, setShowMap] = useState(false)

  return (
    <>
      <Drawer
        variant="permanent"
        anchor="left"
        open
        className={`drawerstyle ${classes.drawer}`}
      >
        <div
          className={`${classes.toolbarContent} flex flex-col justify-center`}
        >
          <img
            src={GeoSinergiaLogo}
            className="m-auto"
            width="40px"
            alt="Geosinergia"
            onClick={() => {
              setShowMap(!showMap)
            }}
          />
        </div>
        <Divider />
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          alignItems="stretch"
          style={{ height: "100%" }}
          className="overflow-auto"
        >
          <Grid item>
            <List component="nav" aria-label="main mailbox folders">
              {frontendPermissions.find(
                (p) => p === FrontendPermissions.Terrain
              ) && (
                  <Tooltip
                    title={<div className="text-lg">Terreno</div>}
                    placement="right"
                    aria-label="add"
                  >
                    <ListItem button key="li_monit" component={Link} to={Routes.FileUpload}>
                      <ListItemIcon className="f_menu_icon">
                        <Icon
                          path={mdiFolderMarker}
                          title=""
                          size={1}
                          color={
                            location.pathname === Routes.FileUpload ? secondaryColor : "white"
                          }
                        />
                      </ListItemIcon>
                    </ListItem>
                  </Tooltip>
                )}
              {frontendPermissions.find(
                (p) => p === FrontendPermissions.Input
              ) && (
                  <Tooltip
                    title={
                      <div className="text-lg">Ingreso de datos de medición</div>
                    }
                    placement="right"
                    aria-label="add"
                  >
                    <ListItem button key="li_alerts" component={Link} to="/review">
                      <ListItemIcon className="f_menu_icon">
                        <Icon
                          path={mdiMicrosoftExcel}
                          title=""
                          size={1}
                          color={
                            location.pathname === Routes.FileReview
                              ? secondaryColor
                              : "white"
                          }
                        />
                      </ListItemIcon>
                    </ListItem>
                  </Tooltip>
                )}
              {frontendPermissions.find(
                (p) => p === FrontendPermissions.NubeDeDatos
              ) && (
                  <Tooltip
                    title={<div className="text-lg">Base de datos</div>}
                    placement="right"
                    aria-label="add"
                  >
                    <ListItem
                      button
                      key="li_measurements"
                      component={Link}
                      to={Routes.MeasurementTable}
                    >
                      <ListItemIcon className="f_menu_icon">
                        <Icon
                          path={mdiTable}
                          title=""
                          size={1}
                          color={
                            location.pathname === Routes.MeasurementTable
                              ? secondaryColor
                              : "white"
                          }
                        />
                      </ListItemIcon>
                    </ListItem>
                  </Tooltip>
                )}
              {frontendPermissions.find(
                (p) => p === FrontendPermissions.Export
              ) && (
                  <Tooltip
                    title={<div className="text-lg">Exportar datos</div>}
                    placement="right"
                    aria-label="add"
                  >
                    <ListItem
                      button
                      key="li_alerts"
                      component={Link}
                      to={Routes.DataExport}
                    >
                      <ListItemIcon className="f_menu_icon">
                        <Icon
                          path={mdiExport}
                          title=""
                          size={1}
                          color={
                            location.pathname === Routes.DataExport
                              ? secondaryColor
                              : "white"
                          }
                        />
                      </ListItemIcon>
                    </ListItem>
                  </Tooltip>
                )}
              {frontendPermissions.find(
                (p) => p === FrontendPermissions.Charts
              ) && (
                  <Tooltip
                    title={<div className="text-lg">Creación de Gráficos</div>}
                    placement="right"
                    aria-label="add"
                  >
                    <ListItem
                      button
                      key="li_alerts"
                      component={Link}
                      to={Routes.PlotCreation}
                    >
                      <ListItemIcon className="f_menu_icon">
                        <Icon
                          path={mdiFileChart}
                          title=""
                          size={1}
                          color={
                            location.pathname === Routes.PlotCreation
                              ? secondaryColor
                              : "white"
                          }
                        />
                      </ListItemIcon>
                    </ListItem>
                  </Tooltip>
                )}
              {frontendPermissions.find(
                (p) => p === FrontendPermissions.Admin
              ) && (
                  <Tooltip
                    title={<div className="text-lg">Admin</div>}
                    placement="right"
                    aria-label="add"
                  >
                    <ListItem
                      button
                      key="li_admin"
                      component={Link}
                      to="/administration"
                    >
                      <ListItemIcon className="f_menu_icon">
                        <Icon
                          path={mdiTools}
                          title=""
                          size={1}
                          color={
                            location.pathname === Routes.Admin
                              ? secondaryColor
                              : "white"
                          }
                        />
                      </ListItemIcon>
                    </ListItem>
                  </Tooltip>
                )}
              {/* <Tooltip
                                title="REPORTES"
                                placement="right"
                                aria-label="add"
                            >
                                    <ListItem 
                                    button
                                    key="li_alerts"
                                    component={Link}
                                    to="/reports">
                                        <ListItemIcon className="f_menu_icon">
                                            <Icon
                                                path={mdiTextBoxOutline}
                                                title=""
                                                size={1}
                                                color="white"
                                            />
                                        </ListItemIcon>
                                    </ListItem>
                            </Tooltip> */}
              {/* <Tooltip
                                title="DOCUMENTOS"
                                placement="right"
                                aria-label="add"
                            >
                                <ListItem
                                    button
                                    key="li_alerts"
                                    component={Link}
                                    to="/documents"
                                >
                                    <ListItemIcon className="f_menu_icon">
                                        <Icon
                                            path={mdiBookshelf}
                                            title=""
                                            size={1}
                                            color="white"
                                        />
                                    </ListItemIcon>
                                </ListItem>
                            </Tooltip> */}
              {/* <Tooltip
								title={
									<div className='text-lg'>Configuración</div>
								}
								placement='right'
								aria-label='add'
							>
								<ListItem
									button
									key='li_inventory'
									component={Link}
									to='/admin'
								>
									<ListItemIcon className='f_menu_icon'>
										<Icon
											path={mdiCogOutline}
											title=''
											size={1}
											color={
												location.pathname ===
												Routes.
													? secondaryColor
													: 'white'
											}
										/>
									</ListItemIcon>
								</ListItem>
							</Tooltip> */}
              {frontendPermissions.find(
                (p) => p === FrontendPermissions.Accounts
              ) && (
                  <Tooltip
                    title={<div className="text-lg">Cuentas</div>}
                    placement="right"
                    aria-label="add"
                  >
                    <ListItem
                      button
                      key="li_accounts"
                      component={Link}
                      to={Routes.Accounts}
                    >
                      <ListItemIcon className="f_menu_icon">
                        <Icon
                          path={mdiAccountAlert}
                          title=""
                          size={1}
                          color={
                            location.pathname === Routes.Accounts
                              ? "white"
                              : "white"
                          }
                        />
                      </ListItemIcon>
                    </ListItem>
                  </Tooltip>
                )}
              {frontendPermissions.find(
                (p) => p === FrontendPermissions.Progress
              ) && (
                  <Tooltip
                    title={<div className="text-lg">Progreso de excavación</div>}
                    placement="right"
                    aria-label="add"
                  >
                    <ListItem
                      button
                      key="li_excavation_progress"
                      component={Link}
                      to={Routes.ExcavationProgress}
                    >
                      <ListItemIcon className="f_menu_icon">
                        <Icon
                          path={mdiBulldozer}
                          title=""
                          size={1}
                          color={
                            location.pathname === Routes.ExcavationProgress
                              ? secondaryColor
                              : "white"
                          }
                        />
                      </ListItemIcon>
                    </ListItem>
                  </Tooltip>
                )}
            </List>
            {frontendPermissions.find(
              (p) => p === FrontendPermissions.Files
            ) && (
                <Tooltip
                  title={<div className="text-lg">Antecedentes del Proyecto</div>}
                  placement="right"
                  aria-label="add"
                >
                  <ListItem
                    button
                    key="li_inventory"
                    component={Link}
                    to="/explorer"
                  >
                    <ListItemIcon className="f_menu_icon">
                      <Icon
                        path={mdiFolderHome}
                        title=""
                        size={1}
                        color={
                          location.pathname === Routes.FileExplorer
                            ? secondaryColor
                            : "white"
                        }
                      />
                    </ListItemIcon>
                  </ListItem>
                </Tooltip>
              )}
            {frontendPermissions.find(
              (p) => p === FrontendPermissions.Reports
            ) && (
                <Tooltip
                  title={<div className="text-lg">Informes</div>}
                  placement="right"
                  aria-label="add"
                >
                  <ListItem
                    button
                    key="li_inventory"
                    component={Link}
                    to={Routes.Files}
                  >
                    <ListItemIcon className="f_menu_icon">
                      <Icon
                        path={mdiPageNextOutline}
                        title=""
                        size={1}
                        color={
                          location.pathname === Routes.Files
                            ? secondaryColor
                            : "white"
                        }
                      />
                    </ListItemIcon>
                  </ListItem>
                </Tooltip>
              )}
            { showMap && (<Tooltip
              title={<div className="text-lg">Map</div>}
              placement="right"
              aria-label="add"
              hidden={true}
            >
              <ListItem button key="li_map" component={Link} to={Routes.Map} hidden={true}>
                <ListItemIcon className="f_menu_icon" hidden={true}>
                  <Icon
                    path={mdiMap}
                    title=""
                    size={1}
                    color={
                      location.pathname === Routes.Map ? secondaryColor : "white"
                    }
                  />
                </ListItemIcon>
              </ListItem>
            </Tooltip>)}
          </Grid>
          <Grid item>
            <Tooltip
              title="CERRAR SESIÓN"
              placement="right"
              aria-label="add"
              onClick={logout}
            >
              <ListItem button>
                <ListItemIcon className="f_menu_icon">
                  <Icon
                    path={mdiExitToApp}
                    title=""
                    size={1}
                    color="white"
                    className="drawerIcon"
                  />
                </ListItemIcon>
              </ListItem>
            </Tooltip>

          </Grid>
        </Grid>
      </Drawer>
    </>
  );
};
export default FunctionsMenu;
