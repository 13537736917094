import { Button, MenuItem, TableCell, TableRow } from '@material-ui/core'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useAppSelector } from '../../../hooks/storeHooks'
import {
	deleteFileType,
	deleteReport,
	editFileType,
	editReport,
	getFileTypes,
	getReports,
} from '../../../services/file'
import {
	addSnackbarNotification,
	withError,
} from '../../../services/snackbarNotification'
import DeleteButton from '../../shared/DeleteButton'
import EditableLabel from '../../shared/EditableLabel'
import EditableSelect from '../../shared/EditableSelect'
import AppTable from '../../utils/AppTable'
import NewFileTypeModal from './NewFileTypeModal'
import NewReportModal from './NewReportModal'

const ReportConfig: React.FC = () => {
	const fileTypes = useAppSelector((s) => s.fileReducer.fileTypes)
	const reports = useAppSelector((s) => s.fileReducer.reports)
	const [newReportModal, setNewReportModal] = useState(false)

	useEffect(() => {
		getFileTypes()
		getReports()
	}, [])
	return (
		<div>
			<div className='title-xl text-center p-2'>
				Reportes
				<Button
					color='primary'
					variant='contained'
					className='float-right'
					onClick={() => setNewReportModal(true)}
				>
					Nuevo Reporte
				</Button>
			</div>
			<AppTable headers={['Reporte', 'Tipo', 'Grupo', 'Inicio', 'Fin']}>
				{reports
					.sort((a, b) => a.start_date.localeCompare(b.start_date))
					.map((r) => (
						<TableRow key={r.id}>
							<TableCell>
								<a
									href={r.file}
									target='_blank'
									rel='noreferrer'
								>
									{r.file.split('/').pop()}
								</a>
							</TableCell>
							<TableCell>
								<EditableSelect<number>
									id='type'
									value={r.type}
									onSubmit={withError(async (type) => {
										await editReport(r.id, { type })
										addSnackbarNotification({
											message: 'Reporte editado',
										})
									})}
									labelValue={
										fileTypes.find((ft) => ft.id === r.type)
											?.name
									}
								>
									{fileTypes.map((ft) => (
										<MenuItem key={ft.id} value={ft.id}>
											{ft.name}
										</MenuItem>
									))}
								</EditableSelect>
							</TableCell>
							<TableCell>
								<EditableSelect<string>
									id='group'
									value={r.group}
									onSubmit={withError(async (group) => {
										await editReport(r.id, { group })
										addSnackbarNotification({
											message: 'Reporte editado',
										})
									})}
								>
									<MenuItem key={'metro'} value={'metro'}>
										{'metro'}
									</MenuItem>
									<MenuItem
										key={'particular'}
										value={'particular'}
									>
										{'particular'}
									</MenuItem>
								</EditableSelect>
							</TableCell>
							<TableCell>
								{moment(r.start_date).format('DD/MM/YYYY')}
							</TableCell>
							<TableCell>
								{moment(r.end_date).format('DD/MM/YYYY')}
							</TableCell>
							<TableCell>
								<DeleteButton
									onClick={withError(async () => {
										await deleteReport(r.id)
										addSnackbarNotification({
											message: 'Reporte eliminado',
										})
									})}
								></DeleteButton>
							</TableCell>
						</TableRow>
					))}
			</AppTable>
			<NewReportModal
				open={newReportModal}
				onClose={() => setNewReportModal(false)}
			></NewReportModal>
		</div>
	)
}
export default ReportConfig
