import { Button, List, MenuItem, TableCell, TableRow } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useAppSelector } from '../../../hooks/storeHooks'
import { deleteGroup, editGroup, getGroups } from '../../../services/auth'
import { withErrors } from '../../../services/snackbarNotification'
import useStyles from '../../../styles/appStyles'
import DeleteButton from '../../shared/DeleteButton'
import EditableLabel from '../../shared/EditableLabel'
import AppTable from '../../utils/AppTable'
import GroupForm from './GroupForm'

const GroupsComponent: React.FC = () => {
	const classes = useStyles(true)()

	const groups = useAppSelector((s) => s.groupReducer.groups)
	const [formVisible, setFormVisible] = useState(false)

	useEffect(() => {
		getGroups()
	}, [])

	return (
		<div className={`${classes.bodyContent}`}>
			<div className='flex h-full'>
				<div className='w-full'>
					<div className='w-full flex items-center justify-between m-2'>
						<div className='title-lg'>Grupos</div>
						<Button
							variant='contained'
							color='primary'
							onClick={() => setFormVisible(true)}
						>
							Crear nuevo Grupo
						</Button>
					</div>
					<AppTable
						headers={['Nombre del grupo', 'Permisos del Grupo']}
					>
						{groups.map((a) => (
							<TableRow key={a.id}>
								<TableCell>
									<EditableLabel
										id='name'
										value={a.name}
										onSubmit={async (name) => {
											await editGroup(a.id, { name })
											getGroups()
										}}
										label='Nombre'
									></EditableLabel>
								</TableCell>
								<TableCell>
									<DeleteButton
										onClick={async () => {
											withErrors(async () => {
												await deleteGroup(a.id)
												getGroups()
											})
										}}
									></DeleteButton>
								</TableCell>
							</TableRow>
						))}
					</AppTable>
				</div>
			</div>
			<GroupForm
				open={formVisible}
				onClose={() => {
					setFormVisible(false)
					getGroups()
				}}
			></GroupForm>
		</div>
	)
}
export default GroupsComponent
