import {
  Button,
  Card,
  TableCell,
  TableRow,
  Select,
  MenuItem,
} from "@material-ui/core";
import { mdiCheck, mdiCircleHalf, mdiClose } from "@mdi/js";
import { Icon } from "@mdi/react";
import moment from "moment";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../hooks/storeHooks";
import {
  deletePrismPoint,
  fetchAllPrismPoints,
  fetchAllSectionPrismPoints,
  updatePrismPoint,
} from "../../../services/prismPoint";
import { fetchAllSections } from "../../../services/section";
import {
  addSnackbarNotification,
  withError,
} from "../../../services/snackbarNotification";
import DeleteButton from "../../shared/DeleteButton";
import EditableLabel from "../../shared/EditableLabel";
import AppTable from "../../utils/AppTable";
import { fetchAllAxes } from "./../../../services/axis";
import NewAxisModal from "./NewAxisModal";
import NewPrismPointModal from "./NewPrismPointModal";

interface Props {}

const PrismPointsConfig: React.FC<Props> = ({}) => {
  const axes = useAppSelector((s) => s.axisReducer.axes);
  const sections = useAppSelector((s) => s.sectionReducer.sections);
  const prismPoints = useAppSelector((s) => s.prismPointReducer.prismPoints);

  const [selectedAxis, setSelectedAxis] = useState(-1);
  const [selectedSection, setSelectedSection] = useState(-1);

  const isLoading = useAppSelector((s) => s.axisReducer.isLoading);

  const [newPrismPointModalOpen, setNewPrismPointModalOpen] = useState(false);

  useEffect(() => {
    fetchAllAxes();
    fetchAllSections();
  }, []);

  useEffect(() => {
    if (selectedAxis > 0 && selectedSection > 0) {
      fetchAllSectionPrismPoints(
        sections.find((s) => s.id === selectedSection)
      );
    } else {
      fetchAllPrismPoints();
    }
  }, [selectedAxis, selectedSection]);

  return (
    <div className="overflow-auto">
      <div className="title-xl text-center p-2">
        Puntos de Prisma
        <Button
          color="primary"
          variant="contained"
          className="float-right"
          onClick={() => setNewPrismPointModalOpen(true)}
        >
          Nuevo Punto
        </Button>
      </div>
      <div className="m-2">
        <Card>
          <div className="flex p-3">
            <div className="flex space-x-2 items-center flex-1">
              <div className="mr-2">Eje</div>
              <Select
                fullWidth
                label="Eje"
                value={selectedAxis}
                onChange={(e) => setSelectedAxis(e.target.value as number)}
              >
                <MenuItem value={-1}>Todos</MenuItem>
                {axes?.map((axis) => (
                  <MenuItem value={axis.id} key={axis.id}>
                    {axis.code} {axis.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className="flex space-x-2 items-center flex-1">
              <div className="mr-2">Sección</div>
              {selectedAxis > -1 ? (
                <Select
                  onChange={(e) => setSelectedSection(e.target.value as number)}
                  label={"Sección"}
                  fullWidth
                  value={selectedSection}
                >
                  <MenuItem value={-1}>Todas</MenuItem>
                  {sections
                    ?.filter((s) => s.axis === selectedAxis)
                    .map((section) => (
                      <MenuItem value={section.id} key={section.id}>
                        {section.code}
                      </MenuItem>
                    ))}
                </Select>
              ) : (
                <div className="title-lg">Seleccione un eje</div>
              )}
            </div>
          </div>
        </Card>
      </div>

      <div className="overflow-auto">
        <AppTable
          headers={[
            "Número",
            "Eje",
            "Sección",
            "Fijo",
            "Rotación (Centesimal)",
            "Creado",
          ]}
        >
          {prismPoints
            .filter((pp) =>
              selectedSection > 0 ? pp.section === selectedSection : true
            )
            .filter((pp) =>
              selectedAxis > 0 ? pp.axis === selectedAxis : true
            )
            .sort((a, b) => a.code.localeCompare(b.code))
            .map((pp) => (
              <TableRow key={pp.id}>
                <TableCell>{pp.code}</TableCell>
                <TableCell>
                  {axes.find((a) => a.id === pp.axis)?.code +
                    " " +
                    axes.find((a) => a.id === pp.axis)?.name}
                </TableCell>
                <TableCell>
                  {sections.find((s) => s.id === pp.section)?.short_code}
                </TableCell>
                <TableCell>
                  {
                    <Icon
                      path={pp.is_fixed_point ? mdiCheck : mdiClose}
                      className="w-7"
                    >
                      {" "}
                    </Icon>
                  }
                </TableCell>
                <TableCell>
                  <EditableLabel
                    id="azimuth"
                    value={parseFloat(((pp?.azimuth * 400) / 360).toFixed(2))}
                    onSubmit={async (azimuth) => {
                      await updatePrismPoint(pp.id, {
                        azimuth: (azimuth * 360) / 400,
                      });
                      fetchAllSections();
                    }}
                    label="Rotación"
                  ></EditableLabel>
                </TableCell>

                <TableCell>
                  {moment(pp.created).format("DD/MM/YY HH:mm")}
                </TableCell>
                <TableCell>
                  <DeleteButton
                    onClick={withError(async () => {
                      await deletePrismPoint(pp.id);
                      addSnackbarNotification({ message: "Punto eliminado" });
                    })}
                  ></DeleteButton>
                </TableCell>
              </TableRow>
            ))}
        </AppTable>
      </div>
      <div className="h-5"></div>

      {isLoading && (
        <div className="text-center text-gray-600">
          <Icon path={mdiCircleHalf} spin></Icon>
        </div>
      )}
      <NewPrismPointModal
        open={newPrismPointModalOpen}
        onClose={() => {
          setNewPrismPointModalOpen(false);
          selectedSection
            ? fetchAllSectionPrismPoints(
                sections.find((s) => s.id === selectedSection)
              )
            : fetchAllPrismPoints();
        }}
        section={selectedSection}
      ></NewPrismPointModal>
    </div>
  );
};
export default PrismPointsConfig;
