import axios from 'axios'
import { ReportActions } from '../reducers/ReportReducer'
import store from '../store'
import { authHeader } from './auth'
const API_URL = process.env.REACT_APP_API_URL

export const fetchReportTypes = async () => {
	const response = await axios.get(
		`${process.env.REACT_APP_API_URL}pdf/reporttype/`,
		{ headers: authHeader() }
	)
	return response.data
}

export const fetchDataToFileRequest = async (id: number) => {
	const response = await axios.get<DataToFileRequest>(
		API_URL + `data_to_file/request/${id}/`,
		{ headers: authHeader() }
	)
	return response.data
}

export const selectedReport = (url: string) => {
	store.dispatch({ type: ReportActions.SetSelectedReport, payload: url })
}
