module.exports = {
    mainColor: "#242363",
    topBarColor: "#F1F3F4",
    loginBgColor: "#E8E9ED",
    defaultMarkerColor: "#FFFFFF",
    chartBarColor: "#C9EEFF",
    analysisProjectUnitIconColor: "#80D6FF",
    singleChannelChartLineColor: "#80D6FF",
    documentsBlue: "#344785",
    secondaryColor: "#80D6FF",
}
