import axios from 'axios'
import { ProfileActions } from '../reducers/ProfileReducer'
import store from '../store'
import { authHeader } from './auth'
const API_URL = process.env.REACT_APP_API_URL

export const getJobTitles = async () => {
	const response = await axios.get<Paginated<JobTitle>>(
		API_URL + 'accounts/jobtitle/',
		{
			headers: authHeader(),
		}
	)
	store.dispatch({
		type: ProfileActions.SetJobTitles,
		payload: response.data.results,
	})
}
