import { Reducer } from 'react'

interface State {
	selectedReport: string
}

const initialState: State = {
	selectedReport: null,
}

export enum ReportActions {
	SetSelectedReport = 'SET_SELECTED_REPORT',
}

const reducer: Reducer<State, { type: ReportActions; payload: any }> = (
	state: State = initialState,
	{ type, payload }
): State => {
	switch (type) {
		case ReportActions.SetSelectedReport:
			return { ...state, selectedReport: payload }

		default:
			return state
	}
}

export default reducer
