import { Card } from "@material-ui/core"
import { useAppSelector } from "../../../hooks/storeHooks"
import Spinner from "../Spinner"
import AxisExpandable from "./AxisExpandable"

const AxesListCard: React.FC = () => {
    const axes = useAppSelector((s) => s.axisReducer.axes)
    const loading = useAppSelector(s=>s.axisReducer.isLoading)
    return (
        <div className="overflow-y-auto  flex-1">
            <Card>
                {!!axes?.length ? (
                    axes.map((p) => (
                        <AxisExpandable
                            axis={p}
                            key={p.id}
                        ></AxisExpandable>
                    ))
                ) : (
                     <div>{!loading &&"No hay proyectos"}</div>
                )}
                {loading && <Spinner></Spinner>}
            </Card>
        </div>
    )
}
export default AxesListCard
