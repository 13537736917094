import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { createBrowserHistory as createHistory } from 'history'
import { adminReducer } from 'react-admin'

import authReducer from './AuthReducer'
import configReducer from './ConfigReducer'
import topoFileReducer from './TopoFileReducer'
import axisReducer from './AxisReducer'
import sectionReducer from './SectionReducer'
import prismPointReducer from './PrismPointReducer'
import snackbarNotificationReducer from './SnackbarReducer'
import reportReducer from './ReportReducer'
import profileReducer from './ProfileReducer'
import groupReducer from './GroupReducer'
import excavationProgressReducer from './ExcavationProgressReducer'
import fileReducer from './FileReducer'
import wsReducer from './WebSocketReducer'

const history = createHistory()
const historyReducer = connectRouter(history)
const rootReducer = combineReducers({
	authReducer,
	configReducer,
	topoFileReducer,
	router: historyReducer,
	admin: adminReducer,
	axisReducer,
	sectionReducer,
	prismPointReducer,
	snackbarNotificationReducer,
	reportReducer,
	profileReducer,
	groupReducer,
	excavationProgressReducer,
	fileReducer,
	wsReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
