import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@material-ui/core'
import { mdiCheck, mdiPencil, mdiSync } from '@mdi/js'
import Icon from '@mdi/react'
import { PropsWithChildren, ReactNode, useState } from 'react'
import { useAppSelector } from '../../hooks/storeHooks'
import { useForm } from '../../hooks/useForm'
import { addSnackbarNotification } from '../../services/snackbarNotification'
import Spinner from './Spinner'

interface Props<T> {
	onSubmit: (value: T[]) => Promise<void>
	value: T[]
	labelValue?: string
	className?: string
	labelClassName?: string
	id: string
	label?: string
	notificationMessage?: string
	permissions?: Permissions[]
	fullwidth?: boolean
	renderValue?: (value: unknown) => ReactNode
}

const EditableMultiSelectLabel: <T = string>(
	p: PropsWithChildren<Props<T>>
) => React.ReactElement = ({
	onSubmit,
	className,
	labelClassName,
	value,
	label,
	id,
	notificationMessage,
	permissions,
	fullwidth,
	children,
	labelValue,
	renderValue,
}) => {
	const isEditing = true
	const [isOver, setIsOver] = useState(false)
	const [editing, setEditing] = useState(false)
	const [inputValue, setInputValues, handleChange] = useForm({ [id]: value })
	const [loading, setLoading] = useState(false)
	const userPermissions = useAppSelector((s) => s.authReducer.permissions)

	const allowEdit =
		isEditing &&
		(permissions
			? permissions.reduce<boolean>(
					(acc, p) => acc && !!userPermissions.find((up) => up === p),
					true
			  )
			: true)

	return (
		<div
			className={className ?? 'w-full flex items-center'}
			onMouseOver={() => !isOver && setIsOver(true)}
			onMouseLeave={() => setIsOver(false)}
			onKeyDown={(e) => {
				e.key === 'Escape' && setEditing(false)
			}}
		>
			{(!allowEdit || !editing) && (
				<div className={labelClassName ?? 'flex items-center'}>
					{labelValue ? labelValue : value}
				</div>
			)}
			{allowEdit && editing && (
				<form
					onSubmit={async (e) => {
						e.preventDefault()
						setLoading(true)

						await onSubmit(inputValue[id])
							.then(
								() =>
									notificationMessage &&
									addSnackbarNotification({
										message: notificationMessage,
										type: 'success',
									})
							)
							.catch((e) =>
								addSnackbarNotification({
									message: 'Ha ocurrido un error',
									type: 'error',
								})
							)
						setLoading(false)
						setEditing(false)
					}}
				>
					<FormControl className='w-full'>
						{label && (
							<InputLabel id={'aa-label'}>{label}</InputLabel>
						)}
						<Select
							multiple
							labelId={'aa-label'}
							value={inputValue[id]}
							onChange={(
								event: React.ChangeEvent<{ value: unknown }>
							) => {
								const target = event.target as HTMLSelectElement
								console.log(target.value)
								setInputValues({
									...inputValue,
									[id]: target.value as unknown as any[],
								})
							}}
							fullWidth
							label={label}
							renderValue={renderValue}
						>
							{children}
						</Select>
					</FormControl>
				</form>
			)}
			{allowEdit && isOver ? (
				loading ? (
					<div className='w-7 ml-2'>
						<Icon
							className='w-5 text-primary transition-opacity opacity-70 cursor-pointer hover:opacity-100'
							path={mdiSync}
							spin
						></Icon>
					</div>
				) : (
					<div
						className='w-7 ml-2'
						onClick={async () => {
							if (editing) {
								setLoading(true)

								await onSubmit(inputValue[id])
									.then(
										() =>
											notificationMessage &&
											addSnackbarNotification({
												message: notificationMessage,
												type: 'success',
											})
									)
									.catch((e) =>
										addSnackbarNotification({
											message: 'Ha ocurrido un error',
											type: 'error',
										})
									)
								setLoading(false)
								setEditing(false)
							} else {
								setEditing(true)
							}
						}}
					>
						<Icon
							className='w-5 text-primary transition-opacity opacity-70 cursor-pointer hover:opacity-100'
							path={editing ? mdiCheck : mdiPencil}
						></Icon>
					</div>
				)
			) : (
				<div className='w-7 ml-2 h-5'></div>
			)}
		</div>
	)
}
export default EditableMultiSelectLabel
