import { useState } from 'react'
import { useAppSelector } from '../../../hooks/storeHooks'
import useStyles from '../../../styles/appStyles'
import { Document, Page } from 'react-pdf'
import { Button, IconButton } from '@material-ui/core'
import Icon from '@mdi/react'
import { mdiDownload, mdiPdfBox } from '@mdi/js'
import { mainColor } from '../../../styles/styleVars'
import { useSize } from '../../../hooks/useSize'

interface Props {
	report?: string
}

const ReportViewerView: React.FC<Props> = ({ report }) => {
	const classes = useStyles(true)()
	const selectedReport = report
	const [numPages, setNumPages] = useState(null)
	const [pageNumber, setPageNumber] = useState(1)
	const [container, width] = useSize<HTMLDivElement>()

	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages)
	}

	const downloadFile = (url: string) => {
		const link = document.createElement('a')
		link.href = url
		link.setAttribute('download', 'filename.txt')
		link.setAttribute('target', '_blank')
		document.body.appendChild(link)
		link.click()
		link.parentNode.removeChild(link)
	}

	return (
		<div ref={container}>
			<div className='sticky'>
				<div className='sticky animate-pulse'>
					<IconButton
						color='primary'
						onClick={() =>
							selectedReport && downloadFile(selectedReport)
						}
					>
						<Icon
							path={mdiDownload}
							className='w-11 h-11'
							color={mainColor}
						></Icon>
					</IconButton>
				</div>
			</div>
			<div className='text-center flex justify-center  '>
				<Document
					file={selectedReport}
					onLoadSuccess={onDocumentLoadSuccess}
				>
					<Page pageNumber={pageNumber} width={1024} />
				</Document>
			</div>
		</div>
	)
}
export default ReportViewerView
