import { Snackbar } from "@material-ui/core"
import Alert from "@material-ui/lab/Alert"
import { useEffect, useState } from "react"
import { useAppSelector } from "../../hooks/storeHooks"
import { removeSnackbarNotification } from "../../services/snackbarNotification"

const SnackbarNotificationContainer: React.FC = () => {
    const notifications = useAppSelector(
        (s) => s.snackbarNotificationReducer.snackbarNotifications
    )
    const [showed, setShowed] = useState<{ [id: number]: boolean }>({})

    useEffect(() => {
        notifications.forEach((n) => {
            if (!Object.keys(showed).find((id) => +id === n.id)) {
                setShowed((prev) => ({ ...prev, [n.id]: true }))
            }
        })
    }, [notifications, showed])

    return (
        <>
            {!!notifications?.length &&
                notifications.map((n) => (
                    <Snackbar
                        autoHideDuration={4000}
                        open={showed[n.id]}
                        onClose={() => {
                            setShowed((prev) => ({ ...prev, [n.id]: false }))
                        }}
                        onExited={() => removeSnackbarNotification(n)}
                        key={n.id}
                    >
                        <Alert
                            severity={n.type}
                            elevation={6}
                            variant="filled"
                            onClose={() => {
                                setShowed((prev) => ({
                                    ...prev,
                                    [n.id]: false,
                                }))
                            }}
                        >
                            {n.message}
                        </Alert>
                    </Snackbar>
                ))}
        </>
    )
}
export default SnackbarNotificationContainer
