import {
	Button,
	Card,
	IconButton,
	MenuItem,
	Paper,
	Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@material-ui/core'
import { DatePicker, DateTimePicker } from '@material-ui/pickers'
import { mdiArrowLeftThick, mdiArrowRightThick } from '@mdi/js'
import Icon from '@mdi/react'
import axios from 'axios'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import { useAppSelector } from '../../../hooks/storeHooks'
import { authHeader } from '../../../services/auth'
import { fetchAllAxes } from '../../../services/axis'
import {
	fetchMeasurments,
	updateMeasurement,
} from '../../../services/measurements'
import { canEditPrismPoint, closeEdit } from '../../../services/prismPoint'
import { fetchAllSections } from '../../../services/section'
import useStyles from '../../../styles/appStyles'
import { Measurement } from '../../../types/Measurement'
import Checkbox from '../../shared/Checkbox'
import EditableLabel from '../../shared/EditableLabel'
import Spinner from '../../shared/Spinner'

interface Props {
	fixed?: boolean
	authorized: boolean
}

const MeasurementTable: React.FC<Props> = ({ fixed, authorized }) => {
	const [measurements, setMeasurements] = useState<Paginated<Measurement>>()
	const timeZone = useAppSelector((s) => s.configReducer.timeZone)
	const [selectedAxis, setSelectedAxis] = useState(-1)
	const [selectedSection, setSelectedSection] = useState(-1)
	const axes = useAppSelector((s) => s.axisReducer.axes)
	const sections = useAppSelector((s) =>
		!selectedAxis
			? null
			: s.sectionReducer.sections.filter(
				(section) => section.axis === selectedAxis
			)
	)
	const [status, setStatus] = useState('all')
	const [fixedPoints, setFixedPoints] = useState(false)
	const [timeRange, setTimeRange] = useState({
		start: moment()
			.tz(timeZone)
			.subtract(7 * 24 * 3600, 'seconds'),
		end: moment().tz(timeZone),
	})
	const [init, setInit] = useState(true)
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		const update = async () => {
			const filter: any = {}
			!init && (filter.dt_gen__lte = timeRange.end.toISOString())
			!init && (filter.dt_gen__gte = timeRange.start.toISOString())
			filter.is_fixed = fixed ? 'True' : 'False'
			if (selectedAxis !== -1 || status !== 'all' || fixedPoints) {
				selectedAxis !== -1 &&
					(filter.axis = axes.find(
						(a) => a.id === selectedAxis
					)?.code)
				selectedSection !== -1 &&
					(filter.section = sections.find(
						(s) => s.id === selectedSection
					)?.short_code)
				status !== 'all' && (filter.status = status)
				fixedPoints && (filter.is_fixed_point = '0')
			}
			const data = await fetchMeasurments(filter)
			setMeasurements(data)
			if (init) {
				const min = Math.min(
					...data.results.map((m) => moment(m.dt_gen).unix())
				)

				setTimeRange({
					start: moment(min * 1000).tz(timeZone),
					end: timeRange.end,
				})
			}
			init && setInit(false)
		}
		update()
	}, [selectedAxis, selectedSection, status, timeRange, fixedPoints])

	const fetch = async () => {
		const filter: any = {}
		!init && (filter.dt_gen__lte = timeRange.end.toISOString())
		!init && (filter.dt_gen__gte = timeRange.start.toISOString())
		filter.is_fixed = fixed ? 'True' : 'False'
		if (selectedAxis !== -1 || status !== 'all' || fixedPoints) {
			selectedAxis !== -1 &&
				(filter.axis = axes.find(
					(a) => a.id === selectedAxis
				)?.code)
			selectedSection !== -1 &&
				(filter.section = sections.find(
					(s) => s.id === selectedSection
				)?.short_code)
			status !== 'all' && (filter.status = status)
			fixedPoints && (filter.is_fixed_point = '0')
		}
		const data = await fetchMeasurments(filter)
		setMeasurements(data)
	}
	const next = async () => {
		if (measurements?.next) {
			setLoading(true)
			const { data } = await axios.get<Paginated<Measurement>>(
				measurements.next,
				{ headers: authHeader() }
			)
			setLoading(false)

			setMeasurements({
				...data,
				results: [...measurements.results, ...data.results],
			})
		}
	}

	const prev = async () => {
		if (measurements?.previous) {
			const { data } = await axios.get<Paginated<Measurement>>(
				measurements.previous,
				{ headers: authHeader() }
			)
			setMeasurements(data)
		}
	}

	useEffect(() => setSelectedSection(-1), [selectedAxis])

	useEffect(() => {
		fetchAllAxes()
		fetchAllSections()
	}, [])

	const classes = useStyles(true)()
	return (
		<div className={`${classes.bodyContent} h-full flex flex-col`}>
			<div className='p-1 mb-5'>
				<Card>
					<div className='flex space-x-2'>
						<div className='flex space-x-2 items-center flex-1'>
							<div className='mr-2'>Status</div>
							<Select
								fullWidth
								label={'Status'}
								value={status}
								onChange={(e) =>
									setStatus(e.target.value as string)
								}
							>
								<MenuItem value={'all'}>Todos</MenuItem>
								{(fixed ? ['5', '10'] : ['0', '1', '2']).map(v => <MenuItem key={v} value={v}>{v}</MenuItem>)
								}
							</Select>
						</div>
						<div className='flex space-x-2 items-center flex-1'>
							<div className='mr-2'>Eje</div>
							<Select
								fullWidth
								label='Eje'
								value={selectedAxis}
								onChange={(e) =>
									setSelectedAxis(e.target.value as number)
								}
							>
								<MenuItem value={-1}>Todos</MenuItem>
								{axes?.map((axis) => (
									<MenuItem value={axis.id} key={axis.id}>
										{axis.code}
									</MenuItem>
								))}
							</Select>
						</div>
						<div className='flex space-x-2 items-center flex-1'>
							<div className='mr-2'>Sección</div>
							{selectedAxis > -1 && (
								<Select
									onChange={(e) =>
										setSelectedSection(
											e.target.value as number
										)
									}
									label={'Sección'}
									fullWidth
									value={selectedSection}
								>
									<MenuItem value={-1}>Todas</MenuItem>
									{sections?.map((section) => (
										<MenuItem
											value={section.id}
											key={section.id}
										>
											{section.code}
										</MenuItem>
									))}
								</Select>
							)}
						</div>

						<div className='flex space-x-2 items-center flex-1'>
							<DatePicker
								value={timeRange.start.tz(timeZone)}
								onChange={(time) => {
									setTimeRange((s) => ({
										...s,
										start: moment(time),
									}))
									// adjustRange()
								}}
								fullWidth
								label='Inicio'
								InputLabelProps={{
									shrink: true,
								}}
								disableFuture
								format='dd/MMM/yyyy'
							></DatePicker>
						</div>
						<div className='flex space-x-2 flex-1'>
							<DatePicker
								fullWidth
								disableFuture
								value={timeRange.end.tz(timeZone)}
								onChange={(time) => {
									setTimeRange((s) => ({
										...s,
										end: moment(time),
									}))
									// adjustRange()
								}}
								label='Fin'
								format='dd/MMM/yyyy'
								InputLabelProps={{
									shrink: true,
								}}
							></DatePicker>
						</div>
						{/* <IconButton
							onClick={prev}
							disabled={!measurements?.previous}
						>
							<div className='w-7 hover:text-main-1'>
								<Icon path={mdiArrowLeftThick}></Icon>
							</div>
						</IconButton>
						<IconButton
							onClick={next}
							disabled={!measurements?.next}
						>
							<div className='w-7 hover:text-main-1'>
								<Icon path={mdiArrowRightThick}></Icon>
							</div>
						</IconButton> */}
					</div>
				</Card>
			</div>
			{authorized && (
				<div
					className='overflow-auto flex-1 p-1'

				>
					<Paper className="w-full h-full overflow-hidden">
						<TableContainer className="max-h-full" onScroll={(e) => {
							const bottom =
								e.currentTarget.scrollHeight -
								e.currentTarget.scrollTop ===
								e.currentTarget.clientHeight
							if (bottom) {
								next()
							}
						}}>
							<Table stickyHeader size='small' >
								<TableHead>
									<TableRow>
										<TableCell>Status</TableCell>
										<TableCell>Eje</TableCell>
										<TableCell>Sección</TableCell>
										<TableCell>Punto</TableCell>
										<TableCell>Fecha Generación</TableCell>
										<TableCell>Hora Generación</TableCell>
										<TableCell>Norte</TableCell>
										<TableCell>Este</TableCell>
										<TableCell>Elevación</TableCell>
										<TableCell>Delta X [mm]</TableCell>
										<TableCell>Delta Y [mm]</TableCell>
										<TableCell>Delta Z [mm]</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{measurements?.results
										.sort(
											(a, b) =>
												new Date(a.dt_gen).getTime() -
												new Date(b.dt_gen).getTime()
										)
										.sort((a, b) =>
											a.prism_point.code.localeCompare(
												b.prism_point.code
											)
										)
										.map((measurement) => {
											const splittedCode =
												measurement.prism_point.code.split(
													'_'
												)
											return (
												<TableRow
													key={measurement.id}
													className={`${measurement.prism_point
														.is_fixed_point ||
														measurement.status === 0
														? 'bg-blue-300'
														: ''
														}`}
												>
													<TableCell>
														<EditableLabel
															id={`${measurement.id}`}
															label='Status'
															value={
																measurement.status
															}
															onSubmit={async (
																value
															) => {
																await updateMeasurement(
																	measurement.id,
																	{
																		status: value,
																	}
																)
																fetch()
															}}
															canEdit={canEditPrismPoint(measurement.prism_point)}
															onEndEdit={()=>closeEdit(measurement.prism_point)}
														></EditableLabel>
													</TableCell>
													<TableCell>
														{splittedCode[0]}
													</TableCell>
													<TableCell>
														{measurement.status == 5 ? '' : splittedCode[1]}
													</TableCell>
													<TableCell>
														{measurement.status == 5 ? splittedCode[1] : splittedCode[2]}
													</TableCell>
													<TableCell>
														{moment(
															measurement.dt_gen
														).format('DD/MM/YYYY')}
													</TableCell>
													<TableCell>
														{moment(
															measurement.dt_gen
														).format('HH:mm')}
													</TableCell>
													<TableCell>
														<EditableLabel
															id={`${measurement.id}-north`}
															label='Norte'
															value={parseFloat(
																	measurement.north
																).toFixed(4)}
															onSubmit={async (
																value
															) => {
																await updateMeasurement(
																	measurement.id,
																	{
																		north: value.toString(),
																	}
																)
																fetch()
															}}
															canEdit={canEditPrismPoint(measurement.prism_point)}
															onEndEdit={()=>closeEdit(measurement.prism_point)}
														></EditableLabel>
													</TableCell>
													<TableCell>
														<EditableLabel
															id={`${measurement.id}-east`}
															label='Norte'
															value={parseFloat(
																	measurement.east
																).toFixed(4)}
															onSubmit={async (
																value
															) => {
																await updateMeasurement(
																	measurement.id,
																	{
																		east: value.toString(),
																	}
																)
																fetch()
															}}
															canEdit={canEditPrismPoint(measurement.prism_point)}
															onEndEdit={()=>closeEdit(measurement.prism_point)}
														></EditableLabel>
													</TableCell>
													<TableCell>
														<EditableLabel
															id={`${measurement.id}-up`}
															label='Norte'
															value={
																parseFloat(
																	measurement.up
																).toFixed(4)}
															onSubmit={async (
																value
															) => {
																await updateMeasurement(
																	measurement.id,
																	{
																		up: value.toString(),
																	}
																)
																fetch()
															}}
															canEdit={canEditPrismPoint(measurement.prism_point)}
															onEndEdit={()=>closeEdit(measurement.prism_point)}
														></EditableLabel>
													</TableCell>
													<TableCell>{`${parseFloat(
														(
															measurement.rotated_data
																.x * 1000
														).toFixed(3)
													).toFixed(1)}`}</TableCell>
													<TableCell>{`${parseFloat(
														(
															measurement.rotated_data
																.y * 1000
														).toFixed(3)
													).toFixed(1)}`}</TableCell>
													<TableCell>{`${parseFloat(
														(
															measurement.rotated_data
																.z * 1000
														).toFixed(3)
													).toFixed(1)}`}</TableCell>
												</TableRow>
											)
										})}
								</TableBody>
							</Table>
							{!!measurements?.next && !loading && (
								<div className='text-center'>
									<Button onClick={next}>Cargar más datos</Button>
								</div>
							)}
							{loading && (
								<div className='text-center'>
									<Spinner></Spinner>
								</div>
							)}
						</TableContainer>
					</Paper>
				</div>
			)}
			<div>.</div>
		</div>
	)
}
export default MeasurementTable
