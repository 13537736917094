import axios from 'axios'
import { AxisActions } from '../reducers/AxisReducer'
import { SectionActions } from '../reducers/SectionReducer'
import store from '../store'
import { authHeader } from './auth'
const API_URL = process.env.REACT_APP_API_URL

export const fetchAllAxes = async () => {
	store.dispatch({ type: SectionActions.RequestSections, payload: {} })
	const firstResponse = (
		await axios.get(API_URL + `axis/axis/`, { headers: authHeader() })
	).data
	if (firstResponse.count)
		store.dispatch({
			type: AxisActions.AddAxes,
			payload: firstResponse.results,
		})
	var nextUrl = firstResponse.next
	while (nextUrl) {
		const nextResponse = (
			await axios.get(nextUrl, { headers: authHeader() })
		).data
		if (nextResponse.count)
			store.dispatch({
				type: AxisActions.AddAxes,
				payload: firstResponse.results,
			})
		nextUrl = nextResponse.next
	}
	store.dispatch({ type: SectionActions.EndAxisRequest, payload: {} })
}

export const updateAxis = async (id: number, update: Partial<Axis>) => {
	const response = await axios.patch<Axis>(
		API_URL + `axis/axis/${id}/`,
		update,
		{ headers: authHeader() }
	)

	store.dispatch({ type: AxisActions.UpdateAxis, payload: {} })
}

export const createAxis = async (axis: any) => {
	const response = await axios.post<Axis>(API_URL + `axis/axis/`, axis, {
		headers: authHeader(),
	})

	store.dispatch({ type: AxisActions.AddAxes, payload: [response.data] })
}

export const deleteAxis = async (axis: number) => {
	const response = await axios.delete<Axis>(API_URL + `axis/axis/${axis}/`, {
		headers: authHeader(),
	})

	store.dispatch({ type: AxisActions.RemoveAxis, payload: { id: axis } })
}

export const selectAxis = (axis?: Axis) => {
	store.dispatch({ type: AxisActions.SetSelectedAxis, payload: axis })
}
