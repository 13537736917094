import { Image as ImageLayer, Tile as TileLayer } from 'ol/layer'
import { useAppSelector } from '../../../hooks/storeHooks'
import ImageWMS from 'ol/source/ImageWMS'
import OSM from 'ol/source/OSM'
import proj4 from 'proj4'
import { register } from 'ol/proj/proj4'
import { get as getProjection } from 'ol/proj'
import { useEffect, useRef, useState } from 'react'
import * as ol from 'ol'
require('ol/ol.css');

proj4.defs(
    'EPSG:32719',
    '+proj=utm +zone=19 +south +ellps=WGS84 +datum=WGS84 +units=m +no_defs '
)
register(proj4)
const UTM19SProjection = getProjection('EPSG:32719')

const Map: React.FC = () => {
    const center = {lat: +process.env.REACT_APP_DEFAULT_MAP_CENTER_Y, lng: +process.env.REACT_APP_DEFAULT_MAP_CENTER_X}
    const mapRef = useRef<HTMLDivElement>(null)
    const [map, setMap] = useState<ol.Map | null>(null)
    const [overlays, setOverlays] = useState<ol.Overlay[]>([])

    useEffect(() => {
        const lng = +process.env.REACT_APP_DEFAULT_MAP_CENTER_X
        const lat = +process.env.REACT_APP_DEFAULT_MAP_CENTER_Y
        const zoom = 0
        console.log(`Map center: ${lng}, ${lat}`)
        map?.getView().setCenter([lng, lat])
        map?.getView().setZoom(zoom)
        console.log("mapview init 1")
        console.log("center: ", map?.getView().getCenter())
    }, [])

    useEffect(() => {
        map?.getView().setCenter([center.lng, center.lat])
        console.log("mapview init 2")
    }, [center])

    useEffect(() => {
        let options = {
            controls: [],
            layers: [
                new TileLayer({
                    source: new OSM(),
                }),
                // new ImageLayer({
                // 	// extent: [
                // 	// 	410416.4666758208, 7502032.6567014195,
                // 	// 	413474.4666758208, 7505449.6567014195,
                // 	// ],
                // 	source: new ImageWMS({
                // 		url: process.env.REACT_APP_MAP_SERVER_URL,
                // 		params: {
                // 			map: process.env.REACT_APP_MAP_SERVER_MAP,
                // 			layers: process.env.REACT_APP_MAP_SERVER_LAYER,
                // 		},
                // 		ratio: 1,
                // 		serverType: 'mapserver',
                // 	}),
                // }),
            ],
            view: new ol.View({
                center: [
                    +process.env.REACT_APP_DEFAULT_MAP_CENTER_X,
                    +process.env.REACT_APP_DEFAULT_MAP_CENTER_Y,
                ],
                zoom: +process.env.REACT_APP_ZOOM_VALUE_ON_INIT,
                projection: UTM19SProjection,
                minZoom: 8,
                maxZoom: 20,
            }),
            overlays: [],
        }

        const mapObject = new ol.Map(options)
        mapObject.setTarget(mapRef.current!)
        setMap(mapObject)

        console.log("mapview init 3.1")
        // return () => {
        //     mapObject.setTarget(undefined)
        //     setMap(null)
        // }
        console.log("mapview init 3.2")
    }, [])

    const saveCurrentView = () => {
        const center = map.getView().getCenter()
        console.log(center)
        const zoom = map.getView().getZoom()
        console.log(zoom)

    }

    return (
        <div style={{ width: '100%', height: '900px' }} ref={mapRef}>
            <div className='hidden'>
            </div>
        </div>
    )
}
export default Map
