import { List, MenuItem } from '@material-ui/core'
import { useState } from 'react'
import useStyles from '../../../styles/appStyles'
import GroupsComponent from './GroupsComponent'
import ProfilesComponent from './ProfilesComponent'

const Accounts: React.FC = () => {
	const classes = useStyles(true)()
	const [tab, setTab] = useState<'Usuarios' | 'Grupos' | 'Notificaciones'>(
		'Usuarios'
	)
	return (
		<div className={`${classes.bodyContent}`}>
			<div className='flex h-full'>
				<div className='w-1/6'>
					<h1 className='text-center title-lg'>Menu</h1>
					<List>
						<MenuItem
							selected={tab === 'Usuarios'}
							onClick={() => setTab('Usuarios')}
						>
							Usuarios
						</MenuItem>
						<MenuItem
							selected={tab === 'Grupos'}
							onClick={() => setTab('Grupos')}
						>
							Grupos
						</MenuItem>
						{/* <MenuItem
							selected={tab === 'Notificaciones'}
							onClick={() => setTab('Notificaciones')}
						>
							Notificaciones
						</MenuItem> */}
					</List>
				</div>
				<div className='w-5/6'>
					{tab === 'Grupos' && <GroupsComponent></GroupsComponent>}
					{tab === 'Usuarios' && (
						<ProfilesComponent></ProfilesComponent>
					)}
				</div>
			</div>
		</div>
	)
}
export default Accounts
