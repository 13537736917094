import axios from 'axios'
import { PrismPointActions } from '../reducers/PrismPointReducer'
import { SectionActions } from '../reducers/SectionReducer'
import store from '../store'
import { Section } from '../types/Section'
import { authHeader } from './auth'
const API_URL = process.env.REACT_APP_API_URL

export const fetchAllAxisSections = async (axis: Axis) => {
	store.dispatch({ type: SectionActions.RequestSections, payload: axis })
	var data = []
	const firstResponse = (
		await axios.get(API_URL + `section/section/?axis=${axis.id}`, {
			headers: authHeader(),
		})
	).data
	if (firstResponse.count)
		store.dispatch({
			type: SectionActions.AddSections,
			payload: firstResponse.results,
		})
	var nextUrl = firstResponse.next
	while (nextUrl) {
		const nextResponse = (
			await axios.get(nextUrl, { headers: authHeader() })
		).data
		if (nextResponse.count)
			store.dispatch({
				type: SectionActions.AddSections,
				payload: nextResponse.results,
			})
		nextUrl = nextResponse.next
	}
	store.dispatch({ type: SectionActions.EndAxisRequest, payload: axis })
}

export const fetchAllSections = async () => {
	var data = []
	const firstResponse = (
		await axios.get(API_URL + `section/section/`, { headers: authHeader() })
	).data
	if (firstResponse.count)
		store.dispatch({
			type: SectionActions.AddSections,
			payload: firstResponse.results,
		})
	var nextUrl = firstResponse.next
	while (nextUrl) {
		const nextResponse = (
			await axios.get(nextUrl, { headers: authHeader() })
		).data
		if (nextResponse.count)
			store.dispatch({
				type: SectionActions.AddSections,
				payload: nextResponse.results,
			})
		nextUrl = nextResponse.next
	}
}

export const setSelectedSection = (section: Section) => {
	store.dispatch({
		type: SectionActions.SetSelectedSection,
		payload: section,
	})
	store.getState().prismPointReducer.prismPoints.forEach((pp) => {
		if (
			pp.section === section.id &&
			!store.getState().prismPointReducer.prismPointColors[pp.id]
		) {
			store.dispatch({
				type: PrismPointActions.SetPrismPointColor,
				payload: {
					prismPoint: pp,
					color: `rgba(${Math.random() * 255},${
						Math.random() * 255
					},${Math.random() * 255},1)`,
				},
			})
		}
	})
}

export const createSection = async (section: any) => {
	const response = await axios.post<Section>(
		API_URL + `section/section/`,
		{ ...section, monographs: [] },
		{
			headers: authHeader(),
		}
	)

	store.dispatch({
		type: SectionActions.AddSections,
		payload: [response.data],
	})
	return response.data
}

export const updateSection = async (id: number, update: Partial<Section>) => {
	const response = await axios.patch<Section>(
		API_URL + `section/section/${id}/`,
		update,
		{ headers: authHeader() }
	)

	store.dispatch({ type: SectionActions.UpdateSection, payload: {} })
}
export const deleteSection = async (id: number) => {
	const response = await axios.delete<Section>(
		API_URL + `section/section/${id}/`,
		{ headers: authHeader() }
	)

	store.dispatch({ type: SectionActions.RemoveSection, payload: { id } })
}
