import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	TextField,
	Button,
} from '@material-ui/core'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { addSnackbarNotification } from '../../../services/snackbarNotification'

interface Props {
	open: boolean
	onClose?: (event?: {}, reason?: 'backdropClick' | 'escapeKeyDown') => void
	onAuth: () => void
}

const PASSWORD = process.env.REACT_APP_PASSWORD

const TableLogin: React.FC<Props> = ({ open, onClose, onAuth }) => {
	const history = useHistory()

	const [pass, setPass] = useState('')
	return (
		<Dialog fullWidth maxWidth='sm' open={open} onClose={onClose}>
			<DialogTitle>Acceso Restringido</DialogTitle>
			<DialogContent>
				<div>
					<div>
						Ingrese contraseña de administración para ingresar a
						esta sección.
					</div>
					<form
						onSubmit={(e) => {
							e.preventDefault()
							if (pass === PASSWORD) {
								onAuth()
							} else {
								addSnackbarNotification({
									type: 'error',
									message: 'Contraseña incorrecta',
								})
							}
						}}
					>
						<TextField
							value={pass}
							onChange={(e) => setPass(e.target.value)}
							type='password'
							fullWidth
						/>
					</form>
				</div>
			</DialogContent>
			<DialogActions>
				<Button
					color='primary'
					variant='contained'
					onClick={() => {
						if (pass === PASSWORD) {
							onAuth()
							addSnackbarNotification({
								message: 'Ingreso correcto',
							})
						} else {
							addSnackbarNotification({
								type: 'error',
								message: 'Contraseña incorrecta',
							})
						}
					}}
				>
					Ingresar
				</Button>
				<Button onClick={() => history.goBack()}>{'Volver'}</Button>
			</DialogActions>
		</Dialog>
	)
}
export default TableLogin
