import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Button,
	TableCell,
	TableHead,
	TableRow,
	TableBody,
	TableContainer,
	Table,
} from '@material-ui/core'
import { mdiChevronDown } from '@mdi/js'
import Icon from '@mdi/react'
import moment from 'moment-timezone'
import { useEffect, useRef, useState } from 'react'
import { useAppSelector } from '../../../hooks/storeHooks'
import { getUserById } from '../../../services/auth'
import {
	withError,
	withErrors,
} from '../../../services/snackbarNotification'
import { spreadsheetCommitImport } from '../../../services/spreadsheetReview'
import {
	createSpreadsheetReview,
	getSpreadsheetReviewsByTopoFileReview,
	getTopoFile,
	getTopoFileReview,
	getTopoFileReviews,
	selectTopoFileReview,
	sendTopoFileReport,
} from '../../../services/topoFile'
import { SpreadsheetReview } from '../../../types/SpreadsheetReview'
import { TopoFileReview } from '../../../types/TopoFileReview'
import SpreadsheetReviewImportConfirmModal from '../FileReviewView/SpreadsheetReviewImportConfirmModal'

interface Props {
	topoFileReview: TopoFileReview
	showPast: boolean
}

const TopoFileReviewExpandable: React.FC<Props> = ({
	topoFileReview,
	showPast,
}) => {
	const selectedTopoFileReview = useAppSelector(
		(s) => s.topoFileReducer.selectedTopoFileReview
	)
	const inputFile = useRef<HTMLInputElement>(null)
	const reportFile = useRef<HTMLInputElement>(null)
	const [topoFile, setTopoFile] = useState<TopoFile>(null)
	const [spreadsheetReviews, setSpreadsheetReviews] = useState<
		SpreadsheetReview[]
	>([])
	const [open, setOpen] = useState(false)
	const [highlight, setHighlight] = useState(false)

	const [file, setFile] = useState<File>(null)

	const [
		spreadsheetReviewImportConfirmModalOpen,
		setSpreadsheetReviewImportConfirmModalOpen,
	] = useState(false)

	const [targetSpreadSheetReview, setTargetSpreadSheetReview] =
		useState<SpreadsheetReview>(null)
	useEffect(() => {
		fetchTopoFile()
		fetchSpreadsheetReviews()
	}, [])

	useEffect(() => {
		window.focus = () => {
			setHighlight(false)
		}
		return () => {
			window.focus = null
		}
	}, [])

	const fetchTopoFile = async () => {
		setTopoFile(await getTopoFile(topoFileReview.topofile))
	}

	const fetchSpreadsheetReviews = async () => {
		setSpreadsheetReviews(
			await getSpreadsheetReviewsByTopoFileReview(topoFileReview.id)
		)
	}
	const uploadSpreadsheet = (tf: TopoFile) => {
		inputFile.current?.click()
		console.log('uploading spreadsheet')
	}
	const sendSpreadsheet = async (specFile?: File) => {
		withErrors(async () => {
			const spreadsheetReview = await createSpreadsheetReview(
				specFile ?? file,
				topoFileReview,
				topoFile
			)
			if (spreadsheetReview) {
				setTargetSpreadSheetReview(spreadsheetReview)
				setSpreadsheetReviewImportConfirmModalOpen(true)
				fetchSpreadsheetReviews()
			}
		})
	}

	return (
		<div
			className={`flex ${selectedTopoFileReview?.id === topoFileReview.id
				? ' border-secondary-1 border-2'
				: ''
				}`}
			onClick={() => selectTopoFileReview(topoFileReview)}
		>
			{(!topoFileReview.confirmed ||
				moment(topoFileReview.created).isSame(moment(), 'day') ||
				showPast) && (
					<Accordion
						expanded={open}
						onChange={(_e, isExpanded) =>
							setOpen(isExpanded && !(spreadsheetReviews.length < 1))
						}
						className={`w-full`}
					>
						<AccordionSummary
							expandIcon={
								<Icon
									path={mdiChevronDown}
									className='w-6'
									color='#BBB'
								></Icon>
							}
						>
							<div
								className={`flex w-full rounded items-center justify-between space-x-2`}
							>
								<div className='w-1/4 truncate'>
									{topoFile?.datafile.split('/').slice(-1)}
								</div>
								<div className='w-1/4'>
									{moment(topoFileReview.created).format(
										'DD/MM/YYYY HH:mm'
									)}
								</div>
								{/* <div className='w-1/2'>
							Autor:{' '}
							{
								users?.find(
									(u) => u.id === topoFileReview?.creator
								)?.username
							}
						</div> */}
								<div className='w-1/4 flex items-center justify-center text-center'>
									{!topoFileReview.topofile_report ? (
										<>
											<div
												onClick={
													(e) => {
														e.stopPropagation()
														selectTopoFileReview(
															topoFileReview
														)
														reportFile.current?.click()
													}
													// { inputFile.current?.click()}
												}
												className='p-2 rounded-xl text-center mt-1 border h-full font-semibold uppercase text-blue-800 cursor-pointer border-blue-500 border-dashed flex justify-center items-center'
											>
												Subir Reporte Mediciones
												<input
													type='file'
													name='file'
													id='file'
													style={{
														display: 'none',
													}}
													ref={reportFile}
													onChange={withError(
														async (e) => {
															setFile(
																e.target.files[0]
															)
															await sendTopoFileReport(
																e.target.files[0],
																topoFileReview
															)
															getTopoFileReview(
																topoFileReview.id
															)
														}
													)}
													onBlur={() =>
														setHighlight(false)
													}
												// {...getInputProps()}
												/>
											</div>
										</>
									) : (
										<div className='rounded-xl p-2  text-md bg-main-1 text-white m-1'>
											REPORTE SUBIDO
										</div>
									)}
								</div>
								<div className='w-1/4 flex items-center justify-center text-center'>
									{!topoFileReview.confirmed ? (
										<>
											<div
												onClick={
													(e) => {
														e.stopPropagation()
														selectTopoFileReview(
															topoFileReview
														)
														uploadSpreadsheet(topoFile)
													}
													// { inputFile.current?.click()}
												}
												className='p-2 rounded-xl text-center mt-1 border h-full font-semibold uppercase text-blue-800 cursor-pointer border-blue-500 border-dashed flex justify-center items-center'
											>
												Subir análisis{'\n'} a Base de Datos
												<input
													type='file'
													name='file'
													id='file'
													style={{
														display: 'none',
													}}
													ref={inputFile}
													onChange={(e) => {
														console.log('change')
														setFile(e.target.files[0])
														sendSpreadsheet(
															e.target.files[0]
														)
														e.target.value = ''
													}}
												// {...getInputProps()}
												/>
											</div>
										</>
									) : (
										<div className='rounded-xl p-2  text-md bg-main-1 text-white m-1'>
											TAREA COMPLETADA
										</div>
									)}
								</div>
							</div>
						</AccordionSummary>
						<AccordionDetails>
							<div className='p-2 w-full'>
								<div>
									<TableContainer>
										<Table>
											<TableHead>
												<TableRow>
													<TableCell>Nombre</TableCell>
													{/* <TableCell>Autor</TableCell> */}
													<TableCell>
														Datos en B.D.
													</TableCell>
													<TableCell>
														Número de errores
													</TableCell>
													<TableCell>
														Fecha de validación
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{spreadsheetReviews.map((sr) => (
													<TableRow>
														<TableCell>
															{sr.spreadsheet
																.split('/')
																.slice(-1)}
														</TableCell>
														{/* <TableCell>
													{
														getUserById(sr.creator)
															?.username
													}
												</TableCell> */}
														<TableCell>
															{sr.data_on_db}%
														</TableCell>
														<TableCell>
															{sr
																?.validation_report_json
																?.ERRORS?.length ??
																0}
														</TableCell>
														<TableCell>
															{moment(
																sr.validation_time
															).format(
																'DD/MM/YYYY HH:mm'
															)}
														</TableCell>
														{/* {!sr.user_accepted &&
													!sr?.validation_report_json
														?.NUMBER_OF_ERRORS && (
														<TableCell>
															<Button
																variant='outlined'
																color='primary'
															>
																Aceptar
															</Button>
														</TableCell>
													)} */}
													</TableRow>
												))}
											</TableBody>
										</Table>
									</TableContainer>
								</div>
							</div>
						</AccordionDetails>
						{targetSpreadSheetReview && (
							<SpreadsheetReviewImportConfirmModal
								open={spreadsheetReviewImportConfirmModalOpen}
								onClose={() => {
									setSpreadsheetReviewImportConfirmModalOpen(
										false
									)
									fetchSpreadsheetReviews()
								}}
								spreadsheetReview={targetSpreadSheetReview}
								topoFile={topoFile}
								onAccept={withError(async () => {
									await spreadsheetCommitImport(
										targetSpreadSheetReview?.id
									)
									getTopoFileReview(topoFileReview?.id)
									getTopoFileReviews()
								})}
							></SpreadsheetReviewImportConfirmModal>
						)}
					</Accordion>
				)}
		</div>
	)
}

export default TopoFileReviewExpandable
