import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	TextField,
	Select,
	MenuItem,
	Input,
	FormControl,
	InputLabel,
} from '@material-ui/core'
import { useState } from 'react'
import { useAppSelector } from '../../../hooks/storeHooks'
import { useForm } from '../../../hooks/useForm'
import { createMonograph } from '../../../services/monograph'
import { createSection } from '../../../services/section'
import {
	addSnackbarNotification,
	withErrors,
} from '../../../services/snackbarNotification'
interface Props {
	open: boolean
	onClose?: (event?: {}, reason?: 'backdropClick' | 'escapeKeyDown') => void
}

const NewSectionModal: React.FC<Props> = ({ open, onClose }) => {
	const [values, setValues, handleChange] = useForm<any>({
		short_code: '0',
		axis: 0,
		azimuth: '0',
		excavation_distance: '0',
		project_kilometer: '0',
	})
	const [monography, setMonography] = useState<File>(null)

	const axes = useAppSelector((s) => s.axisReducer.axes)

	return (
		<Dialog fullWidth maxWidth='md' open={open} onClose={onClose}>
			<DialogTitle>Nueva sección</DialogTitle>
			<DialogContent>
				<TextField
					value={values.short_code}
					onChange={handleChange}
					label='Número de sección'
					fullWidth
					id='short_code'
					margin='normal'
					helperText='Ejemplo: 01'
				></TextField>
				<FormControl className='w-full'>
					<InputLabel id={'axis-label'}>Eje</InputLabel>
					<Select
						value={values.axis}
						onChange={(e) =>
							setValues({ ...values, axis: e.target.value })
						}
						fullWidth
						labelId='axis-label'
					>
						<MenuItem value={0}>Seleccione un Eje</MenuItem>
						{axes.map((a) => (
							<MenuItem value={a.id} key={a.id}>
								{a.code} {a.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<TextField
					value={values.azimuth}
					onChange={handleChange}
					label='Azimuth (° Centesimales)'
					fullWidth
					type='number'
					id='azimuth'
					margin='normal'
					helperText='Ejemplo: 01'
				></TextField>
				<TextField
					value={values.excavation_distance}
					onChange={handleChange}
					fullWidth
					label='Distancia Métrica'
					type='number'
					id='excavation_distance'
					margin='normal'
				></TextField>
				<TextField
					value={values.project_kilometer}
					onChange={handleChange}
					label='Kilómetro del Proyecto'
					fullWidth
					id='project_kilometer'
					margin='normal'
				></TextField>
				<div className='flex items-start'>
					<Button variant='contained' component='label'>
						Seleccione monografía
						<input
							type='file'
							hidden
							onChange={(e) => setMonography(e.target.files[0])}
						/>
					</Button>
					<div className='flex-1'>
						{monography && (
							<div className='w-96 h-96'>
								<img
									className='h-full'
									src={URL.createObjectURL(monography)}
								/>
							</div>
						)}
					</div>
				</div>
			</DialogContent>
			<DialogActions>
				<Button
					variant='contained'
					color='primary'
					onClick={async () => {
						withErrors(async () => {
							const aux = values
							delete aux.thresholds
							aux.code = aux.short_code
							aux.azimuth = (aux.azimuth * 36) / 40
							const section = await createSection(aux)
							monography &&
								(await createMonograph(
									{ section: section.id },
									monography
								))

							addSnackbarNotification({
								message: 'Sección creada',
							})
							onClose()
						})
					}}
				>
					Guardar
				</Button>
				<Button onClick={() => onClose()}>Cancelar</Button>
			</DialogActions>
		</Dialog>
	)
}
export default NewSectionModal
