export enum Routes {
	FileUpload = '/upload',
	FileReview = '/review',
	Admin = '/administration',
	Login = '/login',
	Reports = '/reports',
	DataExport = '/data-export',
	PlotCreation = '/plot-creation',
	// Admin = "Admin"
	MeasurementTable = '/measurements',
	FileExplorer = '/explorer',
	ExcavationProgress = '/excavation-progress',
	ReportViewer = '/report-viewer',
	Accounts = '/accounts',
	Files = '/files',
	Map = '/map',
}
