import { Reducer } from 'redux'

interface State {
	axes: Axis[]
	isLoading: boolean
	selectedAxis: Axis
}

const initialState: State = {
	axes: [],
	isLoading: false,
	selectedAxis: null,
}

export enum AxisActions {
	RequestAxes = 'REQUEST_AXES',
	AddAxes = 'ADD_AXES',
	EndAxesRequest = 'SET_AXES',
	UpdateAxis = 'UPDATE_AXIS',
	RemoveAxis = 'REMOVE_AXIS',
	SetSelectedAxis = 'SET_SELECTED_AXIS',
	// SetTopoFileStatus = 'SET_TOPO_FILE_STATUS',
}

const reducer: Reducer<State, { type: AxisActions; payload: any }> = (
	state: State = initialState,
	{ type, payload }
): State => {
	switch (type) {
		case AxisActions.RequestAxes:
			return { ...state, isLoading: true }
		case AxisActions.AddAxes:
			return {
				...state,
				axes: [
					...state.axes.filter(
						(a) => !payload.find((na) => na.id === a.id)
					),
					...payload,
				],
			}
		case AxisActions.RemoveAxis:
			return {
				...state,
				axes: [...state.axes.filter((a) => a.id !== payload.id)],
			}
		case AxisActions.EndAxesRequest:
			return { ...state, isLoading: false }
		case AxisActions.SetSelectedAxis:
			return { ...state, selectedAxis: payload as Axis }
		default:
			return state
	}
}

export default reducer
