import { Reducer } from 'redux'

interface State {
	prismPoints: PrismPoint[]
	isLoading: Map<number, boolean>
	prismPointColors: Map<number, string>
}

const initialState = {
	prismPoints: [],
	isLoading: {} as Map<number, boolean>,
	prismPointColors: {} as Map<number, string>,
}

export enum PrismPointActions {
	RequestPrismPoints = 'REQUEST_PRISM_POINTS',
	AddPrismPoints = 'ADD_PRISM_POINTS',
	EndSectionRequest = 'END_SECTION_REQUEST',
	SetPrismPointColor = 'SET_PRISM_POINT_COLOR',
	RemovePrismPoint = 'REMOVE_PRISM_POINT',
	UpdatePrismPoint = 'UPDATE_PRISM_POINT',
	// SetTopoFileStatus = 'SET_TOPO_FILE_STATUS',
}

const reducer: Reducer<State, { type: PrismPointActions; payload: any }> = (
	state: State = initialState,
	{ type, payload }
): State => {
	switch (type) {
		case PrismPointActions.RequestPrismPoints:
			return {
				...state,
				isLoading: { ...state.isLoading, [payload.id]: true },
			}
		case PrismPointActions.AddPrismPoints:
			return {
				...state,
				prismPoints: [
					...state.prismPoints.filter(
						(p) => !payload.find((np) => np.id === p.id)
					),
					...payload,
				],
			}
		case PrismPointActions.EndSectionRequest:
			return {
				...state,
				isLoading: { ...state.isLoading, [payload.id]: false },
			}
		case PrismPointActions.SetPrismPointColor:
			return {
				...state,
				prismPointColors: {
					...state.prismPointColors,
					[payload.prismPoint.id]: payload.color,
				},
			}
		case PrismPointActions.RemovePrismPoint:
			return {
				...state,
				prismPoints: state.prismPoints.filter((p) => p.id !== payload),
			}
		case PrismPointActions.UpdatePrismPoint:
			return {
				...state,
				// prismPoints: state.prismPoints.filter((p) => p.id !== payload),
			}
		// case TopoFileActions.SetTopoFileStatus:
		//     return { ...state, isLoading: false, topoFiles: payload }
		default:
			return state
	}
}

export default reducer
