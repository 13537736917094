import {
	Button,
	IconButton,
	MenuItem,
	Select,
	TableCell,
	TableRow,
} from '@material-ui/core'
import { mdiCheck, mdiCircleHalf, mdiPencil } from '@mdi/js'
import { Icon } from '@mdi/react'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useAppSelector } from '../../../hooks/storeHooks'
import { addSnackbarNotification } from '../../../services/snackbarNotification'
import DeleteButton from '../../shared/DeleteButton'
import EditableLabel from '../../shared/EditableLabel'
import AppTable from '../../utils/AppTable'
import { deleteAxis, fetchAllAxes, updateAxis } from './../../../services/axis'
import AxisThresholdEditModal from './AxisThresholdEditModal'
import NewAxisModal from './NewAxisModal'

interface Props { }

const AxesConfig: React.FC<Props> = ({ }) => {
	const axes = useAppSelector((s) => s.axisReducer.axes)
	const isLoading = useAppSelector((s) => s.axisReducer.isLoading)

	const [newAxisModalOpen, setNewAxisModalOpen] = useState(false)
	const [thresholdsEditModal, setThresholdsEditModal] = useState(false)
	const [selectedAxis, setSelectedAxis] = useState(null)
	const [isEditingMethod, setIsEditingMethod] = useState(false)
	const [isOver, setIsOver] = useState(false)

	useEffect(() => {
		fetchAllAxes()
	}, [])

	return (
		<div className=''>
			<div className='title-xl text-center p-2'>
				Ejes
				<Button
					color='primary'
					variant='contained'
					className='float-right'
					onClick={() => setNewAxisModalOpen(true)}
				>
					Nuevo Eje
				</Button>
			</div>
			<AppTable
				headers={[
					'Número de Eje',
					'Nombre',
					'Método de Excavación',
					'Creado',
				]}
			>
				{axes.map((a) => (
					<TableRow key={a.id}>
						<TableCell>{a.code}</TableCell>
						<TableCell>
							<EditableLabel
								id='name'
								value={a.name}
								onSubmit={async (name) => {
									await updateAxis(a.id, { name })
									fetchAllAxes()
								}}
								label='Nombre'
							></EditableLabel>
							{/* {a.name} */}
						</TableCell>
						<TableCell
							onMouseOver={() => !isOver && setIsOver(true)}
							onMouseLeave={() => setIsOver(false)}
						>
							{/* {a.excavation_method} */}
							<Select
								disabled={!isEditingMethod}
								value={a.excavation_method}
								onChange={async (e) => {
									// setValues({
									// 	...values,
									// 	excavation_method: e.target.value,
									// })
									setIsEditingMethod(false)
									await updateAxis(a.id, {
										excavation_method: e.target
											.value as string,
									})
									fetchAllAxes()
								}}
								label='Método de excavación'
							// fullWidth
							>
								<MenuItem value='NATM'>
									NATM (new Austrian tunneling method)
								</MenuItem>
								<MenuItem value='TBM'>
									TBM (tunnel boring machine)
								</MenuItem>
								<MenuItem value='0'>Sin excavación</MenuItem>
							</Select>
							{isOver && (
								<div onClick={() => setIsEditingMethod(true)}>
									<Icon
										className='w-5 text-primary transition-opacity opacity-70 cursor-pointer hover:opacity-100 inline-flex'
										path={
											isEditingMethod
												? mdiCheck
												: mdiPencil
										}
									></Icon>
								</div>
							)}
						</TableCell>
						<TableCell>
							{moment(a.created).format('DD/MM/YY HH:mm')}
						</TableCell>
						<TableCell>
							<Button
								variant='outlined'
								onClick={() => {
									setSelectedAxis(a)
									setThresholdsEditModal(true)
								}}
							>
								Editar umbrales
							</Button>
						</TableCell>
						<TableCell>
							<DeleteButton
								onClick={async () => {
									await deleteAxis(a.id)
									addSnackbarNotification({
										message: 'Eje eliminado',
									})
								}}
							></DeleteButton>
						</TableCell>
					</TableRow>
				))}
			</AppTable>
			{isLoading && (
				<div className='text-center text-gray-600'>
					<Icon path={mdiCircleHalf} spin></Icon>
				</div>
			)}
			<NewAxisModal
				open={newAxisModalOpen}
				onClose={() => setNewAxisModalOpen(false)}
			></NewAxisModal>
			{selectedAxis && (
				<AxisThresholdEditModal
					open={thresholdsEditModal}
					onClose={() => setThresholdsEditModal(false)}
					axis={selectedAxis}
				></AxisThresholdEditModal>
			)}
		</div>
	)
}
export default AxesConfig
