import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import { topBarColor } from './styleVars.js'

const drawerWidth = 60
const toolBarHeight = 60


const useStyles = (isAuthenticated: boolean) => makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
        },
        appBar: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
            backgroundColor: topBarColor,
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
        },
        toolbar: isAuthenticated ? theme.mixins.toolbar : {},
        content: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.default,
        },
        toolbarContent: {
            height: `${toolBarHeight}px`
        },
        bodyContent: { height: `calc(100vh - ${toolBarHeight - 5}px)` }
    }

    )

)

export default useStyles