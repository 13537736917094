import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TableCell,
	TableRow,
} from '@material-ui/core'
import { useEffect, useState } from 'react'
import {
	createMonograph,
	deleteMonograph,
	getSectionMonographs,
} from '../../../services/monograph'
import { deleteNote, editNote, getSectionNotes } from '../../../services/note'
import {
	addSnackbarNotification,
	withError,
	withErrors,
} from '../../../services/snackbarNotification'
import { Section } from '../../../types/Section'
import DeleteButton from '../../shared/DeleteButton'
import EditableLabel from '../../shared/EditableLabel'
import AppTable from '../../utils/AppTable'

interface Props {
	open: boolean
	onClose?: (event?: {}, reason?: 'backdropClick' | 'escapeKeyDown') => void
	section: Section
}

const MonographViewer: React.FC<Props> = ({ open, onClose, section }) => {
	const [monographs, setMonographs] = useState<Monograph[]>([])
	const [monography, setMonography] = useState<File>(null)

	useEffect(() => {
		open &&
			getSectionMonographs(section.id).then((res) =>
				setMonographs(res.results)
			)
	}, [open])

	return (
		<Dialog fullWidth maxWidth='md' open={open} onClose={onClose}>
			<DialogTitle>Monografías</DialogTitle>
			<DialogContent>
				<div className='flex-wrap w-full flex gap-2 p-3 justify-around items-center'>
					{monographs?.map((n) => (
						<div className='w-96 h-96 relative'>
							<div className='absolute top-0 right-0'>
								<DeleteButton
									onClick={async () => {
										withErrors(async () => {
											await deleteMonograph(n.id)
											addSnackbarNotification({
												message: 'Monografía eliminada',
											})
											getSectionMonographs(
												section.id
											).then((res) =>
												setMonographs(res.results)
											)
										})
									}}
								></DeleteButton>
							</div>
							<img src={n.figure} alt='' className='h-full' />
						</div>
					))}
				</div>
				<div className='flex items-start'>
					<Button variant='contained' component='label'>
						Seleccione monografía
						<input
							type='file'
							hidden
							onChange={withError(
								async (e: { target: { files: File[] } }) => {
									await createMonograph(
										{ section: section.id },
										e.target.files[0]
									)
									getSectionMonographs(section.id).then(
										(res) => setMonographs(res.results)
									)
								}
							)}
						/>
					</Button>
				</div>
			</DialogContent>
			<DialogActions>
				<Button onClick={async () => onClose()}>Volver</Button>
			</DialogActions>
		</Dialog>
	)
}

export default MonographViewer
