import { TextField } from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import { ParsableDate } from '@material-ui/pickers/constants/prop-types'
import { mdiCheck, mdiPencil, mdiSync } from '@mdi/js'
import Icon from '@mdi/react'
import moment from 'moment'
import { PropsWithChildren, useState } from 'react'
import Moment from 'react-moment'
import { useAppSelector } from '../../hooks/storeHooks'
import { useForm } from '../../hooks/useForm'
import { addSnackbarNotification } from '../../services/snackbarNotification'
import Spinner from './Spinner'

interface Props<T> {
	onSubmit: (value) => Promise<void>
	value: moment.Moment
	className?: string
	labelClassName?: string
	id: string
	label: string
	notificationMessage?: string
	permissions?: Permissions[]
	fullwidth?: boolean
}

const EditableDateLabel: <T = string>(
	p: PropsWithChildren<Props<T>>
) => React.ReactElement = ({
	onSubmit,
	className,
	labelClassName,
	value,
	label,
	id,
	notificationMessage,
	permissions,
	fullwidth,
}) => {
	const isEditing = true
	const [isOver, setIsOver] = useState(false)
	const [editing, setEditing] = useState(false)
	const [inputValue, setValues, handleChange] = useForm({ [id]: value })
	const [loading, setLoading] = useState(false)
	const userPermissions = useAppSelector((s) => s.authReducer.permissions)

	const allowEdit =
		isEditing &&
		(permissions
			? permissions.reduce<boolean>(
					(acc, p) => acc && !!userPermissions.find((up) => up === p),
					true
			  )
			: true)

	return (
		<div
			className={className ?? 'w-full flex items-center'}
			onMouseOver={() => !isOver && setIsOver(true)}
			onMouseLeave={() => setIsOver(false)}
			onKeyDown={(e) => {
				e.key === 'Escape' && setEditing(false)
			}}
		>
			{(!allowEdit || !editing) && (
				<div className={labelClassName ?? 'flex items-center'}>
					{label}
				</div>
			)}
			{allowEdit && editing && (
				<form
					onSubmit={async (e) => {
						e.preventDefault()
						setLoading(true)
						await onSubmit(inputValue[id])
							.then(
								() =>
									notificationMessage &&
									addSnackbarNotification({
										message: notificationMessage,
										type: 'success',
									})
							)
							.catch((e) =>
								addSnackbarNotification({
									message: 'Ha ocurrido un error',
									type: 'error',
								})
							)
						setLoading(false)
						setEditing(false)
					}}
				>
					<DatePicker
						value={inputValue[id] as ParsableDate}
						onChange={(time) => setValues({ [id]: moment(time) })}
						InputLabelProps={{
							shrink: true,
						}}
						format={'dd/MMM/yyyy'}
					></DatePicker>
				</form>
			)}
			{allowEdit && isOver ? (
				loading ? (
					<div className='w-7 ml-2'>
						<Icon
							className='w-5 text-primary transition-opacity opacity-70 cursor-pointer hover:opacity-100'
							path={mdiSync}
							spin
						></Icon>
					</div>
				) : (
					<div
						className='w-7 ml-2'
						onClick={async () => {
							if (editing) {
								setLoading(true)
								await onSubmit(inputValue[id])
									.then(
										() =>
											notificationMessage &&
											addSnackbarNotification({
												message: notificationMessage,
												type: 'success',
											})
									)
									.catch((e) =>
										addSnackbarNotification({
											message: 'Ha ocurrido un error',
											type: 'error',
										})
									)

								setLoading(false)
								setEditing(false)
							} else {
								setEditing(true)
							}
						}}
					>
						<Icon
							className='w-5 text-primary transition-opacity opacity-70 cursor-pointer hover:opacity-100'
							path={editing ? mdiCheck : mdiPencil}
						></Icon>
					</div>
				)
			) : (
				<div className='w-7 ml-2 h-5'></div>
			)}
		</div>
	)
}
export default EditableDateLabel
