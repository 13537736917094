import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import { TreeItem, TreeView } from '@material-ui/lab'
import { mdiChevronDown, mdiChevronRight, mdiFile, mdiFolder, mdiTrashCanOutline } from '@mdi/js'
import { Icon } from '@mdi/react'
import { useEffect, useRef, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useHistory } from 'react-router'
import { Routes } from '../../../config/routes'
import { selectedReport } from '../../../services/report'
import { deleteDocument, deleteNode, getFirstScheme } from '../../../services/scheme'
import { addSnackbarNotification } from '../../../services/snackbarNotification'
import useStyles from '../../../styles/appStyles'
import { SchemeModel, SchemeNode } from '../../../types/Scheme'
import ReportViewerView from '../ReportViewerView/ReportViewerView'
import DeleteConfirmationModal from './DeleteConfirmationModal'
import UploadFileModal from './UploadFileModal'

const FileExplorerView: React.FC = () => {
	const classes = useStyles(true)()
	const history = useHistory()
	const [selectedNode, setSelectedNode] = useState<SchemeNode>(null)
	const [init, setInit] = useState(true)
	const [expandedNodes, setExpandedNodes] = useState<string[]>([])

	const inputFile = useRef<HTMLInputElement>(null)
	const [file, setFile] = useState<File>(null)
	const { getRootProps, getInputProps } = useDropzone({
		onDrop: (acceptedFiles) => {
			setFile(acceptedFiles[0])
		},
		noClick: true,
	})
	const [viewType, setViewType] = useState<'list' | 'icons'>('icons')
	const [scheme, setScheme] = useState<SchemeModel>(null)
	const [icons, setIcons] = useState<SchemeIcon[]>([])
	const [showDeleteModal, setShowDeleteModal] = useState(false)
	const [nodeToDelete, setNodeToDelete] = useState<SchemeNode>(null)

	const renderTree = (node: SchemeNode) => (
		<TreeItem
			key={node.id}
			nodeId={node.id}
			id={node.id}
			label={node.name}
			onLabelClick={() => {
				setSelectedNode(node)
				if (expandedNodes.find((id) => id === node.id)) {
					setExpandedNodes(
						expandedNodes.filter((id) => id !== node.id)
					)
				} else {
					setExpandedNodes(expandedNodes.concat(node.id))
				}
			}}
			icon={
				node.options?.icon ? (
					<div
						className='w-6 h-6'
						dangerouslySetInnerHTML={{
							__html: icons
								.find((i) => i.id === node.options.icon)
								?.icon?.svg.replace('viewBox="0 0 24 24"', '')
								.replace('width="249mm"', '')
								.replace(
									'height="250mm"',
									'class="w-6 h-6" preserveAspectRatio="none"'
								),
						}}
					></div>
				) : (
					<Icon
						path={node.type === 'directory' ? mdiFolder : mdiFile}
						className='w-6'
						color='#BBB'
					/>
				)
			}
		>
			{Array.isArray(node.children)
				? node.children.map((node) => renderTree(node))
				: null}
		</TreeItem>
	)

	useEffect(() => {
		const initialState = async () => {
			const fetchedScheme = await getFirstScheme()
			setScheme(fetchedScheme)
			setIcons(fetchedScheme.icons)
		}
		initialState()
	}, [])

	useEffect(() => {
		if (!!scheme && init) {
			setSelectedNode(scheme.directory)
			setExpandedNodes([scheme.directory.id])

			setInit(false)
		}
	}, [init, scheme])

	const downloadFile = (url: string) => {
		console.log(url)
		const link = document.createElement('a')
		link.href = url
		link.setAttribute('download', 'filename.txt')
		link.setAttribute('target', '_blank')
		document.body.appendChild(link)
		link.click()
		link.parentNode.removeChild(link)
	}

	const handleViewType = (e) => {
		setViewType(e.target.value)
	}

	return (
		<div className={classes.bodyContent}>
			<div className='flex h-full'>
				<div className='w-1/12 hover:w-5/6 md:hover:w-1/4 md:w-1/4 mt-2 overflow-auto h-full transition-all pl-2'>
					<TreeView
						defaultCollapseIcon={
							<Icon
								path={mdiChevronDown}
								className='w-6'
								color='#BBB'
							></Icon>
						}
						expanded={expandedNodes}
						defaultExpandIcon={
							<Icon
								path={mdiChevronRight}
								className='w-6'
								color='#BBB'
							></Icon>
						}
						selected={selectedNode?.id}
					>
						{scheme && renderTree(scheme.directory)}
					</TreeView>
				</div>
				<div className='w-3/4 overflow-auto h-full pl-2 border-l-2 border-gray-300 relative'>
					{!!selectedNode && selectedNode.type === 'directory' ? (
						<>
							<div className="flex items-end justify-between">
								<div
									className='w-50 h-10 text-bg'
									style={{ float: 'inline-start' }}
								>
									{' ' + selectedNode.name + ' >'}
								</div>
								<div className=''>
									<RadioGroup
										aria-label='gender'
										name='gender1'
										value={viewType}
										onChange={handleViewType}
										row
										style={{ flexDirection: 'row-reverse' }}
									>
										<FormControlLabel
											value='list'
											control={<Radio color='primary' />}
											label='Lista'
											color='primary'
										/>
										<FormControlLabel
											value='icons'
											control={<Radio color='primary' />}
											label='Iconos'
											color='primary'
										/>
									</RadioGroup>
								</div>

							</div><div
								className={
									viewType === 'icons'
										? 'flex flex-wrap items-start space-x-3  space-y-3'
										: ' h-full'
								}
								{...getRootProps()}
							>
								<input
									type='file'
									name='file'
									id='file'
									onClick={(e) => e.stopPropagation()}
									style={{ display: 'none' }}
									ref={inputFile}
									onChange={(e) => setFile(e.target.files[0])}
									{...getInputProps()}
								/>

								{selectedNode.children.length ? (
									selectedNode.children
										.sort((a, b) =>
											a.name.localeCompare(b.name)
										)
										.map((node) => (
											<div
												onClick={() => {
													switch (node.type) {
														case 'directory':
															setSelectedNode(
																node
															)
															break
														case 'file':
															const url: string =
																node.options.url
															if (
																url.includes(
																	'.pdf'
																)
															) {
																setSelectedNode(
																	node
																)
															} else {
																downloadFile(
																	node.options
																		.url
																)
															}
															break
													}
												}}
												title={node.name}
												key={node.id}
												className={
													viewType === 'icons'
														? 'first:mt-3 rounded-lg w-44 max-h-44 h-44  max-w cursor-pointer flex flex-col justify-around items-center p-4 bg-secondary-1 bg-opacity-80 hover:bg-opacity-100 transition-opacity'
														: 'w-full'
												}
											>
												{viewType === 'icons' ? (
													node.options?.icon ? (
														<>
															<div
																className='w-20 h-20'
																dangerouslySetInnerHTML={{
																	__html: icons
																		.find(
																			(
																				i
																			) =>
																				i.id ===
																				node
																					.options
																					.icon
																		)
																		?.icon?.svg.replace(
																			'viewBox="0 0 80 80"',
																			''
																		)
																		.replace(
																			'width="249mm"',
																			''
																		)
																		.replace(
																			'height="250mm"',
																			'class="w-20 h-20" preserveAspectRatio="none"'
																		),
																}}
															></div>
															<div
																className='font-medium text-lg text-white truncate'
																style={{
																	maxWidth:
																		'10rem',
																}}
															>
																{node.name}
															</div>
														</>
													) : (
														<>
															<div className='w-20 h-20'>
																<Icon
																	path={
																		node.type ===
																			'directory'
																			? mdiFolder
																			: mdiFile
																	}
																	className='w-20 h-20 text-white'
																></Icon>
															</div>
															<div
																className='font-medium text-lg text-white truncate'
																style={{
																	maxWidth:
																		'10rem',
																}}
															>
																{node.name}
															</div>
														</>
													)
												) : (
													<>
														<div className='w-full text-gray-600 cursor-pointer hover:text-gray-500 transition-all flex'>
															{node.options?.icon ? (
																<div
																	className='w-6 h-6'
																	dangerouslySetInnerHTML={{
																		__html: icons
																			.find(
																				(
																					i
																				) =>
																					i.id ===
																					node
																						.options
																						.icon
																			)
																			?.icon?.svg.replace(
																				'viewBox="0 0 24 24"',
																				''
																			)
																			.replace(
																				'width="249mm"',
																				''
																			)
																			.replace(
																				'height="250mm"',
																				'class="w-6 h-6" preserveAspectRatio="none"'
																			),
																	}}
																></div>
															) : (
																<Icon
																	path={
																		node.type ===
																			'directory'
																			? mdiFolder
																			: mdiFile
																	}
																	size={1}
																></Icon>
															)}
															<div className='text-gray-600'>
																{node.name}
															</div>
															{node.options?.allow_delete && (
																<div className='w-5 h-5' onClick={(e) => {
																	e.stopPropagation()
																	setNodeToDelete(node)
																	setShowDeleteModal(true)
																}}>
																	{node.type !== 'directory' && (<Icon
																		path={mdiTrashCanOutline}
																	></Icon>)}
																</div>
															)}
														</div>
													</>
												)}
											</div>
										))
								) : (
									<div className='text-center w-full text-gray-600 text-lg font-medium'>
										Este directorio está vacío
									</div>
								)}
							</div>
						</>
					) : selectedNode?.options?.url?.includes('.pdf') ? (
						<ReportViewerView report={selectedNode.options.url} />
					) : (
						<div className='flex flex-wrap space-x-3 space-y-3'>
							<div
								onClick={() => {
									const url: string = selectedNode.options.url
									if (url.includes('.pdf')) {
										selectedReport(url)
										history.push(Routes.ReportViewer)
									} else {
										console.log(selectedNode)
										downloadFile(selectedNode.options.url)
									}
								}}
								title={selectedNode?.name}
								className='first:mt-3 rounded-lg w-44 max-h-44 h-44  max-w cursor-pointer flex flex-col justify-around items-center p-4 bg-secondary-1 bg-opacity-80 hover:bg-opacity-100 transition-opacity'
							>
								<div className='w-20 h-20'>
									<Icon
										path={mdiFile}
										className='w-20 h-20 text-white'
									></Icon>
								</div>
								<div
									className='font-medium text-lg text-white truncate'
									style={{ maxWidth: '10rem' }}
								>
									{selectedNode?.name}
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
			<UploadFileModal
				open={!!file}
				onClose={async () => {
					setFile(null)
					const fetchedScheme = await getFirstScheme()
					setInit(false)
					setScheme(fetchedScheme)
					setIcons(fetchedScheme.icons)
					setSelectedNode(fetchedScheme.directory)
					setExpandedNodes([fetchedScheme.directory.id].concat(expandedNodes))
				}}
				file={file}
				directory={selectedNode?.name}
			></UploadFileModal>
			<DeleteConfirmationModal
				open={showDeleteModal}
				onClose={async () => {
					setShowDeleteModal(false)
				}}
				onAccept={async (node: SchemeNode) => {
					var tmpScheme = scheme
					tmpScheme.directory = deleteNode(node, scheme)
					const newScheme = tmpScheme
					deleteDocument(node).then(
						() => {
							addSnackbarNotification({
								message: 'Documento eliminado',
							})
						}
					)
					setInit(false)
					setScheme(newScheme)
					setIcons(newScheme.icons)
					setShowDeleteModal(false)
				}}
				node={nodeToDelete}
			>
			</DeleteConfirmationModal>
		</div>
	)
}
export default FileExplorerView
