import { useEffect, useState } from 'react'
import { useAppSelector } from '../../../hooks/storeHooks'
import { useSize } from '../../../hooks/useSize'
import {
	fetchMeasurments,
	getPrismPointColor,
} from '../../../services/prismPoint'
import { singleChannelChartLineColor } from '../../../styles/styleVars'
import { PrismPointMeasurment } from '../../../types/PrismPointMeasurment'
import * as d3 from 'd3'
import moment from 'moment'
import { text } from 'd3'

const AXIS_SIZE = 80

const Y_RANGE = 0.02

const PrismPointCharts: React.FC = () => {
	const [data, setData] = useState<Map<number, PrismPointMeasurment[]>>(
		{} as Map<number, PrismPointMeasurment[]>
	)

	const selectedSection = useAppSelector(
		(s) => s.sectionReducer.selectedSection
	)

	const prismPoints = useAppSelector((s) =>
		s.prismPointReducer.prismPoints.filter(
			(pp) => pp.section === selectedSection.id
		)
	)

	const [container, width, height] = useSize<HTMLDivElement>()

	useEffect(() => {
		fetchData()
	}, [selectedSection])

	const fetchData = async () => {
		setData({} as Map<number, PrismPointMeasurment[]>)
		if (prismPoints?.length) {
			prismPoints.forEach(async (pp) => {
				const ppData = await fetchMeasurments(pp)
				setData((prev) => ({
					...prev,
					[pp.id]: ppData,
				}))
			})
		}
	}

	const xScale = data
		? d3
				.scaleLinear()
				.domain([
					d3.min(
						Object.values(data).reduce<number[]>(
							(acc, v) => [
								...acc,
								...v.map((pp) => new Date(pp.dt_gen).getTime()),
							],
							[]
						)
					),
					d3.max(
						Object.values(data).reduce<number[]>(
							(acc, v) => [
								...acc,
								...v.map((pp) => new Date(pp.dt_gen).getTime()),
							],
							[]
						)
					),
				])
				.range([AXIS_SIZE, width])
		: null

	const yScale = d3
		.scaleLinear()
		.domain([Y_RANGE, -Y_RANGE])
		.range([50, height - 50])

	return (
		<div className='h-full'>
			<div className='text-center text-gray-600 font-medium'>
				Expottación de datos
			</div>
			<div className='h-1/3 bg-white relative' ref={container}>
				<div className='absolute top-0 left-1/2'>X</div>

				{Object.values(data).length && (
					<svg width={width} height={height}>
						{yScale.ticks().map((tickValue) => (
							<g key={tickValue}>
								<line
									x1={AXIS_SIZE}
									y1={yScale(tickValue)}
									x2={width}
									y2={yScale(tickValue)}
									stroke='#DDD'
								></line>
								<text
									x={AXIS_SIZE - 3}
									y={yScale(tickValue)}
									textAnchor='end'
								>
									{tickValue.toFixed(2)}
									{' [m]'}
								</text>
							</g>
						))}
						{xScale.ticks().map((tickValue) => (
							<g key={tickValue}>
								<line
									x1={xScale(tickValue)}
									y1={50}
									x2={xScale(tickValue)}
									y2={height - 50}
									stroke='#DDD'
								></line>
								{/* <text
                                            x={xScale(tickValue)}
                                            y={height-50}
                                            className="text-xs"
                                            textAnchor="end"
                                            transform={`rotate(-45,${xScale(
                                                tickValue
                                            )} ${height -50 })`}
                                        >
                                            {moment
                                                .unix(tickValue)
                                                .format("DD/MM/YYYY HH:mm:ss")}
                                        </text> */}
							</g>
						))}
						{Object.keys(data)
							.map((pp) => parseInt(pp))
							.map((pp) => (
								<polyline
									width={width}
									height={height}
									stroke={getPrismPointColor(pp)}
									fill='none'
									strokeWidth={3}
									points={data[pp].reduce<string>(
										(acc, value) =>
											`${acc}${xScale(
												new Date(value.dt_gen).getTime()
											)},${yScale(
												value.rotated_data.x
											)} `,
										''
									)}
								/>
							))}
					</svg>
				)}
			</div>
			<div className='h-1/3 bg-white relative'>
				<div className='absolute top-0 left-1/2'>Y</div>

				{!!data && (
					<svg width={width} height={height}>
						{yScale.ticks().map((tickValue) => (
							<g key={tickValue}>
								<line
									x1={AXIS_SIZE}
									y1={yScale(tickValue)}
									x2={width}
									y2={yScale(tickValue)}
									stroke='#DDD'
								></line>
								<text
									x={AXIS_SIZE - 3}
									y={yScale(tickValue)}
									textAnchor='end'
								>
									{tickValue.toFixed(2)}
									{' [m]'}
								</text>
							</g>
						))}
						{xScale.ticks().map((tickValue) => (
							<g key={tickValue}>
								<line
									x1={xScale(tickValue)}
									y1={50}
									x2={xScale(tickValue)}
									y2={height - 50}
									stroke='#DDD'
								></line>
								{/* <text
                                            x={xScale(tickValue)}
                                            y={height}
                                            className="text-xs"
                                            textAnchor="end"
                                            transform={`rotate(-45,${xScale(
                                                tickValue
                                            )} ${height })`}
                                        >
                                            {moment
                                                .unix(tickValue)
                                                .format("DD/MM/YYYY HH:mm:ss")}
                                        </text> */}
							</g>
						))}
						{Object.keys(data)
							.map((pp) => parseInt(pp))
							.map((pp) => (
								<polyline
									width={width}
									height={height}
									stroke={getPrismPointColor(pp)}
									fill='none'
									strokeWidth={3}
									points={data[pp].reduce<string>(
										(acc, value) =>
											`${acc}${xScale(
												new Date(value.dt_gen).getTime()
											)},${yScale(
												value.rotated_data.y
											)} `,
										''
									)}
								/>
							))}
					</svg>
				)}
			</div>
			<div className='h-1/3 bg-white relative'>
				<div className='absolute top-0 left-1/2 '>Z</div>
				{!!data && (
					<svg width={width} height={height}>
						{yScale.ticks().map((tickValue) => (
							<g key={tickValue}>
								<line
									x1={AXIS_SIZE}
									y1={yScale(tickValue)}
									x2={width}
									y2={yScale(tickValue)}
									stroke='#DDD'
								></line>
								<text
									x={AXIS_SIZE - 3}
									y={yScale(tickValue)}
									textAnchor='end'
								>
									{tickValue.toFixed(2)}
									{' [m]'}
								</text>
							</g>
						))}
						{xScale.ticks().map((tickValue) => (
							<g key={tickValue}>
								<line
									x1={xScale(tickValue)}
									y1={50}
									x2={xScale(tickValue)}
									y2={height - 50}
									stroke='#DDD'
								></line>
								{/* <text
                                            x={xScale(tickValue)}
                                            y={height}
                                            className="text-xs"
                                            textAnchor="end"
                                            transform={`rotate(-45,${xScale(
                                                tickValue
                                            )} ${height })`}
                                        >
                                            {moment
                                                .unix(tickValue)
                                                .format("DD/MM/YYYY HH:mm:ss")}
                                        </text> */}
							</g>
						))}
						{Object.keys(data)
							.map((pp) => parseInt(pp))
							.map((pp) => (
								<polyline
									width={width}
									height={height}
									stroke={getPrismPointColor(pp)}
									fill='none'
									strokeWidth={3}
									points={data[pp].reduce<string>(
										(acc, value) =>
											`${acc}${xScale(
												new Date(value.dt_gen).getTime()
											)},${yScale(
												value.rotated_data.z
											)} `,
										''
									)}
								/>
							))}
					</svg>
				)}{' '}
			</div>
		</div>
	)
}
export default PrismPointCharts
