import {
	Paper,
	Table,
	TableBody,
	TableBodyProps,
	TableCell,
	TableContainer,
	TableContainerProps,
	TableHead,
	TableHeadProps,
	TableProps,
	TableRow,
} from '@material-ui/core'

interface Props {
	ContainerProps?: TableContainerProps
	TableProps?: TableProps
	TableHeadProps?: TableHeadProps
	TableBodyProps?: TableBodyProps
	headers: string[]
}

const AppTable: React.FC<Props> = ({
	ContainerProps,
	TableBodyProps,
	TableHeadProps,
	TableProps,
	children,
	headers,
}) => {
	return (
		<TableContainer component={Paper} {...ContainerProps}>
			<Table {...TableProps} size='small'>
				<TableHead {...TableHeadProps}>
					<TableRow>
						{headers.map((header) => (
							<TableCell key={header}>{header}</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody {...TableBodyProps}>{children}</TableBody>
			</Table>
		</TableContainer>
	)
}
export default AppTable
