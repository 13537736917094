import store from '../store'
import { AuthActions } from '../reducers/AuthReducer'
import axios from 'axios'
import { ProfileActions } from '../reducers/ProfileReducer'
import { Profile } from '../types/Profile'
import { GroupActions } from '../reducers/GroupReducer'
import { Group } from '../types/Group'

const API_URL = process.env.REACT_APP_API_URL

export const login = async (username: string, password: string) => {
	store.dispatch({ type: AuthActions.RequestLogin, payload: {} })
	const response = await axios.post(
		API_URL + process.env.REACT_APP_PATH_LOGIN,
		{ username, password }
	)
	const { token } = response.data
	store.dispatch({ type: AuthActions.SetToken, payload: token })

	getUser()
	getFrontendPermissions()

	// const permissionsResponse = await axios.get(
	// 	API_URL + 'frontend_permissions/current/',
	// 	{ headers: authHeader() }
	// )
	// store.dispatch({
	// 	type: AuthActions.SetPermissions,
	// 	payload: permissionsResponse.data,
	// })
}

export const logout = () => {
	store.dispatch({ type: AuthActions.Logout, payload: {} })
}

export const getUser = async () => {
	const { data } = await axios.get(
		API_URL + process.env.REACT_APP_PATH_USERS + 'me/',
		{ headers: authHeader() }
	)
	store.dispatch({ type: AuthActions.SetUser, payload: data })
}
export const getUsers = async () => {
	const { data } = await axios.get(
		API_URL + process.env.REACT_APP_PATH_USERS,
		{ headers: authHeader() }
	)
	store.dispatch({ type: AuthActions.SetUsers, payload: data.results })
}

export const setIsAdmin = (isAdmin: boolean) => {
	store.dispatch({ type: AuthActions.SetIsAdmin, payload: isAdmin })
}

export const authHeader = () => {
	const token = store.getState().authReducer.token
	return token ? { Authorization: 'token ' + token } : {}
}

export const getUserById = (id: number) =>
	store.getState().authReducer?.users?.find((u) => u.id === id)

export const getProfiles = async () => {
	const { data } = await axios.get<Paginated<Profile>>(
		API_URL + process.env.REACT_APP_PATH_PROFILES,
		{ headers: authHeader() }
	)
	store.dispatch({ type: ProfileActions.SetProfiles, payload: data.results })
}

export const createUser = async (user: User) => {
	const { data } = await axios.post<User>(
		API_URL + process.env.REACT_APP_PATH_USERS,
		user,
		{ headers: authHeader() }
	)
	return data
}

export const editUser = async (id: number, user: Partial<User>) => {
	const { data } = await axios.patch<User>(
		API_URL + process.env.REACT_APP_PATH_USERS + `${id}/`,
		user,
		{ headers: authHeader() }
	)
	return data.id
}

export const createProfile = async (profile: Partial<Profile>) => {
	const { data } = await axios.post<User>(
		API_URL + process.env.REACT_APP_PATH_PROFILES,
		profile,
		{ headers: authHeader() }
	)
	return data.id
}
export const editProfile = async (id: number, profile: Partial<Profile>) => {
	const { data } = await axios.patch<User>(
		API_URL + process.env.REACT_APP_PATH_PROFILES + `${id}/`,
		profile,
		{ headers: authHeader() }
	)
	return data.id
}

export const getGroups = async () => {
	const { data } = await axios.get<Paginated<Group>>(
		API_URL + process.env.REACT_APP_PATH_GROUPS,
		{ headers: authHeader() }
	)
	store.dispatch({ type: GroupActions.SetGroups, payload: data.results })
}

export const createGroup = async (group: Partial<Group>) => {
	const { data } = await axios.post<User>(
		API_URL + process.env.REACT_APP_PATH_GROUPS,
		group,
		{ headers: authHeader() }
	)
	return data.id
}

export const editGroup = async (id: number, group: Partial<Group>) => {
	const { data } = await axios.patch<User>(
		API_URL + process.env.REACT_APP_PATH_GROUPS + `${id}/`,
		group,
		{ headers: authHeader() }
	)
	return data.id
}

export const deleteUser = async (user: User) => {
	await axios.delete<User>(
		API_URL + process.env.REACT_APP_PATH_PROFILES + `${user.id}/`,
		{ headers: authHeader() }
	)
	await axios.delete<User>(
		API_URL + process.env.REACT_APP_PATH_USERS + `${user.profile}/`,
		{ headers: authHeader() }
	)
}

export const deleteGroup = async (id: number) => {
	await axios.delete(API_URL + process.env.REACT_APP_PATH_GROUPS + `${id}/`, {
		headers: authHeader(),
	})
}

export const getFrontendPermissions = async () => {
	const permissionsResponse = await axios.get(
		API_URL + 'permissions/frontend_permissions/current/',
		{ headers: authHeader() }
	)
	store.dispatch({
		type: AuthActions.SetFrontendPermissions,
		payload: permissionsResponse.data,
	})
}