import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	TextField,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
} from '@material-ui/core'
import Checkbox from '../../shared/Checkbox'

import { useAppSelector } from '../../../hooks/storeHooks'
import { useForm } from '../../../hooks/useForm'
import { createSection, fetchAllSections } from '../../../services/section'
import {
	addSnackbarNotification,
	withError,
} from '../../../services/snackbarNotification'
import { createPrismPoint } from '../../../services/prismPoint'
import { useEffect, useState } from 'react'
import { fetchAllAxes } from '../../../services/axis'
interface Props {
	open: boolean
	onClose?: (event?: {}, reason?: 'backdropClick' | 'escapeKeyDown') => void
	section: number
	is_fixed_point?: boolean
}

const NewPrismPointModal: React.FC<Props> = ({
	open,
	onClose,
	section,
	is_fixed_point,
}) => {
	const [values, setValues, handleChange] = useForm<any>({
		short_code: '0',
		section: 0,
		active: false,
		is_fixed_point: !!is_fixed_point,
		is_trigonometric: true,
		azimuth: 0
	})

	const [is_free, setIs_free] = useState(false)
	const [hasAzimuth, setHasAzimuth] = useState(false)


	useEffect(() => {
		fetchAllSections()
		fetchAllAxes()
		open && setValues({ ...values, section })
	}, [open])

	const sections = useAppSelector((s) => s.sectionReducer.sections)
	const axes = useAppSelector((s) => s.axisReducer.axes)

	const [selectedSection, setSelectedSection] = useState<number>()
	const [selectedAxis, setSelectedAxis] = useState<Axis>(null)

	useEffect(() => {
		setHasAzimuth(selectedAxis ? !selectedAxis.is_horizontal : false || sections.find((s) => s.id === selectedSection)?.section_type === 'edificio')
	}, [selectedSection, selectedAxis, sections])
	return (
		<Dialog fullWidth maxWidth='md' open={open} onClose={onClose}>
			<DialogTitle>
				Nuevo Punto {is_fixed_point ? 'Fijo' : 'de Movimiento'}
			</DialogTitle>
			<DialogContent>
				<TextField
					value={values.short_code}
					onChange={handleChange}
					label='Número'
					fullWidth
					id='short_code'
					margin='normal'
					helperText='Ejemplo: 01'
				></TextField>
				<div className='py-2'>
					<FormControl className='w-full'>
						<InputLabel id={'axis-label'}>Eje</InputLabel>
						<Select
							labelId={'axis-label'}
							value={selectedAxis?.id}
							onChange={(e) =>
								setSelectedAxis(
									axes.find((a) => a.id === e.target.value)
								)
							}
							fullWidth
							label='Eje'
							placeholder='Seleccione un eje'
						>
							{axes?.map((axis) => (
								<MenuItem value={axis.id}>
									{axis.code} {axis.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</div>
				{!is_free && <div className='py-2'>
					<FormControl className='w-full'>
						<InputLabel id={'section-label'}>Secciones</InputLabel>
						<Select
							labelId={'section-label'}
							value={selectedSection}
							onChange={(e) =>
								setSelectedSection(e.target.value as number)
							}
							fullWidth
							label='Secciones'
							placeholder='Seleccione una o más secciones'
						>
							{!!selectedAxis &&
								sections
									.filter((s) => s.axis === selectedAxis.id)
									.map((section) => (
										<MenuItem value={section.id}>
											{section.code}
										</MenuItem>
									))}
						</Select>
					</FormControl>
				</div>}
				{hasAzimuth && <TextField
					value={values.azimuth}
					onChange={handleChange}
					label='Azimuth (° Centesimales)'
					fullWidth
					type='number'
					id='azimuth'
					margin='normal'
					helperText='Ejemplo: 01'
				></TextField>}

				<Checkbox
					checked={is_free}
					onChange={(e) =>
						setIs_free(e.target.checked)
					}
					label='Punto fijo'
				></Checkbox>
				<Checkbox
					checked={values.is_trigonometric}
					onChange={(e) =>
						setValues({
							...values,
							is_trigonometric: e.target.checked,
						})
					}
					label='Trigonométrico'
				></Checkbox>
			</DialogContent>
			<DialogActions>
				<Button
					variant='contained'
					color='primary'
					onClick={withError(async () => {
						const aux = values
						delete aux.thresholds
						aux.section = selectedSection
						aux.code = aux.short_code

						is_free && (aux.is_free = true)
						values.section < 1 && delete aux.section
						aux.axis = sections.find(
							(s) => s.id === aux.section
						)?.axis
						if (is_free) {
							aux.is_free = true
							delete aux.section
							aux.section = null
							aux.axis = selectedAxis.id
						}
						!hasAzimuth ? delete aux.azimuth : aux.azimuth = parseFloat(values.azimuth) * 360 / 400
						await createPrismPoint(aux)
						addSnackbarNotification({
							message: 'Punto creado',
						})
						onClose()
					})}
				>
					Guardar
				</Button>
				<Button onClick={() => onClose()}>Cancelar</Button>
			</DialogActions>
		</Dialog>
	)
}
export default NewPrismPointModal
