import { Reducer } from 'redux'
import { TopoFileReview } from '../types/TopoFileReview'

interface State {
	topoFiles: TopoFile[]
	isLoading: boolean
	topoFileReviews: TopoFileReview[]
	selectedTopoFileReview: TopoFileReview
}

const initialState = {
	topoFiles: [],
	isLoading: false,
	topoFileReviews: [],
	selectedTopoFileReview: null,
}

export enum TopoFileActions {
	RequestTopoFiles = 'REQUEST_TOPO_FILES',
	SetTopoFiles = 'SET_TOPO_FILES',
	SetTopoFileReviews = 'SET_TOPO_FILE_REVIEWS',
	AddTopoFile = 'ADD_TOPO_FILE',
	AddTopoFiles = 'ADD_TOPO_FILES',
	AddTopoFileReview = 'ADD_TOPO_FILE_REVIEW',
	AddTopoFileReviews = 'ADD_TOPO_FILE_REVIEWS',
	SetSelectedTopoFileReview = 'SET_SELECTED_TOPO_FILE_REVIEW',
	// SetTopoFileStatus = 'SET_TOPO_FILE_STATUS',
}

const reducer: Reducer<State, { type: TopoFileActions; payload: any }> = (
	state: State = initialState,
	{ type, payload }
): State => {
	switch (type) {
		case TopoFileActions.RequestTopoFiles:
			return { ...state, isLoading: true }
		case TopoFileActions.SetTopoFiles:
			return { ...state, isLoading: false, topoFiles: payload }
		case TopoFileActions.AddTopoFileReviews:
			return {
				...state,
				topoFileReviews: [
					...state.topoFileReviews.filter(
						(tfr) =>
							!payload.find(
								(ntfr: TopoFileReview) => ntfr.id === tfr.id
							)
					),
					...payload,
				],
			}
		case TopoFileActions.AddTopoFileReview:
			return {
				...state,
				topoFileReviews: [
					...state.topoFileReviews.filter(
						(tfr) => payload.id !== tfr.id
					),
					payload,
				],
			}
		case TopoFileActions.AddTopoFile:
			return {
				...state,
				topoFiles: [...state.topoFiles.filter((tf) => tf.id !== payload.id), payload],
			}
		case TopoFileActions.SetSelectedTopoFileReview:
			return {
				...state,
				selectedTopoFileReview: payload,
			}
		// case TopoFileActions.SetTopoFileStatus:
		//     return { ...state, isLoading: false, topoFiles: payload }
		default:
			return state
	}
}

export default reducer
