import { createMuiTheme, ThemeProvider } from "@material-ui/core"
import { mainColor } from "../../styles/styleVars"

const theme = createMuiTheme({
    palette: {
        primary: {
            main: mainColor,
        },
    },
})

const Theme: React.FC = ({ children }) => {
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}
export default Theme
