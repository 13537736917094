import axios from 'axios'
import { ExcavationProgressActions } from '../reducers/ExcavationProgressReducer'
import store from '../store'
import { authHeader } from './auth'
const API_URL = process.env.REACT_APP_API_URL

export const submitExcavationProgress = async (
	excavationProgress: Partial<ExcavationProgress>
) => {
	const response = await axios.post<ExcavationProgress>(
		API_URL + 'axis/excavation_front_progress/',
		excavationProgress,
		{ headers: authHeader() }
	)
}

export const fetchAllExcavationProgress = async () => {
	var response = await axios.get<Paginated<ExcavationProgress>>(
		API_URL + 'axis/excavation_front_progress/',
		{ headers: authHeader() }
	)
	console.log(response.data.results)
	store.dispatch({
		type: ExcavationProgressActions.AddMultipleExcavationProgress,
		payload: response.data.results,
	})
	while (response.data.next) {
		response = await axios.get<Paginated<ExcavationProgress>>(
			response.data.next,
			{ headers: authHeader() }
		)
		store.dispatch({
			type: ExcavationProgressActions.AddMultipleExcavationProgress,
			payload: response.data.results,
		})
	}
}

export const editExcavationProgress = async (
	id: number,
	excavationProgress: Partial<ExcavationProgress>
) => {
	const response = await axios.patch<ExcavationProgress>(
		API_URL + `axis/excavation_front_progress/${id}/`,
		excavationProgress,
		{ headers: authHeader() }
	)
	store.dispatch({
		type: ExcavationProgressActions.AddMultipleExcavationProgress,
		payload: [response.data],
	})
}

export const deleteExcavationProgress = async (id: number) => {
	const response = await axios.delete<ExcavationProgress>(
		API_URL + `axis/excavation_front_progress/${id}/`,
		{ headers: authHeader() }
	)
	store.dispatch({
		type: ExcavationProgressActions.RemoveExcavationProgress,
		payload: id,
	})
}
