import {
	Checkbox as MaterialCheckbox,
	FormControlLabel,
} from '@material-ui/core'
import { ChangeEvent } from 'react'
interface Props {
	onChange?: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void
	checked: boolean
	label?: string
}

const Checkbox: React.FC<Props> = ({ onChange, checked, label }) => {
	return (
		<FormControlLabel
			control={
				<MaterialCheckbox
					color='primary'
					checked={checked}
					onChange={onChange}
				/>
			}
			label={label}
		></FormControlLabel>
	)
}
export default Checkbox
