import { Button, TableCell, TableRow } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useAppSelector } from '../../../hooks/storeHooks'
import {
	deleteFileType,
	editFileType,
	getFileTypes,
} from '../../../services/file'
import {
	addSnackbarNotification,
	withError,
} from '../../../services/snackbarNotification'
import DeleteButton from '../../shared/DeleteButton'
import EditableLabel from '../../shared/EditableLabel'
import AppTable from '../../utils/AppTable'
import NewFileTypeModal from './NewFileTypeModal'

const FileTypeConfig: React.FC = () => {
	const fileTypes = useAppSelector((s) => s.fileReducer.fileTypes)
	const [newFileTypeModal, setNewFileTypeModal] = useState(false)

	useEffect(() => {
		getFileTypes()
	}, [])
	return (
		<div>
			<div className='title-xl text-center p-2'>
				Tipos de Reporte
				<Button
					color='primary'
					variant='contained'
					className='float-right'
					onClick={() => setNewFileTypeModal(true)}
				>
					Nuevo Tipo
				</Button>
			</div>
			<AppTable headers={['Nombre']}>
				{fileTypes.map((ft) => (
					<TableRow key={ft.id}>
						<TableCell>
							<EditableLabel
								id='name'
								value={ft.name}
								label='Nombre'
								onSubmit={withError(async (name) => {
									await editFileType(ft.id, { name })
								})}
							></EditableLabel>
						</TableCell>
						<TableCell>
							<DeleteButton
								onClick={withError(async () => {
									await deleteFileType(ft.id)
									addSnackbarNotification({
										message: 'Tipo eliminado',
									})
								})}
							></DeleteButton>
						</TableCell>
					</TableRow>
				))}
			</AppTable>
			<NewFileTypeModal
				open={newFileTypeModal}
				onClose={() => setNewFileTypeModal(false)}
			></NewFileTypeModal>
		</div>
	)
}
export default FileTypeConfig
