import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	createStyles,
	makeStyles,
	Theme,
} from '@material-ui/core'
import { mdiAutorenew, mdiChevronDown, mdiMemory } from '@mdi/js'
import Icon from '@mdi/react'
import { useEffect, useState } from 'react'
import { useAppSelector } from '../../../hooks/storeHooks'
import {
	fetchAllSectionPrismPoints,
	getPrismPointColor,
} from '../../../services/prismPoint'
import { setSelectedSection } from '../../../services/section'

import { analysisProjectUnitIconColor } from '../../../styles/styleVars'
import { Section } from '../../../types/Section'

interface Props {
	section: Section
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({ root: { padding: 0 }, content: { margin: 0 } })
)

const SectionExpandable: React.FC<Props> = ({ section }) => {
	const classes = useStyles()

	useEffect(() => {
		fetchAllSectionPrismPoints(section)
	}, [section])

	const loading = useAppSelector(
		(s) => s.prismPointReducer.isLoading[section.id]
	)
	const prismPoints = useAppSelector((s) =>
		s.prismPointReducer.prismPoints.filter(
			(pp) => pp.section === section.id
		)
	)
	const selectedSection = useAppSelector(
		(s) => s.sectionReducer.selectedSection
	)

	return (
		<Accordion
			classes={{ root: classes.root }}
			elevation={0}
			className='p-0 m-0'
			onChange={(_, isExpanded) => isExpanded}
		>
			<AccordionSummary
				classes={{ root: classes.root, content: classes.content }}
				expandIcon={
					<Icon
						path={mdiChevronDown}
						className='w-6'
						color='#BBB'
					></Icon>
				}
			>
				<div
					className='flex w-ful items-center font-light cursor-pointer text-sm px-2'
					onClick={() => setSelectedSection(section)}
				>
					<div>
						{/* <Icon
                            path={mdiMemory}
                            color={analysisProjectUnitIconColor}
                            className="w-6 mr-2"
                        ></Icon> */}
					</div>
					<div
						className={`flex-1 text-sm px-2 ${
							selectedSection?.id === section.id
								? 'bg-secondary-1 bg-opacity-30'
								: ''
						}`}
					>
						{section.code}
					</div>
				</div>
			</AccordionSummary>
			<AccordionDetails>
				{loading ? (
					<div className='w-full flex justify-center'>
						<Icon
							path={mdiAutorenew}
							color={'#AAA'}
							className='w-7 m-auto'
							spin
						></Icon>
					</div>
				) : (
					<div className='font-light cursor-pointer'>
						{!!prismPoints?.length &&
							prismPoints.map((pp) => (
								<div
									className={`
                                    px-2 rounded
                                    `}
									key={pp.id}
									style={{
										backgroundColor:
											pp.section === selectedSection?.id
												? getPrismPointColor(pp.id)
												: 'transparent',
									}}
								>
									{pp.code}
								</div>
							))}
					</div>
				)}
			</AccordionDetails>
		</Accordion>
	)
}
export default SectionExpandable
