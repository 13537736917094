import {
	Button,
	FormControl,
	Input,
	InputLabel,
	ListSubheader,
	MenuItem,
	Select,
} from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useAppSelector } from '../../../hooks/storeHooks'
import { fetchAllAxes } from '../../../services/axis'
import { fetchAllSections } from '../../../services/section'
import useStyles from '../../../styles/appStyles'
import moment from 'moment-timezone'
import { DatePicker, DateTimePicker } from '@material-ui/pickers'
import { Card } from '@material-ui/core'
import Checkbox from '../../shared/Checkbox'
import { dataToFileSubmission } from '../../../services/topoFile'
import { eventMonitor } from '../../../services/flower'
import { fetchDataToFileRequest } from '../../../services/report'
import Icon from '@mdi/react'
import { mdiCheck } from '@mdi/js'
import { withError } from '../../../services/snackbarNotification'

const DataSubscriptionForm: React.FC = () => {
	const classes = useStyles(true)()

	const axes = useAppSelector((s) => s.axisReducer.axes)
	const sections = useAppSelector((s) => s.sectionReducer.sections)
	const timeZone = useAppSelector((s) => s.configReducer.timeZone)

	const [timeRange, setTimeRange] = useState({
		start: moment()
			.tz(timeZone)
			.subtract(7 * 24 * 3600, 'seconds'),
		end: moment().tz(timeZone),
	})
	const [result, setResult] = useState(null)

	const [state, setState] = useState('')

	const [fileTypes, setFileTypes] = useState({ csv: true, xls: false })

	const [completedTaskBuffer, setCompletedTaskBuffer] = useState<string[]>([])
	const [taskParams, setTaskParams] = useState({ id: 0, taskId: '' })

	const [selectedSections, setSelectedSections] = useState<number[]>([])
	const [allSections, setAllSections] = useState(false)
	const [selectedAxis, setSelectedAxis] = useState<Axis>(null)
	const socket = useAppSelector((s) => s.wsReducer.flowerSocket)

	const handleChangeMultiple = (
		event: React.ChangeEvent<{ value: unknown }>
	) => {
		const { value } = event.target as HTMLSelectElement

		setSelectedSections(value as unknown as number[])
	}

	const addAllSectionsToSelectedSections = () => {
		if (selectedAxis) {
		  const all = sections.filter((section) => {
			return section.axis === selectedAxis.id;
		  }).map((section) => {return section.id})
		  setSelectedSections(all)
		}
	  }

	useEffect(() => {
		fetchAllAxes()
		fetchAllSections()
	}, [])

	useEffect(() => {
		const check = async () => {
			if (taskParams.taskId) {
				if (completedTaskBuffer.includes(taskParams.taskId)) {
					const completedRequest = await fetchDataToFileRequest(
						taskParams.id
					)
					if (completedRequest['empty']==true) {
						setState('No hay datos para exportar')
					} else {
						setState('Listo')
					}
					setResult(completedRequest)
					setTaskParams({ id: 0, taskId: '' })
				}
			}
		}

		check()
	}, [completedTaskBuffer, taskParams])

	return (
		<div
			className={`bg-gray-200 w-full overflow-auto p-3 ${classes.bodyContent}`}
		>
			<Card>
				<div className='text-center text-gray-600 font-medium'>
					Exportación de datos
				</div>
				<div className='p-2'>
					<FormControl className='w-full'>
						<InputLabel id={'axis-label'}>Eje</InputLabel>
						<Select
							labelId={'axis-label'}
							value={selectedAxis?.id}
							onChange={(e) => {
								setSelectedAxis(
									axes.find((a) => a.id === e.target.value)
								)
								setSelectedSections([])
								setAllSections(false)
							}
							}
							fullWidth
							label='Eje'
							placeholder='Seleccione un eje'
						>
							{axes?.map((axis) => (
								<MenuItem value={axis.id}>
									{axis.code} {axis.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</div>
				<div className='p-2'>
					<FormControl className='w-full'>
						<InputLabel id={'section-label'}>Secciones</InputLabel>
						<Select
							multiple
							labelId={'section-label'}
							value={selectedSections}
							onChange={handleChangeMultiple}
							disabled={allSections}
							fullWidth
							label='Secciones'
							placeholder='Seleccione una o más secciones'
							renderValue={(selected: number[]) => {
								return selected
									.map(
										(sel) =>
											sections.find((s) => s.id === sel)
												?.code
									)
									.reduce<string>(
										(acc, v, i) =>
											acc + `${i === 0 ? '' : ', '}${v}`,
										''
									)
							}}
						>
							{!!selectedAxis &&
								sections
									.filter((s) => s.axis === selectedAxis.id)
									.map((section) => (
										<MenuItem value={section.id}>
											{section.code}{' '}
											{!!selectedSections.find(
												(s) => s === section.id
											) && (
													<div className='w-5 h-5 text-gray-500'>
														<Icon
															path={mdiCheck}
														></Icon>
													</div>
												)}
										</MenuItem>
									))}
						</Select>
					</FormControl>
				</div>
				{!allSections && (
					<div className='p-2'>
						Secciones seleccionadas:{' '}
						{selectedSections.reduce(
							(acc, v, i) =>
								acc +
								`${i === 0 ? '' : ', '} ${sections.find((s) => s.id === v)?.code
								}`,
							''
						)}
					</div>
				)}
				<div className='p-2'>
					<Checkbox
						checked={allSections}
						onChange={(e) => {
							addAllSectionsToSelectedSections()
							setAllSections(e.target.checked)
							if (allSections) {
								setSelectedSections([])
							  }
						}}
						label='Todo el eje'
					></Checkbox>
				</div>
				<div className='m-1 p-1 flex space-x-2'>
					<div className='p-1'>
						<DatePicker
							value={timeRange.start.tz(timeZone)}
							onChange={(time) => {
								setTimeRange((s) => ({
									...s,
									start: moment(time),
								}))
								// adjustRange()
							}}
							label='Inicio'
							InputLabelProps={{
								shrink: true,
							}}
							disableFuture
							format='dd/MMM/yyyy'
						></DatePicker>
					</div>
					<div className='p-1'>
						<DatePicker
							disableFuture
							value={timeRange.end.tz(timeZone)}
							format='dd/MMM/yyyy'
							onChange={(time) => {
								setTimeRange((s) => ({
									...s,
									end: moment(time),
								}))
								// adjustRange()
							}}
							label='Fin'
							InputLabelProps={{
								shrink: true,
							}}
						></DatePicker>
					</div>
				</div>
				<div className='flex p-2 items-center'>
					<Checkbox
						checked={fileTypes.csv}
						onChange={(e) =>
							setFileTypes({
								...fileTypes,
								csv: e.target.checked,
							})
						}
						label='CSV'
					></Checkbox>
					<Checkbox
						checked={fileTypes.xls}
						onChange={(e) =>
							setFileTypes({
								...fileTypes,
								xls: e.target.checked,
							})
						}
						label='XLS'
					></Checkbox>
					<div className='flex-1'></div>
					<Button
						variant='contained'
						color='primary'
						onClick={withError(async () => {
							if (!selectedAxis || (allSections ? !sections.filter(s => s.axis === selectedAxis?.id).map(s => s.id).length : !selectedSections.length)) {
								throw new Error('Seleccione al menos una sección')
							}
							setTaskParams({ id: 0, taskId: '' })
							// const socket = eventMonitor()
							socket.onmessage = socket.onmessage = async (e) => {
								const data = JSON.parse(e.data as string)
								if (data.uuid) {
									if (data.uuid) {
										setCompletedTaskBuffer([
											...completedTaskBuffer,
											data.uuid,
										])
									}
								}
							}
							setResult(null)
							setState('Procesando...')
							const request = await dataToFileSubmission({
								do_spreadsheet: fileTypes.xls,
								start_date: timeRange.start.toDate(),
								end_date: timeRange.end.toDate(),
								sections: allSections ? sections.filter(s => s.axis === selectedAxis.id).map(s => s.id) : selectedSections,
							})
							setTaskParams({
								taskId: request.task_id,
								id: request.id,
							})
						})}
					>
						Generar
					</Button>
				</div>
				<div className='border-main-1 justify-center items-center rounded-lg relative flex space-x-2 pb-2'>
					<div className=' text-center'>{`${state}`}</div>
					{result?.csv && (
						<Button variant='outlined' color='primary'>
							<a
								href={result.csv}
								target='_blank'
								rel='noreferrer'
							>
								CSV
							</a>
						</Button>
					)}
					{result?.spreadsheet && (
						<Button variant='outlined' color='primary' href={result.spreadsheet} download>
							{/* <a
								href={result.spreadsheet}
								target='_blank'
								rel='noreferrer'
							> */}
							XLS
							{/* </a> */}
						</Button>
					)}
				</div>
			</Card>
		</div>
	)
}
export default DataSubscriptionForm
