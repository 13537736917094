import {
	Button,
	Menu,
	MenuItem,
	Select,
	TableCell,
	TableRow,
} from '@material-ui/core'
import { mdiCheck, mdiCircleHalf } from '@mdi/js'
import { Icon } from '@mdi/react'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useAppSelector } from '../../../hooks/storeHooks'
import {
	deleteSection,
	fetchAllSections,
	updateSection,
} from '../../../services/section'
import {
	addSnackbarNotification,
	withErrors,
} from '../../../services/snackbarNotification'
import DeleteButton from '../../shared/DeleteButton'
import EditableLabel from '../../shared/EditableLabel'
import AppTable from '../../utils/AppTable'
import { fetchAllAxes } from './../../../services/axis'
import SectionNotes from './SectionNotes'
import NewSectionModal from './NewSectionModal'
import MonographViewer from './MonographViewer'
import { getSectionMonographs } from '../../../services/monograph'

interface Props {}

const SectionsConfig: React.FC<Props> = ({}) => {
	const axes = useAppSelector((s) => s.axisReducer.axes)
	const sections = useAppSelector((s) => s.sectionReducer.sections)
	const isLoading = useAppSelector((s) => s.axisReducer.isLoading)
	const [newSectionModalOpen, setNewSectionModalOpen] = useState(false)
	const [sectionNotesModalOpen, setSectionNotesModalOpen] = useState(false)
	const [monographModalOpen, setMonographModalOpen] = useState(false)
	const [filteredAxis, setFilteredAxis] = useState(null)
	const [selectedSection, setSelectedSection] = useState(null)
	useEffect(() => {
		fetchAllAxes()
		fetchAllSections()
	}, [])

	const getAxisById = (id: number) => {
		return axes.find((a) => a.id === id)
	}

	const filteredSections = () => {
		var tmp_sections = sections
		if (filteredAxis) {
			tmp_sections = sections.filter((s) => s.axis === filteredAxis)
		}
		return tmp_sections
	}

	return (
		<div className=''>
			<div className='title-xl text-center p-2'>
				Secciones
				<Button
					color='primary'
					variant='contained'
					className='float-right'
					onClick={() => setNewSectionModalOpen(true)}
				>
					Nueva Sección
				</Button>
			</div>
			<div className='title-lg flext justify-center text-center p-2'>
				Filtrar por eje:
				<Select
					value={filteredAxis}
					onChange={(e) => {
						setFilteredAxis(e.target.value)
					}}
					label='Filtrar por Eje'
					className='ml-2'
				>
					<MenuItem value={null}>Todos los ejes</MenuItem>
					{axes.map((a) => (
						<MenuItem value={a.id}>
							{a.code} - {a.name}
						</MenuItem>
					))}
				</Select>
			</div>
			<AppTable
				headers={[
					'Número de sección',
					'Eje',
					'PK',
					'Distancia de Excavación',
					'∢ de rotación (Centesimal)',
					'Creado',
				]}
			>
				{filteredSections().map((s) => (
					<TableRow key={s.id}>
						<TableCell>{s.code}</TableCell>
						<TableCell>
							{getAxisById(s.axis)?.code} -{' '}
							{getAxisById(s.axis)?.name}
						</TableCell>
						<TableCell>
							<EditableLabel
								id='project_kilometer'
								value={s.project_kilometer}
								onSubmit={async (project_kilometer) => {
									await updateSection(s.id, {
										project_kilometer,
									})
									fetchAllSections()
								}}
								label='Nombre'
							></EditableLabel>
						</TableCell>
						<TableCell>
							<EditableLabel
								id='excavation_distance'
								value={s.excavation_distance}
								onSubmit={async (excavation_distance) => {
									await updateSection(s.id, {
										excavation_distance,
									})
									fetchAllSections()
								}}
								label='Nombre'
							></EditableLabel>
						</TableCell>
						<TableCell>
							<EditableLabel
								id='azimuth'
								value={parseFloat(
									((s.azimuth * 400) / 360).toFixed(2)
								)}
								onSubmit={async (azimuth) => {
									await updateSection(s.id, {
										azimuth: (azimuth * 360) / 400,
									})
									fetchAllSections()
								}}
								label='Nombre'
							></EditableLabel>
						</TableCell>
						<TableCell>
							{moment(s.created).format('DD/MMM/YY')}
						</TableCell>
						<TableCell>
							<Button
								variant='outlined'
								onClick={() => {
									setSelectedSection(s)
									setSectionNotesModalOpen(true)
								}}
							>
								<div className='truncate'>Ver notas</div>
							</Button>
						</TableCell>
						<TableCell>
							<Button
								variant='outlined'
								onClick={() => {
									setSelectedSection(s)
									setMonographModalOpen(true)
								}}
							>
								<div className='truncate flex'>
									Ver monografías{' '}
									{s.monographs?.length ? (
										<div className='w-5 text-gray-600'>
											<Icon path={mdiCheck}></Icon>
										</div>
									) : (
										<></>
									)}
								</div>
							</Button>
						</TableCell>
						<TableCell>
							<DeleteButton
								onClick={async () => {
									withErrors(async () => {
										await deleteSection(s.id)
										addSnackbarNotification({
											message: 'Seccion eliminada',
										})
									})
								}}
							></DeleteButton>
						</TableCell>
					</TableRow>
				))}
			</AppTable>
			{isLoading && (
				<div className='text-center text-gray-600'>
					<Icon path={mdiCircleHalf} spin></Icon>
				</div>
			)}
			<NewSectionModal
				open={newSectionModalOpen}
				onClose={() => setNewSectionModalOpen(false)}
			></NewSectionModal>
			<SectionNotes
				open={sectionNotesModalOpen}
				onClose={() => setSectionNotesModalOpen(false)}
				section={selectedSection}
			></SectionNotes>
			<MonographViewer
				open={monographModalOpen}
				onClose={() => setMonographModalOpen(false)}
				section={selectedSection}
			></MonographViewer>
		</div>
	)
}
export default SectionsConfig
