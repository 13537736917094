import { useState, ChangeEvent, SetStateAction, Dispatch } from "react"

export const useForm = <T extends Object>(
    initialValue: T
): [
        T,
        Dispatch<SetStateAction<T>>,
        (event: React.ChangeEvent<{ value: unknown }>) => void
    ] => {
    const [values, setValues] = useState<T>(initialValue)

    const handleChange = (
        event: React.ChangeEvent<{ value: unknown, id: string, name: string }>
    ) => {
        console.log(event.target)
        setValues({
            ...values,
            [event.target.id ?? event.target.name]: event.target.value
        })
    }

    return [values, setValues, handleChange]
}
