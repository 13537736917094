import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  Slider,
  TextField,
} from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { useAppSelector } from "../../../hooks/storeHooks";
import { fetchAllAxes } from "../../../services/axis";
import { fetchAllSections } from "../../../services/section";
import useStyles from "../../../styles/appStyles";
import moment from "moment-timezone";
import { DatePicker, DateTimePicker } from "@material-ui/pickers";
import { Card } from "@material-ui/core";
import Checkbox from "../../shared/Checkbox";
import Cron from "react-cron-generator";
import "react-cron-generator/dist/cron-builder.css";
import { fetchReportTypes } from "../../../services/report";
import { scheduleReportSubmission } from "../../../services/topoFile";
import { eventMonitor } from "../../../services/flower";
import { addSnackbarNotification } from "../../../services/snackbarNotification";
import { IMessage, IMessageEvent, w3cwebsocket } from "websocket";
import Icon from "@mdi/react";
import { mdiCheck } from "@mdi/js";

const PlotCreationView: React.FC = () => {
  const classes = useStyles(true)();

  const axes = useAppSelector((s) => s.axisReducer.axes);
  const sections = useAppSelector((s) => s.sectionReducer.sections);
  const timeZone = useAppSelector((s) => s.configReducer.timeZone);

  const [selectedType, setSelectedType] = useState<string>("");
  const [timeRange, setTimeRange] = useState({
    start: moment()
      .tz(timeZone)
      .subtract(7 * 24 * 3600, "seconds"),
    end: moment().tz(timeZone),
  });
  const [counter, setCounter] = useState(0);
  const [scheduled, setScheduled] = useState(false);
  const [dayRange, setDayRange] = useState(1);
  const [cron, setCron] = useState("");
  const [name, setName] = useState(moment().format("YYYY_MM_DD_HH_mm_ss"));
  const [range, setRange] = useState<"dates" | "days" | "all">("all");
  const [sectionSelectionType, setSectionSelectionType] = useState<
    "select" | "range"
  >("select");
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [messageBuffer, setMessageBuffer] = useState<IMessageEvent[]>([]);

  const [scheduleIds, setScheduleIds] = useState<number[]>([]);
  const [idxs, setIdxs] = useState<string[]>([]);

  const [reportBuffer, setReportBuffer] = useState<
    { report: string; time: moment.Moment }[]
  >([]);
  const [errorBuffer, setErrorBuffer] = useState<string[]>([]);

  // const [socket, setSocket] = useState<w3cwebsocket>(null);
  const socket = useAppSelector((s) => s.wsReducer.flowerSocket)

  const [reportTypes, setReportTypes] =
    useState<{ id: number; name: string; description: string; legend: string; index: number }[]>();
  const [selectedSections, setSelectedSections] = useState<number[]>([]);
  const [sectionRange, setSectionRange] = useState([0, 0]);
  const [allSections, setAllSections] = useState(false);
  const [selectedAxis, setSelectedAxis] = useState<Axis>(null);
  const [trendLine, setTrendLine] = useState(false);

  const handleChangeMultiple = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const { value } = event.target as HTMLSelectElement;
    console.log("section value:" + value);
    setSelectedSections(value as unknown as number[]);
    setSelectedType(null)
  };

  const addAllSectionsToSelectedSections = () => {
    if (selectedAxis) {
      const all = sections.filter((section) => {
        return section.axis === selectedAxis.id;
      }).map((section) => {return section.id})
      setSelectedSections(all)
    }
  }

  const getReportTypes = async () => {
    const reportTypes = await fetchReportTypes();
    setReportTypes(reportTypes.results);
  };

  const processMessage = useCallback(
    (e: IMessageEvent) => {
      if (e) {
        const data = JSON.parse(e.data as string);
        var parsedResult;
        try {
          parsedResult = JSON.parse(
            data?.result
              .replaceAll("'", '"')
              .replaceAll("True", "true")
              .replaceAll("False", "false") as string
          );
        } catch (error) {
          parsedResult = data?.result;
        }

        if (scheduleIds.includes(parsedResult?.schedule_id)) {
          setIdxs([...idxs, parsedResult.results_idx]);
        }
        if (idxs.includes(parsedResult?.idx)) {
          const report = parsedResult.result?.pdf;

          if (!reportBuffer.map((r) => r.report).includes(report) && !!report) {
            processing && setProcessing(false);
            setReportBuffer([...reportBuffer, { report, time: moment() }]);
            setCounter((c) => c - 1);
          }
          if (!parsedResult.result?.ok) {
            const text = parsedResult.result?.text;
            text && setErrorBuffer([...errorBuffer, text]);
            setCounter((c) => c - 1);
          }
        }
      }
    },
    [idxs, reportBuffer, scheduleIds]
  );

  useEffect(() => {
    fetchAllAxes();
    fetchAllSections();
    getReportTypes();
  }, []);

  useEffect(() => {
    if (!socket) {
      const s = eventMonitor();
      // setSocket(s);
      s.onmessage = async (e) => {
        if (loading) {
          setMessageBuffer([...messageBuffer, e]);
        } else {
          processMessage && processMessage(e);
        }
      };
    } else {
      socket.onmessage = async (e) => {
        if (loading) {
          setMessageBuffer([...messageBuffer, e]);
        } else {
          processMessage && processMessage(e);
        }
      };
    }
  }, [loading, messageBuffer, processMessage]);

  useEffect(() => {
    if (!loading && messageBuffer.length) {
      messageBuffer.forEach(processMessage);
      setMessageBuffer([]);
    }
  }, [loading, messageBuffer, processMessage]);

  useEffect(() => {
    if (selectedAxis) {
      const min = Math.min(
        ...sections
          .filter((s) => s.axis === selectedAxis.id)
          .map((s) => s.excavation_distance)
      );
      const max = Math.max(
        ...sections
          .filter((s) => s.axis === selectedAxis.id)
          .map((s) => s.excavation_distance)
      );
      setSectionRange([min, max]);
    }
  }, [selectedAxis]);



  const disabledReportType = (id: number): boolean => {
    let allowedReports = []
    selectedSections.map((s) => {
      sections.filter((section) => {
        return section.id === s;
      }).map((section) => {
        allowedReports.push(section.allowed_report_type_ids)
      })
    })
    if (selectedAxis) {
      allowedReports.push(selectedAxis.allowed_report_type_ids)
    }
    return !allowedReports.flat().includes(id)
  };

  return (
    <div
      className={`bg-gray-200 w-full overflow-auto p-3 ${classes.bodyContent}`}
    >
      <Card>
        {/* <div className='p-2'>
					<TextField
						id='name'
						label='Nombre'
						value={name}
						fullWidth
						onChange={(event) => setName(event.target.value)}
					/>
				</div> */}
        <div className="text-center title-lg">Creación de Graficos</div>
        <div className="p-2">
          <FormControl className="w-full">
            <InputLabel id={"axis-label"}>Eje</InputLabel>
            <Select
              labelId={"axis-label"}
              value={selectedAxis?.id}
              onChange={(e) => {
                setSelectedAxis(axes.find((a) => a.id === e.target.value))
                setSelectedSections([])
                setSelectedType(null)
                setAllSections(false)
              }
              }
              fullWidth
              label="Eje"
              placeholder="Seleccione un eje"
            >
              {axes?.map((axis) => (
                <MenuItem value={axis.id}>
                  {axis.code} {axis.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="p-2 flex">
          {/* <Radio
            value={"select"}
            checked={"select" === sectionSelectionType}
            color="primary"
            disabled={allSections}
            onChange={(e) => setSectionSelectionType("select")}
          ></Radio> */}
          <FormControl className="w-full">
            <InputLabel id={"section-label"}>Secciones</InputLabel>
            <Select
              multiple
              labelId={"section-label"}
              value={selectedSections}
              onChange={handleChangeMultiple}
              disabled={allSections || sectionSelectionType !== "select"}
              fullWidth
              label="Secciones"
              placeholder="Seleccione una o más secciones"
              renderValue={(selected: number[]) => {
                return selected
                  .map((sel) => sections.find((s) => s.id === sel)?.code)
                  .reduce<string>(
                    (acc, v, i) => acc + `${i === 0 ? "" : ", "}${v}`,
                    ""
                  );
              }}
            >
              {!!selectedAxis &&
                sections
                  .filter((s) => s.axis === selectedAxis.id)
                  .sort((a, b) =>
                    a.project_kilometer.localeCompare(b.project_kilometer)
                  )
                  .map((section) => (
                    <MenuItem value={section.id}>
                      {section.code}
                      {"  PK: "}
                      {section.project_kilometer}
                      {!!selectedSections.find((s) => s === section.id) && (
                        <div className="w-5 h-5 text-gray-500">
                          <Icon path={mdiCheck}></Icon>
                        </div>
                      )}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
        </div>

        {/* <div className="p-5 flex">
          <Radio
            value={"range"}
            checked={"range" === sectionSelectionType}
            color="primary"
            onChange={(e) => setSectionSelectionType("range")}
            disabled={allSections}
          ></Radio>
          <Slider
            value={sectionRange}
            onChange={(e, v) => setSectionRange(v as number[])}
            step={null}
            disabled={
              allSections || sectionSelectionType !== "range" || !selectedAxis
            }
            marks={sections
              .filter((s) => s.axis === selectedAxis?.id)
              .sort((a, b) =>
                a.project_kilometer.localeCompare(b.project_kilometer)
              )
              .map((section) => ({
                value: section.excavation_distance,
                label: section.project_kilometer,
              }))}
          ></Slider>
        </div> */}

        {!allSections && sectionSelectionType === "select" && (
          <div className="p-2">
            Secciones seleccionadas:{" "}
            {selectedSections.reduce(
              (acc, v, i) =>
                acc +
                `${i === 0 ? "" : ", "} ${sections.find((s) => s.id === v)?.code
                }`,
              ""
            )}
          </div>
        )}
        <div className="p-2">
          <Checkbox
            checked={allSections}
            onChange={(e) => {
              // setSelectedType(null)
              setAllSections(e.target.checked)
              addAllSectionsToSelectedSections()
              if (allSections) {
                setSelectedSections([])
              }
            }}
            label="Todo el eje"
          ></Checkbox>
          {<Checkbox
            checked={trendLine}
            onChange={(e) => {
              setTrendLine(e.target.checked)
            }
            }
            label="Línea de tendencia"
          ></Checkbox>}
        </div>

        <div className="p-2">
          <FormControl className=" w-1/2">
            <InputLabel id="report-type-label">Tipos de reporte</InputLabel>
            <Select
              fullWidth
              labelId="report-type-label"
              value={selectedType}
              onChange={(e) => {
                setSelectedType(e.target.value as string)
              }
              }
            >
              {!!reportTypes?.length &&
                reportTypes?.sort((a, b) => a.index - b.index).map((type) => (
                  <MenuItem key={type.id} value={type.id} disabled={disabledReportType(type.id)}>
                    {type.legend}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
        {/* <div className='p-2'>
					<Checkbox
						checked={oneTime}
						onChange={(e) => setOneTime(e.target.checked)}
						label='Una sola vez'
					></Checkbox>
				</div> */}

        <div className="m-1 p-1 flex items-center space-x-2">
          <Radio
            value={"all"}
            checked={"all" === range}
            color="primary"
            onChange={(e) => setRange("all")}
          ></Radio>
          <div>Desde el comienzo</div>
          <Radio
            value={"dates"}
            checked={"dates" === range}
            color="primary"
            onChange={(e) => setRange("dates")}
          ></Radio>
          <div className="p-1">
            <DatePicker
              value={timeRange.start.tz(timeZone)}
              onChange={(time) => {
                setTimeRange((s) => ({
                  ...s,
                  start: moment(time),
                }));
                // adjustRange()
              }}
              label="Inicio"
              InputLabelProps={{
                shrink: true,
              }}
              disabled={range !== "dates"}
              disableFuture
              format={"dd/MMM/yyyy"}
            ></DatePicker>
          </div>
          <div className="p-1">
            <DatePicker
              disableFuture
              value={timeRange.end.tz(timeZone)}
              onChange={(time) => {
                setTimeRange((s) => ({
                  ...s,
                  end: moment(time),
                }));
                // adjustRange()
              }}
              label="Fin"
              disabled={range !== "dates"}
              InputLabelProps={{
                shrink: true,
              }}
              format={"dd/MMM/yyyy"}
            ></DatePicker>
          </div>
          <Radio
            color="primary"
            value={"days"}
            checked={"days" === range}
            onChange={(e) => setRange("days")}
          ></Radio>
          <TextField
            label="Dias atrás"
            value={dayRange}
            onChange={(e) => setDayRange(parseInt(e.target.value))}
            disabled={range !== "days"}
          ></TextField>
        </div>

        {/* <div className='p-2'>
					<Checkbox
						checked={!scheduled}
						onChange={(e) => setScheduled(!e.target.checked)}
						label='Ahora, lo mas pronto posible'
					></Checkbox>
					{scheduled && (
						<Cron
							onChange={(e) => {
								setCron(e)
							}}
							value={cron}
							showResultText={true}
							showResultCron={true}
						/>
					)}
				</div> */}
        <div className="flex p-2 items-center">
          <div className="flex-1"></div>
          <Button
            variant="contained"
            color="primary"
            onClick={async () => {
              try {
                setName(moment().format("YYYY_MM_DD_HH_mm_ss"));
                var params: any = {
                  all_active_sections: false,
                  only_one: true,
                  template: parseInt(selectedType),
                  sections:
                    allSections && selectedAxis
                      ? sections
                        .filter((s) => s.axis === selectedAxis.id)
                        .map((s) => s.id)
                      : sectionSelectionType === "select"
                        ? selectedSections.map((section) => section)
                        : sections.filter(
                          (s) =>
                            s.axis === selectedAxis.id &&
                            s.excavation_distance >= sectionRange[0] &&
                            s.excavation_distance <= sectionRange[1]
                        ),

                  name,
                  plot_linear_trends: trendLine,
                };
                if (scheduled) params.schedule = cron;
                switch (range) {
                  case "dates":
                    params.start_date = timeRange.start.toDate();
                    params.end_date = timeRange.end.toDate();
                    break;
                  case "days":
                    params.start_date = moment()
                      .subtract(dayRange, "days")
                      .toDate();
                    params.end_date = moment().toDate();
                    break;
                  default:
                    params.start_date = moment().subtract(20, "years").toDate();
                    params.end_date = moment().toDate();
                    break;
                }
                !cron && delete params.schedule;
                setLoading(true);
                setCounter((c) => c + params.sections.length);
                setProcessing(true);
                addSnackbarNotification({
                  message: `Generando reportes`,
                });
                const response = await scheduleReportSubmission(params);
                setScheduleIds([...scheduleIds, response.id]);
                setLoading(false);
              } catch (e) {
                addSnackbarNotification({
                  message: `Ha ocurrido un error`,
                  type: "error",
                });
              }
            }}
          >
            Generar
          </Button>
        </div>
        <div className="flex flex-col">
          {counter > 0 && (
            <div className="text-center w-full">Procesando...</div>
          )}
          {!!reportBuffer.length &&
            reportBuffer
              .sort((a, b) => a.time.unix() - b.time.unix())
              .map((r, i) =>
                !!r?.report?.split("/")[r?.report?.split("/").length - 1] ? (
                  <div className="m-1 p-2 text-white rounded bg-green-600 hover:bg-green-500 transition-all cursor-pointer flex ">
                    {i + 1}.-{" "}
                    <a
                      href={
                        process.env.REACT_APP_API_URL.replace("api/", "") +
                        r.report
                      }
                      target="_blank"
                      key={r.report}
                      className="w-full inline-block"
                      rel="noreferrer"
                    >
                      {r?.report?.split("/")[r?.report?.split("/").length - 1]}
                    </a>
                  </div>
                ) : (
                  <></>
                )
              )}
          {!!errorBuffer.length &&
            errorBuffer.map((r) => (
              <div className="m-1 p-2 text-white rounded bg-red-600 hover:bg-red-500 transition-all ">
                {r}
              </div>
            ))}
        </div>
      </Card>
    </div>
  );
};
export default PlotCreationView;
