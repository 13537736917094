export enum FrontendPermissions {
	Terrain ='terreno',
	Input ='ingreso_mediciones',
	NubeDeDatos  ='nube_datos',
	Export ='exportar_datos',
	Charts ='creacion_graficos',
	Admin ='admin',
	Accounts ='cuentas',
	Progress ='progreso_excavacion',
	Files ='antecedentes',
	Reports ='informes',
}

