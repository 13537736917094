import { Reducer } from 'react'

interface State {
	fileTypes: FileType[]
	reports: Report[]
}

const initialState: State = {
	fileTypes: [],
	reports: [],
}

export enum FileActions {
	AddFileTypes = 'ADD_FILE_TYPES',
	RemoveFileType = 'REMOVE_FILE_TYPE',
	AddReports = 'ADD_REPORTS',
	RemoveReport = 'REMOVE_REPORT',
}

const reducer: Reducer<State, { type: FileActions; payload: any }> = (
	state: State = initialState,
	{ type, payload }
): State => {
	switch (type) {
		case FileActions.AddFileTypes:
			return {
				...state,
				fileTypes: [
					...state.fileTypes.filter(
						(ft) =>
							!(payload as FileType[]).some(
								(nft) => nft.id === ft.id
							)
					),
					...payload,
				],
			}
		case FileActions.RemoveFileType:
			return {
				...state,
				fileTypes: state.fileTypes.filter(
					(ft) => ft.id !== (payload as number)
				),
			}
		case FileActions.AddReports:
			return {
				...state,
				reports: [
					...state.reports.filter(
						(ft) =>
							!(payload as Report[]).some(
								(nft) => nft.id === ft.id
							)
					),
					...payload,
				],
			}
		case FileActions.RemoveReport:
			return {
				...state,
				reports: state.reports.filter(
					(ft) => ft.id !== (payload as number)
				),
			}
		default:
			return state
	}
}

export default reducer
