import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@material-ui/core'
import { mdiCheck } from '@mdi/js'
import Icon from '@mdi/react'
import { useAppSelector } from '../../../hooks/storeHooks'
import { useForm } from '../../../hooks/useForm'
import { createProfile, createUser, editProfile } from '../../../services/auth'
import { addSnackbarNotification } from '../../../services/snackbarNotification'
// import { useForm } from '../../hooks/useForm'

interface Props {
	open: boolean
	onClose?: (event?: {}, reason?: 'backdropClick' | 'escapeKeyDown') => void
}

const UserProfileForm: React.FC<Props> = ({ open, onClose }) => {
	const groups = useAppSelector((s) => s.groupReducer.groups)
	const jobTitles = useAppSelector((s) => s.profileReducer.jobtitles)
	const [profileValues, , profileHandleChange] = useForm({
		user: 0,
		first_name: '',
		father_last_name: '',
		mother_last_name: '',
		phonenumber: '',
		bio: '',
		jobtitles: [],
	})

	const [userValues, , userHandleChange] = useForm({
		username: '',
		email: '',
		password: '',
		groups: [],
	})

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth='md'>
			<DialogContent className='p-2'>
				<div className='w-full flex'>
					<div className='w-1/3 p-2'>
						<div className='text-main-1 text-lg mb-2 font-medium uppercase'>
							Login
						</div>
						<TextField
							id='username'
							fullWidth
							color='primary'
							label='Login'
							size='medium'
							value={userValues.username}
							onChange={userHandleChange}
						/>
					</div>
					<div className='w-1/3 p-2'>
						<div className='text-main-1 text-lg mb-2 font-medium uppercase'>
							Email
						</div>
						<TextField
							id='email'
							fullWidth
							color='primary'
							label='Email'
							size='medium'
							value={userValues.email}
							onChange={userHandleChange}
						/>
					</div>
					<div className='w-1/3 p-2'>
						<div className='text-main-1 text-lg mb-2 font-medium uppercase'>
							Password
						</div>
						<TextField
							id='password'
							fullWidth
							color='primary'
							label='Password'
							size='medium'
							value={userValues.password}
							onChange={userHandleChange}
						/>
					</div>
				</div>
				<div className='flex w-full'>
					<div className='w-1/3 p-2'>
						<div className='text-main-1 text-lg mb-2 font-medium uppercase'>
							Nombre
						</div>
						<TextField
							id='first_name'
							fullWidth
							color='primary'
							label='Nombre'
							size='medium'
							value={profileValues.first_name}
							onChange={profileHandleChange}
						/>
					</div>
					<div className='w-1/3 p-2'>
						<div className='text-main-1 text-lg mb-2 font-medium uppercase'>
							Primer apellido
						</div>
						<TextField
							id='father_last_name'
							fullWidth
							color='primary'
							label='Primer apellido'
							size='medium'
							value={profileValues.father_last_name}
							onChange={profileHandleChange}
						/>
					</div>
					<div className='w-1/3 p-2'>
						<div className='text-main-1 text-lg mb-2 font-medium uppercase'>
							Segundo apellido
						</div>
						<TextField
							id='mother_last_name'
							fullWidth
							color='primary'
							label='Segundo apellido'
							size='medium'
							value={profileValues.mother_last_name}
							onChange={profileHandleChange}
						/>
					</div>
				</div>
				<div className='flex w-full'>
					<div className='w-1/3 p-2'>
						<div className='text-main-1 text-lg mb-2 font-medium uppercase'>
							Teléfono
						</div>
						<TextField
							id='phonenumber'
							fullWidth
							color='primary'
							label='Teléfono'
							size='medium'
							value={profileValues.phonenumber}
							onChange={profileHandleChange}
						/>
					</div>
					<div className='w-1/3 p-2'>
						<div className='text-main-1 text-lg mb-2 font-medium uppercase'>
							Cargos
						</div>
						<InputLabel id={'groups-select-label'}>
							Cargos
						</InputLabel>

						<Select
							multiple={true}
							labelId='groups-select-label'
							fullWidth
							id='jobtitles'
							value={profileValues.jobtitles}
							name='jobtitles'
							onChange={profileHandleChange}
							renderValue={(selected: number[]) => {
								return selected
									.map(
										(sel) =>
											jobTitles.find((s) => s.id === sel)
												?.name
									)
									.reduce<string>(
										(acc, v, i) =>
											acc + `${i === 0 ? '' : ', '}${v}`,
										''
									)
							}}
						>
							{jobTitles.map((jt) => (
								<MenuItem key={jt.id} value={jt.id}>
									{jt.name}
									{!!profileValues.jobtitles.find(
										(s) => s === jt.id
									) && (
										<div className='w-5 h-5 text-gray-500'>
											<Icon path={mdiCheck}></Icon>
										</div>
									)}
								</MenuItem>
							))}
						</Select>
					</div>
				</div>
			</DialogContent>
			<DialogActions>
				<Button className='m-2' onClick={onClose}>
					Volver
				</Button>
				<Button
					color='primary'
					className='m-2'
					variant='contained'
					onClick={async () => {
						try {
							const user = await createUser(userValues)
							if (user) {
								await editProfile(user?.profile, profileValues)
								onClose()
							}
						} catch (e) {
							try {
								const errors = JSON.parse(e.request?.response)
								if (errors) {
									Object.keys(errors).forEach((key) => {
										errors[key].forEach((error) => {
											addSnackbarNotification({
												message: `${key}: ${error}`,
												type: 'error',
											})
										})
									})
								} else {
									addSnackbarNotification({
										type: 'error',
										message: 'Ha ocurrido un error',
									})
								}
								onClose()
							} catch (error) {
								addSnackbarNotification({
									type: 'error',
									message: 'Ha ocurrido un error',
								})
							}
						}
					}}
				>
					Guardar
				</Button>
			</DialogActions>
		</Dialog>
	)
}
export default UserProfileForm
