import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
} from "@material-ui/core"
import { mdiChevronDown } from "@mdi/js"
import Icon from "@mdi/react"
import { useState } from "react"
import { useAppSelector } from "../../../hooks/storeHooks"
import { fetchAllAxisSections } from "../../../services/section"
import Spinner from "../Spinner"
import SectionExpandable from "./SectionExpandable"

interface Props {
    axis: Axis
}

const AxisExpandable: React.FC<Props> = ({axis }) => {
    const [expanded, setExpanded] = useState(false)
    const sections = useAppSelector(
        (s) => s.sectionReducer.sections.filter(s=>s.axis === axis.id)
    )
    const loading = useAppSelector(s=>s.sectionReducer.isLoading[axis.id])

    return (
        <div className="p-1">
            <Accordion
                elevation={0}
                expanded={expanded}
                onChange={(_, isExpanded) => {
                    isExpanded && fetchAllAxisSections(axis)
                    setExpanded(isExpanded)
                }}
            >
                <AccordionSummary
                    expandIcon={
                        <Icon
                            path={mdiChevronDown}
                            className="w-6"
                            color="#BBB"
                        ></Icon>
                    }
                >
                    <div className="flex">
                        <div className="font-medium text-gray-500">
                            {axis.name}
                        </div>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="w-full">
                        {!!sections?.length &&
                            sections.map((s) => (
                                <SectionExpandable key={s.id} section={s} />
                            ))}
                            {loading && <Spinner></Spinner>}
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}
export default AxisExpandable
