import { Button, List, MenuItem, TableCell, TableRow } from '@material-ui/core'
import { mdiAccountEdit, mdiDelete, mdiPencil } from '@mdi/js'
import Icon from '@mdi/react'
import { profile } from 'console'
import { useEffect, useState } from 'react'
import { useAppSelector } from '../../../hooks/storeHooks'
import {
	deleteUser,
	editProfile,
	editUser,
	getGroups,
	getProfiles,
	getUsers,
} from '../../../services/auth'
import { getJobTitles } from '../../../services/jobTitle'
import {
	addSnackbarNotification,
	withError,
	withErrors,
} from '../../../services/snackbarNotification'
import useStyles from '../../../styles/appStyles'
import DeleteButton from '../../shared/DeleteButton'
import EditableLabel from '../../shared/EditableLabel'
import EditableMultiSelectLabel from '../../shared/EditableMultiSelectLabel'
import AppTable from '../../utils/AppTable'
import UserProfileForm from './UserProfileForm'

const ProfilesComponent: React.FC = () => {
	const classes = useStyles(true)()
	const profiles = useAppSelector((s) => s.profileReducer.profiles)
	const users = useAppSelector((s) => s.authReducer.users)
	const groups = useAppSelector((s) => s.groupReducer.groups)
	const jobTitles = useAppSelector((s) => s.profileReducer.jobtitles)
	const [formVisible, setFormVisible] = useState(false)

	useEffect(() => {
		getUsers()
		getProfiles()
		getGroups()
		getJobTitles()
	}, [])

	return (
		<div className={`${classes.bodyContent}`}>
			<div className='flex h-full'>
				<div className='w-full'>
					<div className='w-full flex items-center justify-between m-2'>
						<div className='title-lg'>Usuarios</div>
						<Button
							variant='contained'
							color='primary'
							onClick={() => setFormVisible(true)}
						>
							Crear nuevo Usuario
						</Button>
					</div>
					<AppTable
						headers={[
							'Usuario',
							'Nombre',
							'Apellido',
							'Segundo Apellido',
							'Email',
							'Teléfono',
							'Cargos',
							// 'Acciones',
						]}
					>
						{profiles.map((profile) => (
							<TableRow
								key={profile.id}
								id={profile.id.toString()}
							>
								<TableCell>
									<EditableLabel
										id='username'
										label='Nombre de usuario'
										value={
											users.find(
												(user) =>
													user.id === profile.user
											)?.username
										}
										onSubmit={async (username) => {
											await editUser(profile.user, {
												username,
											})
											getUsers()
										}}
									></EditableLabel>
								</TableCell>
								<TableCell>
									<EditableLabel
										id='first_name'
										label='Nombre'
										value={profile.first_name}
										onSubmit={async (first_name) => {
											await editProfile(profile.id, {
												first_name,
											})
											getProfiles()
										}}
									></EditableLabel>
								</TableCell>
								<TableCell>
									<EditableLabel
										id='father_last_name'
										label='Apellido'
										value={profile.father_last_name}
										onSubmit={async (father_last_name) => {
											await editProfile(profile.id, {
												father_last_name,
											})
											getProfiles()
										}}
									></EditableLabel>
								</TableCell>
								<TableCell>
									<EditableLabel
										id='mother_last_name'
										label='Segundo Apellido'
										value={profile.mother_last_name}
										onSubmit={async (mother_last_name) => {
											await editProfile(profile.id, {
												mother_last_name,
											})
											getProfiles()
										}}
									></EditableLabel>
								</TableCell>
								<TableCell>
									<EditableLabel
										id='email'
										value={
											users.find(
												(user) =>
													user.id === profile.user
											)?.email
										}
										label='Email'
										onSubmit={async (email) => {
											await editUser(profile.user, {
												email,
											})
											getUsers()
										}}
									></EditableLabel>
								</TableCell>
								<TableCell>
									<EditableLabel
										id='phonenumber'
										value={profile.phonenumber}
										label='Teléfono'
										onSubmit={async (phonenumber) => {
											withErrors(async () => {
												await editProfile(profile.id, {
													phonenumber,
												})
												console.log('aaa')
												getProfiles()
											})
										}}
									></EditableLabel>
								</TableCell>
								<TableCell>
									<EditableMultiSelectLabel
										id='jobtitles'
										value={profile?.jobtitles}
										onSubmit={withError(
											async (jobtitles) => {
												await editProfile(profile.id, {
													jobtitles,
												})
												getProfiles()
											}
										)}
										labelValue={profile?.jobtitles.reduce(
											(acc, v, i) =>
												acc +
												(i === 0 ? '' : ', ') +
												jobTitles.find(
													(g) => g.id === v
												)?.name,
											''
										)}
										renderValue={(selected: number[]) => (
											<div>
												{selected.reduce(
													(acc, v, i) => {
														console.log(
															v,
															jobTitles.find(
																(g) =>
																	g.id === v
															)?.name
														)
														return (
															acc +
															(i === 0
																? ''
																: ', ') +
															jobTitles.find(
																(g) =>
																	g.id === v
															)?.name
														)
													},
													''
												)}
											</div>
										)}
									>
										{jobTitles?.map((jt) => (
											<MenuItem value={jt.id} key={jt.id}>
												{jt.name}
											</MenuItem>
										))}
									</EditableMultiSelectLabel>
								</TableCell>
								<TableCell>
									<DeleteButton
										onClick={async () => {
											withErrors(async () => {
												await deleteUser(
													users.find(
														(user) =>
															user.id ===
															profile.user
													)
												)
												getUsers()
												getProfiles()
											})
										}}
									></DeleteButton>
								</TableCell>
								{/* <TableCell>
									<Button
										variant='outlined'
										title='Editar usuario'
									>
										<Icon
											className='w-5 h-5 text-center m-auto'
											path={mdiPencil}
										></Icon>
									</Button>
									<Button
										variant='outlined'
										title='Eliminar usuario'
									>
										<Icon
											className='w-5 h-5 text-center m-auto'
											path={mdiDelete}
										></Icon>
									</Button>
								</TableCell> */}
							</TableRow>
						))}
					</AppTable>
					<UserProfileForm
						open={formVisible}
						onClose={() => {
							setFormVisible(false)
							getUsers()
							getProfiles()
						}}
					></UserProfileForm>
				</div>
			</div>
		</div>
	)
}
export default ProfilesComponent
