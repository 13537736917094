import {
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@material-ui/core'
import { useDropzone } from 'react-dropzone'

import Dialog from '@material-ui/core/Dialog'
import { useRef, useState } from 'react'
import { createFileType, createReport } from '../../../services/file'
import {
	addSnackbarNotification,
	withError,
} from '../../../services/snackbarNotification'
import { useAppSelector } from '../../../hooks/storeHooks'
import { useForm } from '../../../hooks/useForm'
import moment from 'moment'
import { DatePicker, DateTimePicker } from '@material-ui/pickers'
import Checkbox from '../../shared/Checkbox'

interface Props {
	open: boolean
	onClose?: (event?: {}, reason?: 'backdropClick' | 'escapeKeyDown') => void
}
interface Props {}

const NewReportModal: React.FC<Props> = ({ open, onClose }) => {
	const [name, setName] = useState('')
	const inputFile = useRef<HTMLInputElement>(null)
	const [file, setFile] = useState<File>(null)
	const { getRootProps, getInputProps } = useDropzone({
		onDrop: (acceptedFiles) => {
			setFile(acceptedFiles[0])
		},
	})

	const [values, setValues, handleChange] = useForm({
		type: 1,
		group: 'metro',
		public: true,
		start_date: moment(),
		end_date: moment(),
	})

	const fileTypes = useAppSelector((s) => s.fileReducer.fileTypes)

	return (
		<Dialog fullWidth maxWidth='md' open={open} onClose={onClose}>
			<DialogTitle>Nuevo Reporte</DialogTitle>
			<DialogContent>
				<div className='flex items-center w-full space-x-2 space-y-2'>
					<div
						className='w-48  p-2 rounded-xl text-center space-x-2  border h-full font-semibold uppercase text-blue-800 cursor-pointer border-blue-500 border-dashed flex justify-center items-center'
						{...getRootProps()}
					>
						Seleccionar o arrastrar archivo
						<input
							type='file'
							name='file'
							id='file'
							style={{ display: 'none' }}
							ref={inputFile}
							onChange={(e) => setFile(e.target.files[0])}
							{...getInputProps()}
						/>
					</div>
					{!!file && file.name}
				</div>
				<FormControl className='w-full'>
					<InputLabel id={'type-label'}>Tipo</InputLabel>
					<Select
						labelId='type'
						value={values.type}
						onChange={(e) =>
							setValues({
								...values,
								type: e.target.value as number,
							})
						}
						label='Tipo'
						fullWidth
						id='type'
					>
						{fileTypes.map((ft) => (
							<MenuItem key={ft.id} value={ft.id}>
								{ft.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<FormControl className='w-full'>
					<InputLabel id={'group-label'}>Grupo</InputLabel>

					<Select
						labelId='group-label'
						value={values.group}
						onChange={(e) =>
							setValues({
								...values,
								group: e.target.value as string,
							})
						}
						label='Grupo'
						fullWidth
						id='group'
					>
						<MenuItem key={'metro'} value={'metro'}>
							{'Metro'}
						</MenuItem>
						<MenuItem key={'particular'} value={'particular'}>
							{'Particular'}
						</MenuItem>
					</Select>
				</FormControl>
				<div className='flex'>
					<div className='p-1'>
						<DatePicker
							disableFuture
							value={values.start_date}
							onChange={(time) => {
								setValues((s) => ({
									...s,
									start_date: moment(time),
								}))
							}}
							label='Inicio'
							InputLabelProps={{
								shrink: true,
							}}
							format={'dd/MMM/yyyy'}
						></DatePicker>
					</div>
					<div className='p-1'>
						<DatePicker
							disableFuture
							value={values.end_date}
							onChange={(time) => {
								setValues((s) => ({
									...s,
									end_date: moment(time),
								}))
							}}
							label='Fin'
							InputLabelProps={{
								shrink: true,
							}}
							format={'dd/MMM/yyyy'}
						></DatePicker>
					</div>
				</div>
				<div className='p-2'>
					<Checkbox
						checked={values.public}
						onChange={(e) =>
							setValues({ ...values, public: e.target.checked })
						}
						label='Público'
					></Checkbox>
				</div>
			</DialogContent>
			<DialogActions>
				<Button
					variant='contained'
					color='primary'
					onClick={withError(async () => {
						const aux: any = values
						aux.start_date = values.start_date.format('YYYY-MM-DD')
						aux.end_date = values.end_date.format('YYYY-MM-DD')
						await createReport(aux, file)
						addSnackbarNotification({ message: 'Reporte creado' })
						onClose()
					})}
				>
					Guardar
				</Button>
				<Button onClick={() => onClose()}>Cancelar</Button>
			</DialogActions>
		</Dialog>
	)
}
export default NewReportModal
function getInputProps(): JSX.IntrinsicAttributes &
	import('react').ClassAttributes<HTMLInputElement> &
	import('react').InputHTMLAttributes<HTMLInputElement> {
	throw new Error('Function not implemented.')
}
