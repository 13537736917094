import { List, MenuItem } from '@material-ui/core'
import { useState } from 'react'
import useStyles from '../../../styles/appStyles'
import MeasurementTable from './MeasurementTable'
import TableLogin from './TableLogin'

const MeasurementsView: React.FC = () => {
	const classes = useStyles(true)()
	const [tab, setTab] = useState<'MovementPoints' | 'FixedPoints'>(
		'MovementPoints'
	)
	const [authorized, setAuthorized] = useState(false)

	return (
		<div className={`${classes.bodyContent}`}>
			<div className='flex h-full'>
				<div className='w-1/6'>
					<h1 className='text-center title-lg'>Puntos</h1>
					<List>
						<MenuItem
							selected={tab === 'MovementPoints'}
							onClick={() => setTab('MovementPoints')}
						>
							Puntos de movimiento
						</MenuItem>
						<MenuItem
							selected={tab === 'FixedPoints'}
							onClick={() => setTab('FixedPoints')}
						>
							Puntos fijos
						</MenuItem>
					</List>
				</div>
				<TableLogin
					open={!authorized}
					onAuth={() => setAuthorized(true)}
				></TableLogin>
				<div className='w-5/6'>
					{tab === 'FixedPoints' && (
						<MeasurementTable
							authorized={authorized}
							fixed
						></MeasurementTable>
					)}
					{tab === 'MovementPoints' && (
						<MeasurementTable
							authorized={authorized}
						></MeasurementTable>
					)}
				</div>
			</div>
		</div>
	)
}
export default MeasurementsView
