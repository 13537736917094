import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Table,
	TableCell,
	TableHead,
	TableRow,
} from '@material-ui/core'
import {
	addSnackbarNotification,
	withError,
} from '../../../services/snackbarNotification'
import { spreadsheetCommitImport } from '../../../services/spreadsheetReview'
import { getTopoFileReview } from '../../../services/topoFile'
import { SpreadsheetReview } from '../../../types/SpreadsheetReview'
import AppTable from '../../utils/AppTable'

interface Props {
	topoFile?: TopoFile
	open: boolean
	onClose?: (event?: {}, reason?: 'backdropClick' | 'escapeKeyDown') => void
	spreadsheetReview?: SpreadsheetReview
	onAccept: any
}

const SpreadsheetReviewImportConfirmModal: React.FC<Props> = ({
	open,
	onClose,
	topoFile,
	spreadsheetReview,
	onAccept,
}) => {
	if (!topoFile) return <></>
	return (
		<Dialog fullWidth maxWidth='md' open={open} onClose={onClose}>
			<DialogTitle>
				El archivo calculado es{' '}
				{
					spreadsheetReview.spreadsheet.split('/')[
						spreadsheetReview.spreadsheet.split('/').length - 1
					]
				}
			</DialogTitle>
			<DialogContent>
				<div className='font-medium'>
					Se ha recibido un archivo procesado de terreno para el
					archivo{' '}
					{
						topoFile.datafile.split('/')[
							topoFile.datafile.split('/').length - 1
						]
					}
				</div>
				<br />

				<div>
					{!!spreadsheetReview.validation_report_json?.ERRORS
						?.length && (
						<>
							<div className='title-lg'>Errores</div>
							<AppTable headers={['Error', 'Descripción']}>
								{spreadsheetReview.validation_report_json?.ERRORS?.map(
									(e) => (
										<TableRow className='ml-5'>
											<TableCell>{e.label}</TableCell>
											<TableCell className='ml-5'>
												Descripción: {e.message}
											</TableCell>
										</TableRow>
									)
								)}
							</AppTable>
						</>
					)}
					{!!spreadsheetReview.validation_report_json?.WARNINGS
						?.length && (
						<>
							<div className='title-lg'>Advertencias</div>
							<AppTable headers={['Advertencia', 'Descripción']}>
								{spreadsheetReview.validation_report_json?.WARNINGS?.map(
									(e) => (
										<TableRow className='ml-5'>
											<TableCell>{e.label}</TableCell>
											<TableCell className='ml-5'>
												Descripción: {e.message}
											</TableCell>
										</TableRow>
									)
								)}
							</AppTable>
						</>
					)}
				</div>
				<br />
			</DialogContent>
			<DialogActions>
				{spreadsheetReview.validation_report_json?.ERRORS?.length <
					1 && (
					<Button
						variant='contained'
						color='primary'
						onClick={withError(() =>
							spreadsheetCommitImport(spreadsheetReview.id).then(
								() => {
									getTopoFileReview(
										spreadsheetReview.topofile_review
									)
									onClose()
									addSnackbarNotification({
										message: 'Datos importados',
									})
								}
							)
						)}
					>
						{`Enviar archivo a base de datos${
							spreadsheetReview.validation_report_json.WARNINGS
								?.length > 0
								? ' de todas formas'
								: ''
						}`}
					</Button>
				)}
				<Button onClick={() => onClose()}>Cancelar</Button>
			</DialogActions>
		</Dialog>
	)
}
export default SpreadsheetReviewImportConfirmModal
